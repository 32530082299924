import { RightOutlined } from '@ant-design/icons'
import { ColorPicker } from 'antd'
import { IKioskKitchen } from 'app/api/kiosk-kitchen/model'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { Loading } from 'app/components/common/Loading'
import { BaseTimePicker } from 'app/components/common/pickers/BaseTimePicker'
import { LoadingWrapper, Text } from 'app/components/styles'
import * as SR from 'app/containers/admin/Settings/components/RightSide/styles'
import {
  useGetKioskKitchen,
  useUpdateKioskKitchen,
} from 'app/react-query/hook/kiosk-kitchen'
import dayjs from 'dayjs'
import moment from 'moment'
import { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FONT_DATA,
  MESSAGING_DATA,
  ORDER_TYPE_COLORS_DATA,
  STANDARD_SCREEN,
  STANDARD_SCREEN_OTHER_OPTIONS,
  STATUS_COLORS_DATA,
  TRANSITION_TIMES_FOR_HERE,
  TRANSITION_TIMES_PICKUP,
  TRANSITION_TIMES_TO_GO,
  TransitionTimesListValueData
} from './constant'
import * as S from './styles'
const textSome =
  'Lorem ipsum dolor sit amet consectetur. Risus nec morbi tincidunt sit amet aliquam pharetra. Posuere vitae enim nec dis morbi. Feugiat quam mauris eros tellus eget.'

const ItemTransitionTime = ({
  title,
  listValue,
  data,
  onChange,
}: {
  title: string
  listValue: TransitionTimesListValueData[]
  data: IKioskKitchen | undefined
  onChange: (body: IKioskKitchen, loadingTarget: LoadingTarget) => void
}) => {
  const { t } = useTranslation()

  return (
    <BaseSpace direction="vertical" size={10} style={{ width: '100%' }}>
      <S.TitleTransition>{title}</S.TitleTransition>
      <S.ItemTransitionItemWrapper>
        <S.ItemTransitionTime direction="vertical" split={<S.SplitDivider />}>
          {
            listValue?.map((value, index) => {
              const time: number = (data?.[value.key] as number) || 0
              return (
                <S.CustomPopover
                  key={index}
                  arrow={false}
                  content={
                    <BaseTimePicker
                      size="small"
                      format={'mm:ss'}
                      showNow={false}
                      defaultValue={dayjs(time)}
                      allowClear={false}
                      onChange={selectedTime =>
                        onChange(
                          {
                            [value.key]:
                              ((selectedTime?.minute() ?? 0) * 60 +
                                (selectedTime?.second() ?? 0)) *
                              1000,
                          },
                          'transitionTimes',
                        )
                      }
                      getPopupContainer={triggerNode => triggerNode}
                    />
                  }
                  title="Change time"
                  trigger="click"
                  placement="bottomRight"
                >
                  <BaseRow
                    justify={'space-between'}
                    align={'middle'}
                    style={{ cursor: 'pointer' }}
                  >
                    <S.TextTransitionTime>{t(value.label)}</S.TextTransitionTime>
                    <BaseCol>
                      <Text
                        $fontSize="10px"
                        $fontWeight="400"
                        $margin="0 4px 0 0"
                        $inline
                      >
                        {moment(time).format('mm:ss')} {t(R.strings.min)}
                      </Text>
                      <RightOutlined rev={undefined} />
                    </BaseCol>
                  </BaseRow>
                </S.CustomPopover>
              )
            })
          }
        </S.ItemTransitionTime>
      </S.ItemTransitionItemWrapper>
    </BaseSpace>
  )
}
const MemoizedItemTransitionTime = memo(ItemTransitionTime);

const ItemFontWeight = ({
  title,
  isSelected,
  onClick,
}: {
  title?: string
  isSelected?: boolean
  onClick?: () => void
}) => {
  return (
    <BaseRow
      justify={'space-between'}
      gutter={[10, 10]}
      align={'middle'}
      style={{ cursor: 'pointer' }}
      onClick={() => onClick && onClick()}
    >
      <BaseCol>
        <BaseRow gutter={[10, 10]}>
          <BaseCol>
            <S.TextTransitionTime>{title}</S.TextTransitionTime>
          </BaseCol>
          <S.IconChecked rev={undefined} isSelected={isSelected} />
        </BaseRow>
      </BaseCol>
    </BaseRow>
  )
}

const ItemFontColor = ({
  title,
  color,
  loadingSave,
  onChange,
}: {
  title?: string
  color?: string
  loadingSave?: boolean
  onChange?: (color: string) => void
}) => {
  const { t } = useTranslation()
  const [currentColor, setCurrentColor] = useState(color ?? '')

  const save = () => {
    onChange?.(currentColor)
  }

  return (
    <ColorPicker
      value={currentColor}
      onChangeComplete={color => setCurrentColor(color.toHexString())}
      onOpenChange={() => setCurrentColor(color ?? '')}
      placement="bottomRight"
      panelRender={(panel) => (
        <>
          {panel}
          <S.ColorPickerFooter justify={'end'}>
            <S.SaveButton onClick={save} loading={loadingSave}>{t(R.strings.save)}</S.SaveButton>
          </S.ColorPickerFooter>
        </>
      )}
    >
      <BaseRow
        justify={'space-between'}
        gutter={[10, 10]}
        align={'middle'}
        style={{ cursor: 'pointer' }}
      >
        <BaseCol>
          <S.TextTransitionTime>{title}</S.TextTransitionTime>
        </BaseCol>
        <BaseCol>
          <BaseRow gutter={[5, 5]} align={'middle'}>
            <BaseCol>
              <S.ColorDot color={color} />
            </BaseCol>
            <BaseCol>
              <RightOutlined rev={undefined} />
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </BaseRow>
    </ColorPicker>
  )
}

const ItemFontMessaging = ({
  title,
  status,
  onClick,
}: {
  title?: string
  status?: boolean
  onClick?: (value: boolean) => void
}) => {
  const { t } = useTranslation()

  return (
    <BaseRow
      justify={'space-between'}
      gutter={[10, 10]}
      align={'middle'}
      style={{ cursor: 'pointer' }}
      onClick={() => onClick && onClick(!status)}
    >
      <BaseCol>
        <S.TextTransitionTime>{title}</S.TextTransitionTime>
      </BaseCol>
      <BaseCol>
        <BaseRow gutter={[5, 5]} align={'middle'}>
          <BaseCol>
            <S.TextTransitionTime>{status ? t(R.strings.on) : t(R.strings.off)}</S.TextTransitionTime>
          </BaseCol>
          <BaseCol>
            <RightOutlined rev={undefined} />
          </BaseCol>
        </BaseRow>
      </BaseCol>
    </BaseRow>
  )
}

type LoadingTarget =
  | 'standardScreenOption'
  | 'transitionTimes'
  | 'fontsAndColors'
  | 'messaging'

const KdsSettingLayout = () => {
  const {
    data,
    refetch: getKioskKitchenData,
    isLoading: loadingData,
  } = useGetKioskKitchen()
  const { mutate: mutateUpdateKioskKitchen } = useUpdateKioskKitchen()

  const { t } = useTranslation()

  const [standardScreenOptionLoading, setStandardScreenOptionLoading] = useState(false)
  const [transitionTimesLoading, setTransitionTimesLoading] = useState(false)
  const [fontsAndColorsLoading, setFontsAndColorsLoading] = useState(false)
  const [messagingLoading, setMessagingLoading] = useState(false)

  const updateLoadingState = (loadingTarget: LoadingTarget, value: boolean) => {
    loadingTarget === 'standardScreenOption' && setStandardScreenOptionLoading(value)
    loadingTarget === 'transitionTimes' && setTransitionTimesLoading(value)
    loadingTarget === 'fontsAndColors' && setFontsAndColorsLoading(value)
    loadingTarget === 'messaging' && setMessagingLoading(value)
  }

  const onUpdateKioskKitchenData = useCallback(
    (body: IKioskKitchen, loadingTarget: LoadingTarget) => {
      updateLoadingState(loadingTarget, true)

      const formatBody: IKioskKitchen = { ...data, ...body }
      delete formatBody.user_fk

      mutateUpdateKioskKitchen(formatBody, {
        onSuccess: () =>
          getKioskKitchenData().finally(() =>
            updateLoadingState(loadingTarget, false),
          ),
      })
    },
    [data],
  )

  return (
    <S.RootWrapper>
      <BaseSpace direction="vertical" size={15}>
        <S.HeaderWrapper justify={'space-between'} align={'middle'}>
          <SR.TitleSide>{t(R.strings.setting_menu_kds_t)}</SR.TitleSide>
        </S.HeaderWrapper>

        {/* standard screen option */}
        <LoadingWrapper>
          {(loadingData || standardScreenOptionLoading) && <Loading />}
          <S.StandardScreenOptionWrapper
            split={<S.SplitDivider />}
            size={15}
            direction="vertical"
          >
            <S.TitleStandardScreenOption>
              {t(R.strings.kds_standard_screen_option)}
            </S.TitleStandardScreenOption>
            <BaseSpace direction="vertical" size={15}>
              <S.OptionWrapper
                gutter={[20, 20]}
                align={'middle'}
                justify={'space-around'}
              >
                <BaseCol xl={10}>
                  <S.OptionItem direction="vertical" size={10}>
                    <BaseImage src={R.images.ic_option_kds_default} />
                    <S.TitleOption>{t(R.strings.kds_default_t)}</S.TitleOption>
                    <S.DescriptionOption>
                      {t(R.strings.kds_default_d)}
                    </S.DescriptionOption>
                    <BaseRow justify={'center'}>
                      <S.ButtonSelectOption
                        onClick={() =>
                          onUpdateKioskKitchenData(
                            { standard_screen: STANDARD_SCREEN.Default },
                            'standardScreenOption',
                          )
                        }
                      >
                        <S.DotCircleOption
                          isSelected={data?.standard_screen === STANDARD_SCREEN.Default}
                        />
                      </S.ButtonSelectOption>
                    </BaseRow>
                  </S.OptionItem>
                </BaseCol>
                <BaseCol xl={10}>
                  <S.OptionItem direction="vertical" size={10}>
                    <BaseImage src={R.images.ic_option_kds_classic} />
                    <S.TitleOption>{t(R.strings.kds_classic_t)}</S.TitleOption>
                    <S.DescriptionOption>
                      {t(R.strings.kds_classic_d)}
                    </S.DescriptionOption>
                    <BaseRow justify={'center'}>
                      <S.ButtonSelectOption
                        onClick={() =>
                          onUpdateKioskKitchenData(
                            { standard_screen: STANDARD_SCREEN.Classic },
                            'standardScreenOption',
                          )
                        }
                      >
                        <S.DotCircleOption
                          isSelected={data?.standard_screen === STANDARD_SCREEN.Classic}
                        />
                      </S.ButtonSelectOption>
                    </BaseRow>
                  </S.OptionItem>
                </BaseCol>
              </S.OptionWrapper>
              <S.OptionOtherWrapper
                direction="vertical"
                size={10}
                split={<S.SplitDivider />}
              >
                {
                  STANDARD_SCREEN_OTHER_OPTIONS.map((item, index) => {
                    return (
                      <S.OptionOtherItem
                        key={index}
                        justify={'space-between'}
                        gutter={[10, 10]}
                        align={'middle'}
                      >
                        <BaseCol xl={20}>
                          <S.TitleOptionOtherItem>
                            {t(item.title)}
                          </S.TitleOptionOtherItem>
                          <S.DescriptionOptionOtherItem>
                            {textSome}
                          </S.DescriptionOptionOtherItem>
                        </BaseCol>
                        <S.SwitchButton
                          checked={data?.[item.key]}
                          onChange={value =>
                            onUpdateKioskKitchenData(
                              { [item.key]: value },
                              'standardScreenOption',
                            )
                          }
                        />
                      </S.OptionOtherItem>
                    )
                  })
                }
              </S.OptionOtherWrapper>
            </BaseSpace>
          </S.StandardScreenOptionWrapper>
        </LoadingWrapper>

        {/* transition times */}
        <LoadingWrapper>
          {transitionTimesLoading && <Loading />}
          <S.TransitionWrapper
            direction="vertical"
            split={<S.SplitDivider />}
            size={20}
          >
            <S.TitleTransition>{t(R.strings.kds_transition_time)}</S.TitleTransition>
            <S.ListTransitionItemWrapper direction="vertical" size={10}>
              {
                [TRANSITION_TIMES_FOR_HERE, TRANSITION_TIMES_PICKUP, TRANSITION_TIMES_TO_GO].map((item, index) => {
                  return (
                    <MemoizedItemTransitionTime
                      key={index}
                      title={t(item.title)}
                      listValue={item.listValue}
                      data={data}
                      onChange={onUpdateKioskKitchenData}
                    />
                  )
                })
              }
            </S.ListTransitionItemWrapper>
          </S.TransitionWrapper>
        </LoadingWrapper>

        {/* fonts and colors */}
        <LoadingWrapper>
          {(loadingData || fontsAndColorsLoading) && <Loading />}
          <S.FontColorWrapper
            direction="vertical"
            split={<S.SplitDivider />}
            size={20}
          >
            <S.TitleTransition>{t(R.strings.kds_font_and_color)}</S.TitleTransition>
            <S.ListTransitionItemWrapper direction="vertical" size={10}>
              <BaseSpace
                direction="vertical"
                size={10}
                style={{ width: '100%' }}
              >
                <S.TitleTransition>{t(R.strings.kds_font_size)}</S.TitleTransition>
                <S.ItemTransitionItemWrapper>
                  <S.ItemTransitionTime
                    direction="vertical"
                    split={<S.SplitDivider />}
                  >
                    {
                      FONT_DATA.map((item, index) => {
                        return (
                          <ItemFontWeight
                            key={index}
                            title={t(item.title)}
                            isSelected={data?.font_size === item.size}
                            onClick={() =>
                              onUpdateKioskKitchenData(
                                { font_size: item.size },
                                'fontsAndColors',
                              )
                            }
                          />
                        )
                      })
                    }
                  </S.ItemTransitionTime>
                </S.ItemTransitionItemWrapper>
              </BaseSpace>

              <BaseSpace
                direction="vertical"
                size={10}
                style={{ width: '100%' }}
              >
                <S.TitleTransition>{t(R.strings.kds_status_color)}</S.TitleTransition>
                <S.ItemTransitionItemWrapper>
                  <S.ItemTransitionTime
                    direction="vertical"
                    split={<S.SplitDivider />}
                  >
                    {
                      STATUS_COLORS_DATA.map((item, index) => {
                        return (
                          <ItemFontColor
                            key={index}
                            title={t(item.title)}
                            color={data?.status_color?.[item.key]}
                            loadingSave={fontsAndColorsLoading}
                            onChange={color =>
                              onUpdateKioskKitchenData(
                                {
                                  status_color: {
                                    ...data?.status_color,
                                    [item.key]: color,
                                  },
                                },
                                'fontsAndColors',
                              )
                            }
                          />
                        )
                      })
                    }
                  </S.ItemTransitionTime>
                </S.ItemTransitionItemWrapper>
              </BaseSpace>

              <BaseSpace
                direction="vertical"
                size={10}
                style={{ width: '100%' }}
              >
                <S.TitleTransition>{t(R.strings.kds_order_type)}</S.TitleTransition>
                <S.ItemTransitionItemWrapper>
                  <S.ItemTransitionTime
                    direction="vertical"
                    split={<S.SplitDivider />}
                  >
                    {
                      ORDER_TYPE_COLORS_DATA.map((item, index) => {
                        return (
                          <ItemFontColor
                            key={index}
                            title={t(item.title)}
                            color={data?.order_type?.[item.key]}
                            loadingSave={fontsAndColorsLoading}
                            onChange={color =>
                              onUpdateKioskKitchenData(
                                {
                                  order_type: {
                                    ...data?.order_type,
                                    [item.key]: color,
                                  },
                                },
                                'fontsAndColors',
                              )
                            }
                          />
                        )
                      })
                    }
                  </S.ItemTransitionTime>
                </S.ItemTransitionItemWrapper>
              </BaseSpace>
            </S.ListTransitionItemWrapper>
          </S.FontColorWrapper>
        </LoadingWrapper>

        {/* messaging */}
        <LoadingWrapper>
          {(messagingLoading || loadingData) && <Loading />}
          <S.FontColorWrapper
            direction="vertical"
            split={<S.SplitDivider />}
            size={20}
          >
            <S.TitleTransition>{t(R.strings.kds_mesaging)}</S.TitleTransition>
            <S.ListTransitionItemWrapper direction="vertical" size={10}>
              <S.ItemTransitionItemWrapper>
                <S.ItemTransitionTime
                  direction="vertical"
                  split={<S.SplitDivider />}
                >
                  {
                    MESSAGING_DATA.map((item, index) => {
                      return (
                        <ItemFontMessaging
                          key={index}
                          title={t(item.title)}
                          status={data?.[item.key]}
                          onClick={value =>
                            onUpdateKioskKitchenData(
                              { [item.key]: value },
                              'messaging',
                            )
                          }
                        />
                      )
                    })
                  }
                </S.ItemTransitionTime>
              </S.ItemTransitionItemWrapper>
            </S.ListTransitionItemWrapper>
          </S.FontColorWrapper>
        </LoadingWrapper>
      </BaseSpace>
    </S.RootWrapper>
  )
}

export default memo(KdsSettingLayout)
