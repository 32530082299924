import { DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import { IAppearance } from 'app/api/appearance/model'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { Loading } from 'app/components/common/Loading'
import * as SR from 'app/containers/admin/Settings/components/RightSide/styles'
import { APPERANCE_COLOR_TARGETS } from 'app/containers/admin/Settings/constant'
import {
  useGetRestaurantDataByLocation,
  useUpdateRestaurantDataByLocation,
} from 'app/react-query/hook/appearance'
import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ColorCustomSetting, ColorProfileSetting } from '../../components'
import ThemeSwitchComponent from '../../components/ThemeSwitch'
import * as S from './styles'

export const KEY_ALL_ID = 'all'
export const COLOR_PROFILE_LIST = [
  { colorName: R.strings.color_black, colorCode: '#000000' },
  { colorName: R.strings.color_white, colorCode: '#FFFFFF' },
  { colorName: R.strings.color_light_red, colorCode: '#F79992' },
  { colorName: R.strings.color_light_green, colorCode: '#76D0A3' },
  { colorName: R.strings.color_light_blue, colorCode: '#7EC2F3' },
  { colorName: R.strings.color_light_orange, colorCode: '#FAAF76' },
  { colorName: R.strings.color_light_gray, colorCode: '#666666' },
  { colorName: R.strings.color_light_cyan, colorCode: '#76CDD3' },
]

const AppearanceLayout = () => {
  const [restaurantLocation, setRestaurantLocation] = useState(KEY_ALL_ID)
  const [bgColor, setBGColor] = useState('transparent')
  const [textColor, setTextColor] = useState('#FFFFFF')
  const [buttonColor, setButtonColor] = useState('#29CC6A')
  const [customColorValue, setCustomColorValue] = useState(bgColor)
  const [customColorTarget, setCustomColorTarget] = useState(
    APPERANCE_COLOR_TARGETS.Background,
  )

  const { t } = useTranslation()

  const { data, isFetching: loading } =
    useGetRestaurantDataByLocation(restaurantLocation)
  const { mutate: mutateSaveAppearance, isLoading: loadingSave } =
    useUpdateRestaurantDataByLocation()

  useEffect(() => {
    const isAll = restaurantLocation === KEY_ALL_ID
    const newBGColor = isAll
      ? 'transparent'
      : data?.pallete_color1 || 'transparent'
    const newTextColor = isAll ? '#FFFFFF' : data?.pallete_color2 || '#FFFFFF'
    const newButtonColor = isAll ? '#29CC6A' : data?.pallete_color3 || '#29CC6A'

    setBGColor(newBGColor)
    setTextColor(newTextColor)
    setButtonColor(newButtonColor)
    handleCustomColorCompChange(
      'target',
      newBGColor,
      newTextColor,
      newButtonColor,
    )
  }, [data])

  useEffect(() => {
    handleCustomColorCompChange('color')
  }, [customColorValue])

  useEffect(() => {
    handleCustomColorCompChange('target')
  }, [customColorTarget])

  const handleCustomColorCompChange = (
    changeType: 'color' | 'target',
    newBGColor?: string,
    newTextColor?: string,
    newButtonColor?: string,
  ) => {
    switch (customColorTarget) {
      case APPERANCE_COLOR_TARGETS.Background:
        changeType === 'color' && setBGColor(customColorValue)
        changeType === 'target' && setCustomColorValue(newBGColor || bgColor)
        break
      case APPERANCE_COLOR_TARGETS.Text:
        changeType === 'color' && setTextColor(customColorValue)
        changeType === 'target' &&
          setCustomColorValue(newTextColor || textColor)
        break
      case APPERANCE_COLOR_TARGETS.Button:
        changeType === 'color' && setButtonColor(customColorValue)
        changeType === 'target' &&
          setCustomColorValue(newButtonColor || buttonColor)
        break
      default:
        break
    }
  }

  const save = () => {
    const body: IAppearance =
      {
        ...data,
        pallete_name: `${data?.location_name} color`,
        pallete_color1: bgColor,
        pallete_color2: textColor,
        pallete_color3: buttonColor,
        location_fk: restaurantLocation,
      } || {}

    mutateSaveAppearance(body)
  }

  return (
    <S.RootWrapper direction="vertical">
      <S.HeaderWrapper justify={'space-between'} align={'middle'}>
        <BaseCol>
          <SR.TitleSide>{t(R.strings.setting_menu_appearance_t)}</SR.TitleSide>
        </BaseCol>
        <BaseCol />
      </S.HeaderWrapper>

      <S.BodyWrapper gutter={[16, 16]} justify={'space-between'}>
        <S.BodyLeftSide>
          <S.YourRestaurantWrapper>
            <S.BodyLeftSideTitle>
              {t(R.strings.your_restaurant)}
            </S.BodyLeftSideTitle>

            <S.CustomSelectionRestaurant
              onChange={setRestaurantLocation}
              value={restaurantLocation}
              keyAllValue={KEY_ALL_ID}
              formItemName={false}
            />

            {/* color profile */}
            <ColorProfileSetting
              title={t(R.strings.background)}
              colorList={COLOR_PROFILE_LIST}
              quickSelectColorList={[
                COLOR_PROFILE_LIST[5],
                COLOR_PROFILE_LIST[7],
              ]}
              onChange={colorCode => {
                setBGColor(colorCode)
                customColorTarget === APPERANCE_COLOR_TARGETS.Background &&
                  setCustomColorValue(colorCode)
              }}
            />
            <ColorProfileSetting
              title={t(R.strings.text)}
              colorList={COLOR_PROFILE_LIST}
              quickSelectColorList={[
                COLOR_PROFILE_LIST[0],
                COLOR_PROFILE_LIST[1],
              ]}
              onChange={colorCode => {
                setTextColor(colorCode)
                customColorTarget === APPERANCE_COLOR_TARGETS.Text &&
                  setCustomColorValue(colorCode)
              }}
            />
            <ColorProfileSetting
              title={t(R.strings.button)}
              colorList={COLOR_PROFILE_LIST}
              quickSelectColorList={[
                COLOR_PROFILE_LIST[2],
                COLOR_PROFILE_LIST[3],
              ]}
              onChange={colorCode => {
                setButtonColor(colorCode)
                customColorTarget === APPERANCE_COLOR_TARGETS.Button &&
                  setCustomColorValue(colorCode)
              }}
            />

            {/* color custom */}
            <ColorCustomSetting
              title={t(R.strings.custom)}
              isLoading={loading}
              currentValue={customColorValue}
              onChange={setCustomColorValue}
              onTargetChange={setCustomColorTarget}
            />
          </S.YourRestaurantWrapper>
        </S.BodyLeftSide>

        <S.BodyRightSide flex={1}>
          <S.RightSideTitleWrapper justify={'space-between'}>
            <SR.Text $fontSize="24px" $fontWeight="700">
              {t(R.strings.review)}
            </SR.Text>
            <ThemeSwitchComponent />
          </S.RightSideTitleWrapper>

          <S.RestaurantAppearanceWrapper $bgColor={bgColor}>
            {/* menu appearance */}
            <S.MenuAppearanceWrapper>
              <BaseRow justify={'space-between'} align={'middle'}>
                <S.RestaurantInfo>
                  <S.RestaurantImage />
                  <S.RestaurantName>
                    <SR.Text
                      $fontSize="16px"
                      $fontWeight="700"
                      $color={textColor}
                    >
                      {t(R.strings.restaurant_name)}
                    </SR.Text>
                    <SR.Text
                      $fontSize="8px"
                      $fontWeight="400"
                      $color={textColor}
                    >
                      {t(R.strings.fake_time)}
                    </SR.Text>
                  </S.RestaurantName>
                </S.RestaurantInfo>
                <S.FakeInputSearch $color={textColor}>
                  <SearchOutlined rev={null} />
                  <span className="placeholder">
                    {t(R.strings.input_placeholder_search)}
                  </span>
                </S.FakeInputSearch>
              </BaseRow>

              <S.FakeTab $color={textColor}>
                <span className="label">
                  {t(R.strings.fake_tab_hot_dishes)}
                </span>
                <span className="label">
                  {t(R.strings.fake_tab_cold_dishes)}
                </span>
                <span className="label">{t(R.strings.fake_tab_soup)}</span>
                <span className="label">{t(R.strings.fake_tab_grill)}</span>
                <span className="label">{t(R.strings.fake_tab_appetizer)}</span>
                <span className="label">{t(R.strings.fake_tab_dessert)}</span>
                <span className="color-bar"></span>
              </S.FakeTab>

              <S.ChooseDishes $fontSize="12px" $color={textColor}>
                {t(R.strings.choose_dishes)}
              </S.ChooseDishes>

              <S.DishesWrapper
                justify={'space-between'}
                align={'middle'}
                gutter={[32, 32]}
              >
                <S.DishesItem $color={textColor}>
                  <div className="dishes-data">
                    <img
                      className="img"
                      src={
                        'https://storage.googleapis.com/kiosify-01/images/img_food_1.png'
                      }
                    ></img>
                    <div className="content">
                      <div className="name">
                        {t(R.strings.spicy_seasoned_seafood_noodles)}
                      </div>
                      <div className="price">$ 2.29</div>
                      <div className="number-available">
                        20 {t(R.strings.bowls_available)}
                      </div>
                    </div>
                  </div>
                </S.DishesItem>
                <S.DishesItem $color={textColor}>
                  <div className="dishes-data">
                    <img
                      className="img"
                      src={
                        'https://storage.googleapis.com/kiosify-01/images/img_food_2.png'
                      }
                    ></img>
                    <div className="content">
                      <div className="name">
                        {t(R.strings.salted_pasta_with_mushroom_sauce)}
                      </div>
                      <div className="price">$ 2.69</div>
                      <div className="number-available">
                        11 {t(R.strings.bowls_available)}
                      </div>
                    </div>
                  </div>
                </S.DishesItem>
                <S.DishesItem $color={textColor}>
                  <div className="dishes-data">
                    <img
                      className="img"
                      src={
                        'https://storage.googleapis.com/kiosify-01/images/img_food_3.png'
                      }
                    ></img>
                    <div className="content">
                      <div className="name">
                        {t(R.strings.beef_dumpling_in_hot_and_sour_soup)}
                      </div>
                      <div className="price">$ 2.99</div>
                      <div className="number-available">
                        16 {t(R.strings.bowls_available)}
                      </div>
                    </div>
                  </div>
                </S.DishesItem>
                <S.DishesItem $color={textColor}>
                  <div className="dishes-data">
                    <img
                      className="img"
                      src={
                        'https://storage.googleapis.com/kiosify-01/images/img_food_4.png'
                      }
                    ></img>
                    <div className="content">
                      <div className="name">
                        {t(R.strings.healthy_noodle_with_spinach_leaf)}
                      </div>
                      <div className="price">$ 3.39</div>
                      <div className="number-available">
                        22 {t(R.strings.bowls_available)}
                      </div>
                    </div>
                  </div>
                </S.DishesItem>
                <S.DishesItem $color={textColor}>
                  <div className="dishes-data">
                    <img
                      className="img"
                      src={
                        'https://storage.googleapis.com/kiosify-01/images/img_food_4.png'
                      }
                    ></img>
                    <div className="content">
                      <div className="name">
                        {t(R.strings.hot_spicy_fried_rice_with_omelet)}
                      </div>
                      <div className="price">$ 3.49</div>
                      <div className="number-available">
                        13 {t(R.strings.bowls_available)}
                      </div>
                    </div>
                  </div>
                </S.DishesItem>
                <S.DishesItem $color={textColor}>
                  <div className="dishes-data">
                    <img
                      className="img"
                      src={
                        'https://storage.googleapis.com/kiosify-01/images/img_food_5.png'
                      }
                    ></img>
                    <div className="content">
                      <div className="name">
                        {t(
                          R.strings.spicy_instant_noodle_with_special_omelette,
                        )}
                      </div>
                      <div className="price">$ 3.59</div>
                      <div className="number-available">
                        17 {t(R.strings.bowls_available)}
                      </div>
                    </div>
                  </div>
                </S.DishesItem>
              </S.DishesWrapper>
            </S.MenuAppearanceWrapper>

            {/* checkout appearance */}
            <S.CheckoutAppearanceWrapper>
              <SR.Text $fontSize="12px" $fontWeight="700" $color={textColor}>
                {t(R.strings.table_order)} #34562
              </SR.Text>
              <S.CheckoutHeaderItemWrapper>
                <S.CheckoutHeaderItem flex={1} $color={textColor}>
                  {t(R.strings.item)}
                </S.CheckoutHeaderItem>
                <S.CheckoutHeaderItem $color={textColor}>
                  {t(R.strings.qty)}
                </S.CheckoutHeaderItem>
                <S.CheckoutHeaderItem $color={textColor}>
                  {t(R.strings.price)}
                </S.CheckoutHeaderItem>
              </S.CheckoutHeaderItemWrapper>

              <S.SplitDivider />

              <S.CheckoutItemWrapper>
                <S.CheckoutItem>
                  <S.CheckoutItemFirstRow>
                    <BaseCol flex={1} className="food-info">
                      <img
                        src={
                          'https://storage.googleapis.com/kiosify-01/images/img_food_1.png'
                        }
                      />
                      <div className="info-data">
                        <SR.Text
                          $fontSize="8px"
                          $fontWeight="700"
                          $color={textColor}
                        >
                          {t(R.strings.spicy_seasoned_seafood_noodles_short)}
                        </SR.Text>
                        <SR.Text
                          $fontSize="8px"
                          $fontWeight="400"
                          $color={textColor}
                        >
                          $ 2.29
                        </SR.Text>
                      </div>
                    </BaseCol>
                    <BaseCol className="input-qty">
                      <S.FakeInput $color={textColor} $placeholder={false}>
                        <span className="content">2</span>
                      </S.FakeInput>
                    </BaseCol>
                    <BaseCol className="price" style={{ color: textColor }}>
                      $ 4,58
                    </BaseCol>
                  </S.CheckoutItemFirstRow>
                  <S.CheckoutItemSecondRow>
                    <BaseCol flex={1} style={{ paddingRight: 12 }}>
                      <S.FakeInput
                        $color={textColor}
                        $placeholder={false}
                        $width="100%"
                      >
                        <span className="content">
                          {t(R.strings.fake_order_note)}
                        </span>
                      </S.FakeInput>
                    </BaseCol>
                    <BaseCol>
                      <S.FakeInput
                        $color={textColor}
                        $placeholder={false}
                        $borderColor="#EA7C69"
                        $iconColor="#EA7C69"
                        $bgColor="transparent"
                      >
                        <DeleteOutlined rev={null} />
                      </S.FakeInput>
                    </BaseCol>
                  </S.CheckoutItemSecondRow>
                </S.CheckoutItem>
                <S.CheckoutItem>
                  <S.CheckoutItemFirstRow>
                    <BaseCol flex={1} className="food-info">
                      <img
                        src={
                          'https://storage.googleapis.com/kiosify-01/images/img_food_2.png'
                        }
                      />
                      <div className="info-data">
                        <SR.Text
                          $fontSize="8px"
                          $fontWeight="700"
                          $color={textColor}
                        >
                          {t(R.strings.salted_pasta_with_mushroom_sauce_short)}
                        </SR.Text>
                        <SR.Text
                          $fontSize="8px"
                          $fontWeight="400"
                          $color={textColor}
                        >
                          $ 2.69
                        </SR.Text>
                      </div>
                    </BaseCol>
                    <BaseCol className="input-qty">
                      <S.FakeInput $color={textColor} $placeholder={false}>
                        <span className="content">1</span>
                      </S.FakeInput>
                    </BaseCol>
                    <BaseCol className="price" style={{ color: textColor }}>
                      $ 2.69
                    </BaseCol>
                  </S.CheckoutItemFirstRow>
                  <S.CheckoutItemSecondRow>
                    <BaseCol flex={1} style={{ paddingRight: 12 }}>
                      <S.FakeInput
                        $color={textColor}
                        $placeholder={true}
                        $width="100%"
                      >
                        <span className="content">
                          {t(R.strings.input_placeholder_order_note)}
                        </span>
                      </S.FakeInput>
                    </BaseCol>
                    <BaseCol>
                      <S.FakeInput
                        $color={textColor}
                        $placeholder={false}
                        $borderColor="#EA7C69"
                        $iconColor="#EA7C69"
                        $bgColor="transparent"
                      >
                        <DeleteOutlined rev={null} />
                      </S.FakeInput>
                    </BaseCol>
                  </S.CheckoutItemSecondRow>
                </S.CheckoutItem>
                <S.CheckoutItem>
                  <S.CheckoutItemFirstRow>
                    <BaseCol flex={1} className="food-info">
                      <img
                        src={
                          'https://storage.googleapis.com/kiosify-01/images/img_food_3.png'
                        }
                      />
                      <div className="info-data">
                        <SR.Text
                          $fontSize="8px"
                          $fontWeight="700"
                          $color={textColor}
                        >
                          {t(
                            R.strings
                              .spicy_instant_noodle_with_special_omelette_short,
                          )}
                        </SR.Text>
                        <SR.Text
                          $fontSize="8px"
                          $fontWeight="400"
                          $color={textColor}
                        >
                          $ 3.49
                        </SR.Text>
                      </div>
                    </BaseCol>
                    <BaseCol className="input-qty">
                      <S.FakeInput $color={textColor} $placeholder={false}>
                        <span className="content">3</span>
                      </S.FakeInput>
                    </BaseCol>
                    <BaseCol className="price" style={{ color: textColor }}>
                      $ 10.47
                    </BaseCol>
                  </S.CheckoutItemFirstRow>
                  <S.CheckoutItemSecondRow>
                    <BaseCol flex={1} style={{ paddingRight: 12 }}>
                      <S.FakeInput
                        $color={textColor}
                        $placeholder={true}
                        $width="100%"
                      >
                        <span className="content">
                          {t(R.strings.input_placeholder_order_note)}
                        </span>
                      </S.FakeInput>
                    </BaseCol>
                    <BaseCol>
                      <S.FakeInput
                        $color={textColor}
                        $placeholder={false}
                        $borderColor="#EA7C69"
                        $iconColor="#EA7C69"
                        $bgColor="transparent"
                      >
                        <DeleteOutlined rev={null} />
                      </S.FakeInput>
                    </BaseCol>
                  </S.CheckoutItemSecondRow>
                </S.CheckoutItem>
                <S.CheckoutItem>
                  <S.CheckoutItemFirstRow>
                    <BaseCol flex={1} className="food-info">
                      <img
                        src={
                          'https://storage.googleapis.com/kiosify-01/images/img_food_4.png'
                        }
                      />
                      <div className="info-data">
                        <SR.Text
                          $fontSize="8px"
                          $fontWeight="700"
                          $color={textColor}
                        >
                          {t(R.strings.healthy_noodle_with_spinach_leaf_short)}
                        </SR.Text>
                        <SR.Text
                          $fontSize="8px"
                          $fontWeight="400"
                          $color={textColor}
                        >
                          $ 3.29
                        </SR.Text>
                      </div>
                    </BaseCol>
                    <BaseCol className="input-qty">
                      <S.FakeInput $color={textColor} $placeholder={false}>
                        <span className="content">1</span>
                      </S.FakeInput>
                    </BaseCol>
                    <BaseCol className="price" style={{ color: textColor }}>
                      $ 3.29
                    </BaseCol>
                  </S.CheckoutItemFirstRow>
                  <S.CheckoutItemSecondRow>
                    <BaseCol flex={1} style={{ paddingRight: 12 }}>
                      <S.FakeInput
                        $color={textColor}
                        $placeholder={true}
                        $width="100%"
                      >
                        <span className="content">
                          {t(R.strings.input_placeholder_order_note)}
                        </span>
                      </S.FakeInput>
                    </BaseCol>
                    <BaseCol>
                      <S.FakeInput
                        $color={textColor}
                        $placeholder={false}
                        $borderColor="#EA7C69"
                        $iconColor="#EA7C69"
                        $bgColor="transparent"
                      >
                        <DeleteOutlined rev={null} />
                      </S.FakeInput>
                    </BaseCol>
                  </S.CheckoutItemSecondRow>
                </S.CheckoutItem>
              </S.CheckoutItemWrapper>

              <S.TotalItem justify={'space-between'}>
                <SR.Text
                  $fontSize="8px"
                  $fontWeight="400"
                  $opacity="0.8"
                  $color={textColor}
                >
                  {t(R.strings.discount)}
                </SR.Text>
                <SR.Text $fontSize="8px" $fontWeight="400" $color={textColor}>
                  $ 0
                </SR.Text>
              </S.TotalItem>
              <S.TotalItem justify={'space-between'}>
                <SR.Text
                  $fontSize="8px"
                  $fontWeight="400"
                  $opacity="0.8"
                  $color={textColor}
                >
                  {t(R.strings.sub_total)}
                </SR.Text>
                <SR.Text $fontSize="8px" $fontWeight="400" $color={textColor}>
                  $ 21.03
                </SR.Text>
              </S.TotalItem>

              <S.FakeInput
                $color={textColor}
                $placeholder={false}
                $borderColor="transparent"
                $iconColor="#EA7C69"
                $bgColor={buttonColor}
                $width="100%"
                $textCenter={true}
                $margin="30px 0 0 0"
              >
                <span className="content">
                  {t(R.strings.continue_to_payment)}
                </span>
              </S.FakeInput>
            </S.CheckoutAppearanceWrapper>
            {loading && <Loading />}
          </S.RestaurantAppearanceWrapper>

          <S.SaveButton onClick={save} loading={loadingSave}>
            {t(R.strings.save)}
          </S.SaveButton>
        </S.BodyRightSide>
      </S.BodyWrapper>
    </S.RootWrapper>
  )
}

export default memo(AppearanceLayout)
