/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteOutlined } from '@ant-design/icons'
import {
  IItemProductManagement,
  IParamsUpdateStatus,
  IResponseQueryList,
  IUpSellProductManagement,
} from 'app/api/product-management/model'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { Loading } from 'app/components/common/Loading'
import { formatMoneyDollar, usePagination } from 'app/hook'
import {
  useDeleteUpSellStatusById,
  useGetUpSellListByItem,
} from 'app/react-query/hook/product-management'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import { useMemo } from 'react'
import styled from 'styled-components'

import { BasePopconfirm } from 'app/components/common/BasePopconfirm'
import { useTranslation } from 'react-i18next'
import AddYourUpSellsModal, {
  FormLocationTypeEnum,
} from '../../ProductManagement/tabs/UpSell/components/AddYourUpSellsModal'
import * as S from '../styles'

const UpSellItem = ({
  item,
  onDelete,
}: {
  item: IUpSellProductManagement
  onDelete: (item: IUpSellProductManagement) => void
}) => {
  const { t } = useTranslation()

  return (
    <UpSellItemWrapper
      gutter={[10, 10]}
      justify={'space-between'}
      align={'middle'}
    >
      <BaseCol>
        <BaseRow gutter={[10, 10]} align={'middle'}>
          <BaseCol>
            <S.InfoLeftSideImage
              width={40}
              height={40}
              src={
                item?.image ??
                'https://storage.googleapis.com/kiosify-01/images/ic_logo_kiosify.png'
              }
            />
          </BaseCol>
          <BaseCol className="label">
            <BaseSpace size={10} direction="vertical">
              <BaseCol>{item?.item_id?.[0]?.name ?? '-'}</BaseCol>
              <BaseCol>
                {item?.price
                  ? formatMoneyDollar(item?.price ?? '0', item?.currency)
                  : '-'}
              </BaseCol>
            </BaseSpace>
          </BaseCol>
        </BaseRow>
      </BaseCol>
      <BaseRow gutter={[10, 10]} align={'middle'}>
        <BaseCol className="svg">
          <BasePopconfirm
            title={t(R.strings.product_management_delete_item_up_sell)}
            description={`${t(
              R.strings.product_management_delete_item_up_sell_description,
            )} ${item?.varation}`}
            onConfirm={() => onDelete(item)}
          >
            <DeleteOutlined rev={undefined} />
          </BasePopconfirm>
        </BaseCol>
      </BaseRow>
    </UpSellItemWrapper>
  )
}

export const UpSellList = ({ item }: { item: IItemProductManagement }) => {
  const { t } = useTranslation()
  const { data, isLoading: loading } = useGetUpSellListByItem({
    page_size: 100,
    item_id: item?.item_id,
  })
  const { flattenDataList } = usePagination()

  const upSellData = useMemo(() => {
    const upSell: IResponseQueryList<IUpSellProductManagement[]> =
      flattenDataList(data)
    return upSell
  }, [data])

  const upSellList = useMemo(() => {
    return upSellData?.data
  }, [upSellData])

  const {
    mutateAsync: mutateAsyncDeleteUpSellStatusById,
    isLoading: loadingDelete,
  } = useDeleteUpSellStatusById()

  const deleteUpSell = (item: IUpSellProductManagement) => {
    if (!item) return

    const params: IParamsUpdateStatus = {
      id: item?.id,
    }

    mutateAsyncDeleteUpSellStatusById(params)
  }

  return (
    <S.RootWrapper direction="vertical" size={5}>
      <S.UpSellWrapper direction="vertical" size={15}>
        <div className="title">{t(R.strings.product_management_up_sell)}</div>
        {upSellList?.length < 6 && (
          <AddYourUpSellsModal
            type={FormLocationTypeEnum.category}
            itemInit={item}
            listUpSellExist={upSellList}
          />
        )}
        {upSellList?.map((item, index) => {
          return <UpSellItem key={index} item={item} onDelete={deleteUpSell} />
        })}
        {(loading || loadingDelete) && <Loading />}
      </S.UpSellWrapper>
    </S.RootWrapper>
  )
}

const UpSellItemWrapper = styled(BaseRow)`
  padding: ${PADDING.xxs};
  border-radius: ${BORDER_RADIUS};
  margin: 0px !important;
  background-color: rgba(255, 255, 255, 0.1); // need change
  .label {
    color: var(--background-color);
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
  }
  .svg {
    svg {
      fill: var(--background-color);
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
`
