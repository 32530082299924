import { BaseCol } from 'app/components/common/BaseCol'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import {
  PADDING,
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
} from 'parkway-web-common'
import styled from 'styled-components'
import SelectionRestaurant from '../../components/SelectionLocation'

export const RootWrapper = styled.div``

export const HeaderSplitDivider = styled(BaseDivider)`
  margin: 4px 0;
  background-color: rgba(255, 255, 255, 0.1); // need change
`

// info
export const InfoWrapper = styled(BaseRow)`
  padding: ${PADDING.xxxs};
  width: 100%;
`

export const InfoImageWrapper = styled(BaseCol)`
  width: 20%;
  border-radius: ${BORDER_RADIUS};
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const InfoContentWrapper = styled(BaseSpace)`
  width: 79%;
`

export const SplitDivider = styled(BaseDivider)`
  margin: 0 0.5rem;
  background-color: rgba(255, 255, 255, 0.1); // need change
`

export const InfoContentItemWrapper = styled(BaseCol)``

export const SpaceVertical = styled.div`
  height: 5px;
`
export const InfoContentItemTitle = styled.span`
  color: #abbbc2; // need change
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.regular};
`

export const InfoContentItemDescription = styled.span`
  color: var(--background-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
`

// general
export const GeneralWrapper = styled(BaseRow)`
  padding: ${PADDING.xxxs};
  width: 100%;
`

export const GeneralHeader = styled.div`
  width: 100%;
`

export const GeneralContent = styled.div`
  width: 100%;
`

export const GeneralContentNotFound = styled.div`
  width: 100%;
`

export const GeneralContentItem = styled(BaseRow)`
  width: 100%;
  margin-bottom: 16px;
  padding: 8px 0;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
`

export const GeneralContentItemName = styled.div`
  color: var(--background-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
`

export const GeneralContentItemId = styled.div`
  color: var(--background-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
`

// connect and manage
export const ConnectManageWrapper = styled(BaseRow)`
  padding: ${PADDING.xxxs};
  width: 100%;
`

export const ConnectManageHeader = styled.div`
  width: 100%;
`

export const ConnectManageContent = styled.div`
  width: 100%;
`

export const ConnectManageContentItem = styled(BaseRow)`
  width: 100%;
  margin-bottom: 16px;
  padding: 8px 0;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
`

export const ConnectManageContentItemTitle = styled.div`
  color: var(--background-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
`

export const CustomSelectionRestaurant = styled(SelectionRestaurant)`
  .ant-form-item-control-input {
    height: 24px;
    min-height: unset;
  }
  .ant-select {
    height: 24px;
    border: unset;
    & .ant-select-selector {
      background-color: transparent !important;
      padding-left: 0 !important;
      color: #ffffff;
      border: unset !important;
      border-radius: 20px;
      box-shadow: unset !important;
    }
    & .ant-select-arrow {
      top: 50% !important;
      right: 0;
      .anticon svg {
        fill: #475569 !important;
      }
    }
    & .ant-select-selection-item {
      color: #ffffff !important;
    }
    & .ant-select-dropdown {
      background-color: #ffffff;
      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: #29cc6a38 !important;
      }
    }
  }
`
