import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div``

export const ItemRestaurant = styled.div`
  border-width: 2px;
  border-style: dotted;
  border-color: transparent;
  cursor: pointer;
  border-radius: ${BORDER_RADIUS};
  transition: all 1s ease;
  width: 150px;
  height: 100%;
  &:hover {
    border-style: dotted;
    border-color: var(--admin-background-focus-setting-menu-color);
    box-shadow: rgba(41, 204, 106, 0.3) 0px 0px 30px 10px,
      rgba(41, 204, 106, 0.2) 0px 5px 5px -2px;
    transition: all 1s ease;
  }
`

export const HeadItemRestaurant = styled.div`
  background-color: #393c49; //need change
  border-radius: ${BORDER_RADIUS};
  padding: ${PADDING.xxxl} 2.75rem;
  transition: all 1s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    transition: all 1s ease;
    background-color: var(--admin-background-focus-setting-menu-color);
  }
`

export const ImageHeadItem = styled(BaseImage)`
  border-radius: ${BORDER_RADIUS};
`

export const NameRestaurant = styled(BaseRow)`
  color: rgba(171, 187, 194, 1); //need change
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
  padding: ${PADDING.xxxs};
  width: 100%;
  text-align: center;
`

export const ListWrapper = styled(BaseRow)`
  padding: ${PADDING.xxxl} 0px;
`
