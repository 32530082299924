import styled, { css } from 'styled-components'
import { LAYOUT, media } from 'parkway-web-common'
import { BaseLayout } from 'app/components/common/BaseLayout'

interface HeaderProps {
  $isTwoColumnsLayout: boolean
}

export default styled(BaseLayout.Content)<HeaderProps>`
  padding: ${LAYOUT.mobile.paddingVertical} ${LAYOUT.mobile.paddingHorizontal};
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--admin-background-primary-color);

  @media only screen and ${media.md} {
    padding: ${LAYOUT.desktop.paddingVertical}
      ${LAYOUT.desktop.paddingHorizontal};
  }

  @media only screen and ${media.xs} {
    padding: 0px;
  }

  @media only screen and ${media.xl} {
    ${props =>
      props?.$isTwoColumnsLayout &&
      css`
        padding: 0;
      `}
  }
`
