import { BaseTable } from 'app/components/common/BaseTable'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
  colors,
} from 'parkway-web-common'
import styled from 'styled-components'

export const BaseTableWrapper = styled(BaseTable)`
  &.no-border {
    .ant-table-cell {
      border: unset !important;
    }
    .ant-table-cell::before {
      background-color: transparent !important;
    }
  }
  .row-overwrite-style {
    .ant-table-cell {
      border: 0.5px solid transparent !important;
      background-color: rgba(255, 255, 255, 0.1);
    }

    .ant-table-cell-row-hover {
      background-color: transparent !important;
    }

    .ant-table-cell-scrollbar {
      display: none !important;
    }
  }

  .ant-table-cell {
    border: 0.5px solid transparent !important;
  }

  .ant-table-tbody > tr > td {
    border: 0.5px solid transparent !important;
  }

  .ant-table-wrapper .ant-table-thead > tr > td {
    background: transparent !important;
  }

  .ant-table-placeholder {
    background: transparent !important;
    border-radius: ${BORDER_RADIUS};
    margin-top: ${PADDING.xxxs} !important;
  }

  .ant-table-cell-row-hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }

  .ant-table-placeholder:hover > td {
    background: transparent !important;
  }

  .ant-table-placeholder .ant-empty-description {
    color: var(--background-color);
  }

  .antd-table {
    background: transparent !important;
  }

  background: transparent !important;

  .ant-table-thead .ant-table-cell-fix-left {
    background-color: var(--admin-collapse-background-color) !important;
    background: var(--admin-collapse-background-color) !important;
  }


  .ant-table-cell-fix-left {
    background-color: var(--admin-collapse-background-color) !important;
  }

  .ant-table-thead > tr > th {
    border-bottom: 0.5px solid #ebeef2 !important;
    text-align: left !important;
    font-size: 12px !important;
    background-color: transparent ;
    background: transparent ;
  }

  .very-big-column {
    min-width: 300px !important;
  }

  .big-column {
    min-width: 200px !important;
  }

  .normal-column {
    min-width: 150px !important;
  }

  .medium-column {
    min-width: 100px !important;
  }

  .small-column {
    min-width: 30px !important;
  }

`

export const AreaRowWrapper = styled(BaseRow)`
  width: 100%;
  position: absolute !important;
  top: ${PADDING.md};
`

export const AreaText = styled.div`
  margin-left: ${PADDING.xxs};
  font-weight: ${FONT_WEIGHT.bold};
`

export const RevenueTableWrapper = styled(BaseRow) <{
  isBold?: boolean
  isBlueText?: boolean
}>`
  align-item: center;
  font-weight: ${props =>
    props?.isBold ? FONT_WEIGHT.bold : FONT_WEIGHT?.medium};
  color: ${props =>
    props?.isBlueText && props.isBold ? '#216AE2' : 'var(--background-color)'};
`

export const NoWrapper = styled.div`
  position: absolute !important;
  top: ${PADDING.md};
`

export const HeadingTitlePage = styled.p`
  font-size: ${FONT_SIZE.xxl};
  color: var(--background-color);
  font-weight: ${FONT_WEIGHT.bold};
  padding: ${PADDING.md} 0px;
`

export const RootWrapper = styled(BaseCol)`
  background-color: ${colors.white};
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
`

export const HeadWrapper = styled(BaseRow)``

export const TitleTable = styled.span`
  font-size: ${FONT_SIZE.xs};
  color: var(--background-color);
  font-weight: ${FONT_WEIGHT.bold};
`
