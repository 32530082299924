import { MenuProps, Space } from 'antd';
import { memo } from 'react';
import * as S from './styles';
import { useTranslation } from 'react-i18next';
import R from 'app/assets/R';

interface ColorItem {
  colorName: string;
  colorCode: string;
}

interface ColorProfileProps {
  title: string;
  colorList: ColorItem[];
  quickSelectColorList: ColorItem[];
  onChange?: (colorCode: string) => void;
}

const ColorProfile = (props: ColorProfileProps) => {
  const { t } = useTranslation()

  const actionDropdownMenuClick: MenuProps['onClick'] = (e) => {
    props.onChange && props.onChange(props.colorList[+e.key].colorCode)
  }

  const actionDropdownItems: MenuProps['items'] = props.colorList.map((item, index) => {
    return {
      key: index,
      label: (
        <S.ActionDropdownMenuItem
          key={index}
          $bgColor={item.colorCode}
        >
          <div className="color-box"></div>
          <span className="color-name">{t(item.colorName)}</span>
        </S.ActionDropdownMenuItem>
      ),
    }
  })

  const actionDropdownMenu = {
    items: actionDropdownItems,
    onClick: actionDropdownMenuClick,
  }

  return (
    <S.RootWrapper>
      <S.TitleWrapper
        justify={'space-between'}
        align={'middle'}
      >
        <div className="title">
          {props.title}
        </div>
        <S.ActionDropdown
          menu={actionDropdownMenu}
          trigger={['click']}
          placement="bottomRight"
        >
          <Space>
            {t(R.strings.see_all)}
          </Space>
        </S.ActionDropdown>
      </S.TitleWrapper>

      {
        props.quickSelectColorList.length > 0
        &&
        props.quickSelectColorList.map((item, index) => {
          return (
            <S.QuickColorSelectItem
              key={index}
              $bgColor={item.colorCode}
              onClick={() => props.onChange?.(item.colorCode)}
            >
              <div className="color-name">{t(item.colorName)}</div>
              <div className="color-bar"></div>
            </S.QuickColorSelectItem>
          )
        })
      }
    </S.RootWrapper>
  )
}

export default memo(ColorProfile)