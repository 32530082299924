/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetAppearanceList, requestUpdateByLocation } from 'app/api/appearance/api'
import { IAppearance, ResponseAppearanceList } from 'app/api/appearance/model'
import R from 'app/assets/R'
import { LOGOUT_PATH } from 'app/components/router/route-path'
import { notificationController } from 'app/controllers/notification-controller'
import { t } from 'i18next'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router'
import { KEY_ALL_ID } from 'app/containers/admin/Settings/components/RightSide/layout/Appearance'

export function useGetRestaurantDataByLocation(location_fk: string, enabled = true) {
  const navigate = useNavigate()
  return useQuery(
    location_fk,
    async () => {
      if(location_fk === KEY_ALL_ID) return null

      try {
        const response: ResponseAppearanceList = await requestGetAppearanceList(
          {
            location_fk,
          },
        )

          return response?.results?.[0] ?? null
      } catch (error: any) {
        console.log({ error }, 'appearance.ts')
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return {}
        }
        return {}
      }
    },
    {
      enabled,
      cacheTime: 5000,
      keepPreviousData: true
    },
  )
}

export const useUpdateRestaurantDataByLocation = () => {
  const navigate = useNavigate()
  return useMutation(
    async (body: IAppearance) => {
      try {
        const res = await requestUpdateByLocation(body)

        notificationController.success({
          message: t(R.strings.save_success),
        })
        
        return res
      } catch (error: any) {
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
        }
      }
    },
  )
}
