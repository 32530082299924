import { RightOutlined } from '@ant-design/icons'
import { requestChangeLogoUser } from 'app/api/auth'
import R from 'app/assets/R'
import UploadImage from 'app/components/common/BaseUpload/components/UploadImage'
import { LanguagePicker } from 'app/components/header/admin/components/SettingsDropdown/settings-overlay/LanguagePicker'
import * as SR from 'app/containers/admin/Settings/components/RightSide/styles'
import { URL_SETTING } from 'app/containers/admin/Settings/constant'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import {
  setDeviceSetting,
  setPathSetting,
} from 'app/redux/slices/pathSettingSlice'
import { getUserInfo } from 'app/redux/slices/userSlice'
import { requestUploadImage } from 'app/service/general/api'
import { ResponseUploadFile } from 'app/service/general/model'
import { isEmpty } from 'lodash'
import { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

interface DeviceItem {
  name: string
  id: string
}

const fakeGeneralDevice: DeviceItem[] = [
  {
    name: 'Device Name 1',
    id: '12345678ASDKQW19283DSAJH1298',
  },
  {
    name: 'Device Name 2',
    id: '12345678ASDKQW19283DSAJH1298',
  },
  {
    name: 'Device Name 3',
    id: '12345678ASDKQW19283DSAJH1298',
  },
]

const YourInformationLayout = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(state => state.user)
  const { t, i18n } = useTranslation()
  const [restaurantLocation, setRestaurantLocation] = useState('')
  const [loading, setLoading] = useState(false)

  const navigateToDeviceDetail = (data: DeviceItem) => {
    dispatch(setPathSetting(URL_SETTING.DeviceDetail))
    dispatch(setDeviceSetting(data))
  }

  const connectManageItem = useMemo(
    () => ({
      kmStand: {
        title: R.strings.your_infomation_km_stand,
        id: 'kmStand',
      },
      kmPrinters: {
        title: R.strings.your_infomation_km_printers,
        id: 'kmPrinters',
      },
      kmReaders: {
        title: R.strings.your_infomation_km_readers,
        id: 'kmReaders',
      },
    }),
    [i18n.language],
  )

  const handleClickConnectManageItem = (itemId: string) => {
    // do something
    console.log('handleClickConnectManageItem ran, itemId is', itemId)

    switch (itemId) {
      case connectManageItem.kmStand.id:
        break
      case connectManageItem.kmPrinters.id:
        dispatch(setPathSetting(URL_SETTING.PrinterConfig))
        // dispatch(setDeviceSetting(data))
        break
      case connectManageItem.kmReaders.id:
        break
      default:
        break
    }
  }

  const handleChangeImage = async (imageFile: File) => {
    try {
      if (!imageFile) return
      setLoading(true)
      const body = new FormData()
      body.append('image', imageFile)

      const res: ResponseUploadFile = await requestUploadImage(body)
      if (res?.data && res.data?.image) {
        await requestChangeLogoUser(res.data?.image)
        dispatch(getUserInfo())
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <S.RootWrapper>
      <SR.TitleSide>{t(R.strings.about)}</SR.TitleSide>

      {/* info */}
      <S.InfoWrapper
        gutter={[16, 16]}
        align={'middle'}
        justify={'space-between'}
      >
        <S.InfoImageWrapper>
          <UploadImage
            src={
              !isEmpty(user?.user?.logo)
                ? user?.user?.logo
                : 'https://storage.googleapis.com/kiosify-01/images/ic_logo_kiosify.png'
            }
            isLoading={loading}
            onChange={handleChangeImage}
          />
        </S.InfoImageWrapper>
        <S.InfoContentWrapper
          direction="vertical"
          size={10}
          split={<S.SplitDivider />}
        >
          <S.InfoContentItemWrapper>
            <S.InfoContentItemTitle>
              {t(R.strings.information_location)}
            </S.InfoContentItemTitle>
            <S.SpaceVertical />
            <S.InfoContentItemDescription>
              <S.CustomSelectionRestaurant
                onChange={setRestaurantLocation}
                value={restaurantLocation}
                formItemName={false}
                allowSelectAll={false}
                selectFirstValue
              />
            </S.InfoContentItemDescription>
          </S.InfoContentItemWrapper>
          <S.InfoContentItemWrapper>
            <S.InfoContentItemTitle>
              {t(R.strings.language)}
            </S.InfoContentItemTitle>
            <S.SpaceVertical />
            <S.InfoContentItemDescription>
              <LanguagePicker initValue={user?.user?.language} type="user" />
            </S.InfoContentItemDescription>
          </S.InfoContentItemWrapper>
          <S.InfoContentItemWrapper>
            <S.InfoContentItemTitle>
              {t(R.strings.information_branch)}
            </S.InfoContentItemTitle>
            <S.SpaceVertical />
            <S.InfoContentItemDescription>
              {'Banh Mi Zon Vietnamese Gourmet'}
            </S.InfoContentItemDescription>
          </S.InfoContentItemWrapper>
          <S.InfoContentItemWrapper>
            <S.InfoContentItemTitle>
              {t(R.strings.information_phone_no)}
            </S.InfoContentItemTitle>
            <S.SpaceVertical />
            <S.InfoContentItemDescription>
              {'+ 1 415 - 521 - 5289'}
            </S.InfoContentItemDescription>
          </S.InfoContentItemWrapper>
          <S.InfoContentItemWrapper>
            <S.InfoContentItemTitle>
              {t(R.strings.information_email)}
            </S.InfoContentItemTitle>
            <S.SpaceVertical />
            <S.InfoContentItemDescription>
              {'San-Rafael@grubbrr.com'}
            </S.InfoContentItemDescription>
          </S.InfoContentItemWrapper>
        </S.InfoContentWrapper>
      </S.InfoWrapper>

      {/* genera */}
      <S.GeneralWrapper
        gutter={[16, 4]}
        align={'middle'}
        justify={'space-between'}
      >
        <S.GeneralHeader>
          <S.InfoContentItemTitle>
            {t(R.strings.general)}
          </S.InfoContentItemTitle>
          <S.HeaderSplitDivider />
        </S.GeneralHeader>

        <S.GeneralContent>
          {fakeGeneralDevice.length === 0 ? (
            <S.GeneralContentNotFound>
              {t(R.strings.your_infomation_no_device)}
            </S.GeneralContentNotFound>
          ) : (
            fakeGeneralDevice.map((item, index) => {
              return (
                <S.GeneralContentItem
                  key={`${item} ${index}`}
                  align={'middle'}
                  justify={'space-between'}
                  onClick={() => navigateToDeviceDetail(item)}
                >
                  <S.GeneralContentItemName>
                    {item.name}
                  </S.GeneralContentItemName>
                  <S.GeneralContentItemId>{item.id}</S.GeneralContentItemId>
                </S.GeneralContentItem>
              )
            })
          )}
        </S.GeneralContent>
      </S.GeneralWrapper>

      {/* connect and manage */}
      <S.ConnectManageWrapper
        gutter={[16, 4]}
        align={'middle'}
        justify={'space-between'}
      >
        <S.ConnectManageHeader>
          <S.InfoContentItemTitle>
            {t(R.strings.connect_and_manage)}
          </S.InfoContentItemTitle>
          <S.HeaderSplitDivider />
        </S.ConnectManageHeader>

        <S.ConnectManageContent>
          {Object.keys(connectManageItem).map((key, index) => {
            return (
              <S.ConnectManageContentItem
                key={`${key} ${index}`}
                align={'middle'}
                justify={'space-between'}
                onClick={() =>
                  handleClickConnectManageItem(connectManageItem[key].id)
                }
              >
                <S.ConnectManageContentItemTitle>
                  {t(connectManageItem[key].title)}
                </S.ConnectManageContentItemTitle>
                <RightOutlined rev={undefined} />
              </S.ConnectManageContentItem>
            )
          })}
        </S.ConnectManageContent>
      </S.ConnectManageWrapper>
    </S.RootWrapper>
  )
}

export default memo(YourInformationLayout)
