import RestaurantLayout from './Restaurant'
import AppearanceLayout from './Appearance'
import BackupLayout from './Backup'
import KdsSettingLayout from './KdsSetting'
import ProductManagementLayout from './ProductManagement'
import SyncDataLayout from './SyncData'
import YourInformationLayout from './YourInformation'
import DeviceDetailLayout from './DeviceDetail'
import PrinterConfigLayout from './PrinterConfig'
import YourKioskLayout from './YourKiosk'
import RestaurantDetailLayout from './RestaurantDetail'
import EditYourKioskLayout from './EditYourKiosk'
import AddYourKioskLayout from './AddYourKiosk'
import ProductDetail from './ProductDetail'
import ProductSelectUpSell from './ProductSelectUpSell'

export {
  RestaurantLayout,
  AppearanceLayout,
  BackupLayout,
  KdsSettingLayout,
  ProductManagementLayout,
  YourKioskLayout,
  YourInformationLayout,
  DeviceDetailLayout,
  PrinterConfigLayout,
  SyncDataLayout,
  RestaurantDetailLayout,
  EditYourKioskLayout,
  AddYourKioskLayout,
  ProductDetail,
  ProductSelectUpSell,
}
