import { ArrowLeftOutlined, DownOutlined } from '@ant-design/icons'
import { MenuProps, TabsProps } from 'antd'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseSpace } from 'app/components/common/BaseSpace'
import * as SR from 'app/containers/admin/Settings/components/RightSide/styles'
import { URL_SETTING } from 'app/containers/admin/Settings/constant'
import { useAppDispatch } from 'app/redux/hooks'
import { setPathSetting } from 'app/redux/slices/pathSettingSlice'
import { memo, useEffect, useMemo, useState } from 'react'
import * as S from './styles'
import TabPrinterLayout from './tab-components/TabPrinter'
import TabProfileLayout from './tab-components/TabProfile'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

// fake, the real value will be taken from the database
const printersData = [
  'Printer name',
  'Printer name',
  'Printer name',
  'Printer name',
  'Printer name',
  'Printer name',
  'Printer name',
  'Printer name',
  'Printer name',
  'Printer name',
]

const PrinterConfigLayout = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const [searchPrinters, setSearchPrinters] = useState('')
  const [searchProfile, setSearchProfile] = useState('')
  const [deviceName, setDeviceName] = useState('')

  // const printersData = useAppSelector(
  //   state => state.pathSetting,
  // )?.printersData


  // useEffect(() => {
  //   if (!printersData) {
  //     dispatch(setPathSetting(URL_SETTING.Information))
  //   }
  // }, [])

  useEffect(() => {
    console.log(searchPrinters)
  }, [searchPrinters])

  useEffect(() => {
    console.log(searchProfile)
  }, [searchProfile])

  useEffect(() => {
    console.log(deviceName)
  }, [deviceName])

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    console.log('handleMenuClick ran', e);
  }

  const dropdownMenuItems: MenuProps['items'] = useMemo(() => ([
    {
      label: '1st menu item',
      key: '1',
      icon: <DownOutlined rev={null} />,
    },
    {
      label: '2nd menu item',
      key: '2',
      icon: <DownOutlined rev={null} />,
    },
    {
      label: '3rd menu item',
      key: '3',
      icon: <DownOutlined rev={null} />,
    },
  ]), [i18n.language])

  const menuProps = {
    items: dropdownMenuItems,
    onClick: handleMenuClick,
  }

  const onTabChange = (key: string) => {
    console.log(key);
  }

  const connectPrinter = () => {
    // do something
    console.log('connectPrinter ran')
  }

  const troubleshoot = () => {
    // do something
    console.log('troubleshoot ran')
  }

  const tabItems: TabsProps['items'] = useMemo(() => ([
    {
      key: '1',
      label: t(R.strings.your_infomation_printers),
      children: <TabPrinterLayout
        printersData={printersData}
        searchPrinters={searchPrinters}
        onChangeSearchPrinters={setSearchPrinters}
        onConnectPrinter={connectPrinter}
        onTroubleshoot={troubleshoot}
      />,
    },
    {
      key: '2',
      label: t(R.strings.your_infomation_profiles),
      children: <TabProfileLayout
        searchProfile={searchProfile}
        onChangeSearchProfile={setSearchProfile}
        deviceName={deviceName}
        onChangeDevicename={setDeviceName}
        onTroubleshoot={troubleshoot}
      />,
    },
  ]), [i18n.language])

  const goBack = () => {
    return dispatch(setPathSetting(URL_SETTING.Information))
  }

  return (
    <S.RootWrapper>
      <S.HeaderWrapper justify={'space-between'} align={'middle'}>
        <S.BackButton onClick={goBack}>
          <ArrowLeftOutlined rev={undefined} />
        </S.BackButton>
        <SR.TitleSide>{t(R.strings.your_infomation_printers)}</SR.TitleSide>
        <BaseCol>
          <S.HeaderDropDown
            menu={menuProps}
            trigger={['click']}
            placement="bottomRight"
          >
            <BaseButton>
              <BaseSpace>
                {t(R.strings.your_infomation_get_help)}
                <DownOutlined rev={null} />
              </BaseSpace>
            </BaseButton>
          </S.HeaderDropDown>
        </BaseCol>
      </S.HeaderWrapper>

      <S.PrintersDescription>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Blanditiis vitae fugiat odio architecto veniam! Quae architecto dolores ipsa nostrum ea quaerat odio doloribus quia porro tempore iste nulla, esse voluptates.
      </S.PrintersDescription>

      <S.SplitDivider />

      <S.Tabs
        defaultActiveKey="1"
        items={tabItems}
        onChange={onTabChange}
      />

      {/* <S.SplitDivider />

      <S.TroubleshootMessage>
        {'Expecting a printer to be connected? '}
        <S.TroubleshootAction onClick={troubleshoot}>
          {'Troubleshoot'}
        </S.TroubleshootAction>
      </S.TroubleshootMessage> */}
    </S.RootWrapper>
  )
}

export default memo(PrinterConfigLayout)
