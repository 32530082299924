import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BORDER_RADIUS_2 } from 'app/constant'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'
export const ItemCategoryWrapper = styled(BaseSpace)`
  border-radius: ${BORDER_RADIUS};
  border: 1px solid #393c49;
  position: relative;
  justify-content: space-between;
  width: 100%;
  max-width: 200px;
`

export const ItemCategoryContentWrapper = styled(BaseSpace)`
  padding: ${PADDING.xl};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

export const TextNameItemCategory = styled(BaseCol)`
  color: var(--background-color);
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: ${FONT_SIZE.xs};
  text-align: center;
`

export const ImageItemCategory = styled(BaseImage)`
  border-radius: 60px;
`

export const ButtonViewMore = styled(BaseButton)`
  padding: ${PADDING.xl} ${PADDING.md};
  width: 100%;
  background-color: rgba(41, 204, 106, 0.24);
  border: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  svg {
    fill: #29cc6a;
  }
`

export const TextButtonViewMore = styled(BaseCol)`
  color: #29cc6a;
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: ${FONT_SIZE.xxs};
`

export const NumberActiveWrapper = styled.div<{ isActive?: boolean }>`
  position: absolute;
  top: 10px;
  left: 10px;
  padding: ${PADDING.xxs} ${PADDING.xxxs};
  background-color: ${props =>
    props.isActive ? '#29cc6a' : 'rgba(255, 255, 255, 0.1)'};
  border-radius: ${BORDER_RADIUS_2};
  color: var(--background-color);
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: ${FONT_SIZE.md};
`
