/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IItemProductManagement,
  IResponseQueryList,
  IUpSellProductManagement,
} from 'app/api/product-management/model'
import { Loading } from 'app/components/common/Loading'
import { Option } from 'app/components/common/selects/BaseSelect'
import { usePagination } from 'app/hook'
import { useGetItemList } from 'app/react-query/hook/product-management'
import { useMemo } from 'react'
import BaseSelectInUpSell from '../BaseSelect'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

interface IProps {
  onChange?: (value: IItemProductManagement) => void
  value?: IItemProductManagement
  defaultValue?: string
  keyAllValue?: string
  itemParent?: IItemProductManagement
  placeholder?: string
  title?: string
  listUpSellExist?: IUpSellProductManagement[]
}

export const KEY_ALL = R.strings.key_all_label

function SelectionItem({
  onChange,
  value,
  defaultValue,
  keyAllValue = '',
  itemParent,
  placeholder,
  title,
  listUpSellExist = [],
}: IProps) {
  const { t, i18n } = useTranslation()
  const { data, isLoading: loading } = useGetItemList({ page_size: 1000 })
  const { flattenDataList } = usePagination()

  const dataConvert = useMemo(() => {
    const itemProductManagement: IResponseQueryList<IItemProductManagement[]> =
      flattenDataList(data)

    let listData = itemProductManagement?.data

    if (itemParent) {
      listData = listData?.filter(item => item?.item_id !== itemParent?.item_id)
    }
    console.log({ listUpSellExist })

    if (listUpSellExist?.length) {
      listData = listData?.filter(item => {
        console.log({ item })

        return !listUpSellExist?.find(
          exist => exist?.item_id?.[0]?.id === item?.item_id,
        )
      })
    }

    console.log({ listData })

    const list: IItemProductManagement[] = [
      // {
      //   item_id: keyAllValue,
      //   name: t(KEY_ALL),
      // },
    ]
    return list.concat(listData ?? [])
  }, [data, keyAllValue, itemParent, i18n.language, listUpSellExist])

  const onChangeSelect = (value: any) => {
    const itemItemProductManagement = dataConvert?.find(
      item => item?.item_id === value,
    )

    if (!itemItemProductManagement) return
    onChange && onChange(itemItemProductManagement)
  }

  return (
    <div>
      <BaseSelectInUpSell
        placeholder={placeholder ?? t(R.strings.form_item_select_item_p)}
        onChange={onChangeSelect}
        value={value?.item_id}
        defaultValue={defaultValue}
        title={title ?? t(R.strings.form_item_select_item_t)}
      >
        {dataConvert?.map((item, index) => {
          return (
            <Option key={index} value={item?.item_id}>
              {item.name}
            </Option>
          )
        })}
      </BaseSelectInUpSell>
      {loading && <Loading />}
    </div>
  )
}

export default SelectionItem
