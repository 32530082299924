import { BaseButton } from 'app/components/common/BaseButton'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseRow } from 'app/components/common/BaseRow'
import { SearchInput } from 'app/components/common/inputs/SearchInput'
import { BORDER_RADIUS, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

export const SearchTabPaneWrapper = styled(BaseRow)``

export const SearchTabPaneInput = styled(SearchInput)`
  width: 190px;
  .ant-input-group-addon {
    display: none;
  }
  .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: ${BORDER_RADIUS};
    border: 0;
    background: #393C49 !important;
    color: var(--background-color);
    border: unset;
  }
  input {
    height: 42px;
    background: #393C49 !important;
    color: var(--background-color);
  }
`

export const TabPaneMainButton = styled(BaseButton)`
  height: 44px;
  margin: 8px 0;
  background-color: var(--admin-text-focus-setting-menu-color);
  color: var(--background-color);
  font-weight: ${FONT_WEIGHT.medium};
  border-color: transparent;
  transition: all 1s ease;

  &:hover {
    border-color: var(--background-color) !important;
    color: var(--background-color) !important;
    transition: all 1s ease;
  }
`

export const NoData = styled.div`
  width: 100%;
  margin: 16px;
`

export const SplitDivider = styled(BaseDivider)`
  margin: 8px 0;
  background-color: #475569;
`

export const TroubleshootMessage = styled.div`
  width: 100%;
  color: #475569;
`

export const TroubleshootAction = styled.span`
  cursor: pointer;
  color: #29CC6A;
`