import {
  IDishOrdered,
  IFilterReport,
  IMostOrderedReport,
  TypeDateEnum,
} from 'app/api/report/type'
import { BaseChart } from 'app/components/common/charts/BaseChart'
import { usePagination } from 'app/hook'
import { useGetMostOrderedReport } from 'app/react-query/hook/report'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CHART_SERIES_COLORS } from '../../constant'
import ChartHeaderLayout from '../ChartHeader'
import ChartLegendLayout from '../ChartLegend'
import * as S from './styles'

interface Series {}

function getUniqueDishNames(dishOrders: IDishOrdered[]): string[] {
  const uniqueNames: Set<string> = new Set()
  dishOrders?.forEach(dish => {
    if (dish.name) {
      uniqueNames.add(dish.name)
    }
  })
  return Array.from(uniqueNames)
}

const TotalDishOrderedLayout = () => {
  const [params, setParams] = useState<IFilterReport>({
    type_date: TypeDateEnum.daily,
  })

  const { i18n } = useTranslation()
  const [selectedLegend, setSelectedLegend] = useState<string[]>([])

  const onChangeChartLegend = (value: string[]) => {
    setSelectedLegend(value)
  }

  const { data: dataReport } = useGetMostOrderedReport(params)

  const { flattenDataList } = usePagination()

  const chartData = useMemo(() => {
    const data: IMostOrderedReport[] = flattenDataList(dataReport)

    const listDishOrder = _.flatten(data.map(item => item?.dish_ordered ?? []))

    const dataNameFood = getUniqueDishNames(listDishOrder)

    const listDate = data?.map(itm => itm?.date)

    const dataValues = dataNameFood?.map(item => {
      let list: number[] = []
      data?.forEach(itm => {
        const itmExist = itm?.dish_ordered?.find(od => od?.name === item)
        if (itmExist) {
          list = list.concat([itmExist?.current?.amount ?? 0])
        }
      })

      return list
    })
    return { dataNameFood, listDate, dataValues }
  }, [dataReport])

  useEffect(() => {
    setSelectedLegend(chartData?.dataNameFood)
  }, [chartData?.dataNameFood])

  const xAxisLabel = useMemo(() => {
    switch (params?.type_date) {
      case TypeDateEnum.daily:
        return chartData?.listDate?.map(item => {
          const hour = item ? +item : 0
          return hour > 9
            ? `${hour}:00 - ${hour}:59`
            : `0${hour}:00 - 0${hour}:59`
        })

      case TypeDateEnum.weekly:
        return chartData?.listDate?.map(item => `${item}`)
      case TypeDateEnum.monthly:
        return chartData?.listDate?.map(item => `${item}`)
      case TypeDateEnum.yearly:
        return chartData?.listDate?.map(item => `Month ${item}`)
    }
  }, [chartData, params])

  const option = useMemo(() => {
    const series: Series[] = []

    const { dataNameFood, dataValues } = chartData

    for (let i = 0; i < dataValues.length; i++) {
      const newData = dataValues[i]
      const newName = dataNameFood[i]

      const newSeries: Series = {
        data: newData,
        name: newName,
        type: 'line',
        smooth: true,
        stack: 'Total',
        symbol: () =>
          'image://data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAMFJREFUKFOFkKEOglAUhv+fq1AdnUk1KsHgI9jcMPMARiMbZIvd4APcwFMYCCRf4M5oILrBplcHuxicjL+c85//OyccwihNUyuO440QIjKjM8msy9k0SqmJ7/tXAKILTH0BmJF8sLmUJIkCMPqBOvskOWVVVWvHcU49UDeOqLWWJFcD4KUBM5LLATBnXddb27aPA+COUkoRhuENQPuBP3oD8NqwLEvPdd28B1yQvH+vFEUxngfB3gJCsyABHEjqxn8ATJAx6W/eoJ8AAAAASUVORK5CYII=',
        symbolSize: 10,
        itemStyle: {
          color: CHART_SERIES_COLORS[i],
        },
      }

      series.push(newSeries)
    }

    // generate selected legend
    const selectedLegendData: { [key: string]: boolean } = {}
    for (const item of dataNameFood) {
      selectedLegendData[item] = selectedLegend?.includes(item) || false
    }

    return {
      backgroundColor: 'transparent',
      tooltip: {
        trigger: 'item',
        position: 'top',
        formatter: params => {
          console.log('params is', params)
          return (
            `<span style="color: #FF747C;">-</span>$1.209` +
            '&nbsp;' +
            `<span style="color: #87C754;">+</span>9.488`
          )
        },
        extraCssText:
          'color: #707070; font-size: 18px; font-weight: 400; border: unset; border-radius:13px; padding: 16px;',
      },
      legend: {
        show: false,
        selected: { ...selectedLegendData },
      },
      grid: {
        top: 20,
        left: 0,
        right: 40,
        bottom: 0,
        containLabel: true,
      },
      xAxis: {
        data: xAxisLabel,
        type: 'category',
        boundaryGap: false,
        axisLine: {
          lineStyle: {
            color: '#D5D9E0',
          },
        },
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: true,
          lineStyle: {
            color: '#FFFFFF',
          },
        },
        splitLine: {
          lineStyle: {
            color: '#FFFFFF',
          },
        },
      },
      series: [...series],
    }
  }, [chartData, selectedLegend, i18n.language, xAxisLabel])

  const onChange = (type_date: TypeDateEnum) => {
    setParams({
      type_date,
    })
  }

  return (
    <S.RootWrapper>
      <ChartHeaderLayout
        hideFilter
        params={params.type_date}
        onChange={onChange}
      />
      <BaseChart
        option={{ ...option }}
        theme={'dark'}
        width={'100%'}
        height={500}
      />
      {chartData?.dataValues.length > 0 && (
        <ChartLegendLayout
          serieNames={chartData.dataNameFood ?? []}
          onChange={onChangeChartLegend}
        />
      )}
    </S.RootWrapper>
  )
}

export default TotalDishOrderedLayout
