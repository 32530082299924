import { BaseCol } from 'app/components/common/BaseCol'
import { isEqual } from 'lodash'
import { useMemo } from 'react'

import * as S from './styles'
import { BaseRow } from 'app/components/common/BaseRow'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { setPathSetting } from 'app/redux/slices/pathSettingSlice'
import { useTranslation } from 'react-i18next'

export interface IMenuSetting {
  url: string
  title?: string
  description?: string
  icon?: string
  iconFocus?: string
}

export const ItemMenuCpn = ({
  item,
  type = 'link',
  onPress,
}: {
  item: IMenuSetting
  type?: 'link' | 'action'
  onPress?: () => void
}) => {
  const currentPath = useAppSelector(state => state?.pathSetting)?.path
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const isSelected = useMemo(() => {
    const pathSlice = currentPath
      .split('/')
      .filter(component => component !== '')

    const path = pathSlice?.find(itm => isEqual(itm, item?.url))
    // console.log({ pathSlice, item })

    if (path) {
      return true
    }
    return isEqual(currentPath, item?.url)
  }, [currentPath, item?.url])

  const onClickItem = () => {
    switch (type) {
      case 'link':
        dispatch(setPathSetting(item?.url))
        break
      case 'action':
        onPress?.()
        break
      default:
        dispatch(setPathSetting(item?.url))
        break
    }
  }

  return (
    <S.RootWrapper
      wrap={false}
      onClick={onClickItem}
      align={'middle'}
      justify={'space-between'}
      $isSelected={isSelected}
    >
      <BaseCol xl={20}>
        <BaseRow
          gutter={[10, 10]}
          justify={'start'}
          align={'middle'}
          wrap={false}
          style={{ paddingTop: 3, paddingBottom: 3 }}
        >
          <BaseCol>
            <S.Image
              src={isSelected ? item?.iconFocus : item?.icon}
              width={14}
              // className={'text-opacity-up-to'}
            />
          </BaseCol>
          <BaseCol>
            <S.Title
              $isSelected={isSelected}
              // className={'text-opacity-right-to'}
            >
              {t(item?.title ?? '-')}
            </S.Title>
            <S.Space />
            <S.Description

            // className={'text-opacity-left-to'}
            >
              {t(item?.description ?? '-')}
            </S.Description>
          </BaseCol>
        </BaseRow>
      </BaseCol>
      {isSelected ? (
        <S.RightLine
        // className={'text-opacity-up-to'}
        />
      ) : (
        <BaseCol />
      )}
    </S.RootWrapper>
  )
}
