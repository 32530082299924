import { memo } from 'react'
import * as S from './styles'
import * as SR from 'app/containers/admin/Settings/components/RightSide/styles'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

const RestaurantLayout = () => {
  const { t } = useTranslation()
  return (
    <S.RootWrapper>
      <SR.TitleSide>{t(R.strings.restaurant)}</SR.TitleSide>
    </S.RootWrapper>
  )
}

export default memo(RestaurantLayout)
