import R from 'app/assets/R'
import { Image, Text } from 'app/components/styles'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import { useAppDispatch } from 'app/redux/hooks'
import { setPathReport } from 'app/redux/slices/pathReportSlice'
import { URL_REPORTS } from '../../constant'

interface CardData {
  icon: string
  title: string
  path: string
}

const CARD_DATAS: CardData[] = [
  {
    icon: 'https://storage.googleapis.com/kiosify-01/images/img_total_revenue.png',
    title: R.strings.reports_common_total_revenue,
    path: URL_REPORTS.TotalRevenue,
  },
  {
    icon: 'https://storage.googleapis.com/kiosify-01/images/img_total_dish_ordered.png',
    title: R.strings.reports_common_total_dish_ordered,
    path: URL_REPORTS.TotalDishOrdered,
  },
  {
    icon: 'https://storage.googleapis.com/kiosify-01/images/img_total_customer.png',
    title: R.strings.reports_common_total_customer,
    path: URL_REPORTS.TotalCustomer,
  },
  {
    icon: 'https://storage.googleapis.com/kiosify-01/images/img_order_report.png',
    title: R.strings.reports_common_order_report,
    path: URL_REPORTS.OrderReport,
  },
]

const HomeLayout = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const handleNavigate = (path: string) => {
    // do something
    console.log('handleNavigate ran, data is', path)

    dispatch(setPathReport(path))
  }

  return (
    <S.RootWrapper gutter={[28, 40]}>
      {CARD_DATAS.map((item, index) => {
        return (
          <S.CardItemWrapper key={index}>
            <S.CardItem onClick={() => handleNavigate(item.path)}>
              <Image src={item.icon} $width="139px" $height="139px" />
              <Text $fontSize="24px" $fontWeight="600">
                {t(item.title)}
              </Text>
            </S.CardItem>
          </S.CardItemWrapper>
        )
      })}
    </S.RootWrapper>
  )
}

export default HomeLayout
