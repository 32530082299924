import { ArrowLeftOutlined } from '@ant-design/icons'
import * as S from './styles'

interface IProps {
  goBack?: () => void
}

const BaseBackButton = ({ goBack }: IProps) => {
  return (
    <S.BackButton onClick={goBack}>
      <ArrowLeftOutlined rev={undefined} />
    </S.BackButton>
  )
}

export default BaseBackButton
