// import { ArrowLeftOutlined } from '@ant-design/icons'
// import { BaseCol } from 'app/components/common/BaseCol'
// import { BaseRow } from 'app/components/common/BaseRow'
// import { BaseSpace } from 'app/components/common/BaseSpace'
// import { BaseForm } from 'app/components/common/forms/BaseForm'
// import * as SR from 'app/containers/admin/Settings/components/RightSide/styles'
// import { URL_SETTING } from 'app/containers/admin/Settings/constant'
// import { useAppDispatch } from 'app/redux/hooks'
// import { setPathSetting } from 'app/redux/slices/pathSettingSlice'
// import { memo } from 'react'
// import * as S from './styles'
// import { randomMoney } from 'app/components/tables/BaseTableReport/constant'

// interface IFormKiosk {
//   name: string
//   location: string
//   kioskCode: string
//   subscription?: string
//   paymentMethod?: string[]
//   redeemLoyalty?: boolean
//   redeemDiscount?: boolean
// }

// const initData: IFormKiosk = {
//   name: 'Banh Mi Zon Vietnamese Gourmet',
//   location: '76 San Pablo Ave',
//   kioskCode: `${randomMoney()}`,
//   subscription: `$39.95/mo
//   $0.10 per  transaction`,
//   paymentMethod: [],
//   redeemDiscount: false,
//   redeemLoyalty: false,
// }

// const ItemPaymentMethod = ({
//   isSelected,
//   label,
// }: {
//   isSelected?: boolean
//   label?: string
// }) => {
//   return (
//     <BaseCol>
//       <S.ItemPaymentMethod isSelected={isSelected}>
//         <BaseRow gutter={[10, 10]}>
//           {isSelected && (
//             <BaseCol>
//               <S.IconChecked rev={undefined} />
//             </BaseCol>
//           )}
//           <BaseCol>{label}</BaseCol>
//         </BaseRow>
//       </S.ItemPaymentMethod>
//     </BaseCol>
//   )
// }

// const EditYourKioskLayout = () => {
//   const [form] = BaseForm.useForm<IFormKiosk>()
//   const dispatch = useAppDispatch()
//   const goBack = () => {
//     return dispatch(setPathSetting(URL_SETTING.Restaurant))
//   }

//   return (
//     <S.RootWrapper>
//       <S.HeaderWrapper justify={'space-between'} align={'middle'}>
//         <S.BackButton onClick={goBack}>
//           <ArrowLeftOutlined rev={undefined} />
//         </S.BackButton>
//         <SR.TitleSide>{'Edit Your Kiosk'}</SR.TitleSide>
//         <BaseCol>
//           <S.ButtonSeeYourRestaurant>{'Save'}</S.ButtonSeeYourRestaurant>
//         </BaseCol>
//       </S.HeaderWrapper>

//       <S.FormDataWrapper form={form} initialValues={initData}>
//         <BaseForm.Item name="name" label={'Kiosk Name'}>
//           <S.InputCustom />
//         </BaseForm.Item>
//         <BaseRow justify={'space-between'} align={'middle'} gutter={[10, 10]}>
//           <BaseCol xl={12}>
//             <BaseForm.Item name="location" label={'Location'}>
//               <S.InputCustom />
//             </BaseForm.Item>
//           </BaseCol>
//           <BaseCol xl={12}>
//             <BaseForm.Item name="kioskCode" label={'Kiosk Code'}>
//               <S.InputCustom />
//             </BaseForm.Item>
//           </BaseCol>
//         </BaseRow>
//         <BaseForm.Item name="subscription" label={'Your Subscription'}>
//           <S.InputCustom />
//         </BaseForm.Item>
//         <BaseSpace direction="vertical" size={10}>
//           <S.LabelInput>{'Payment method'}</S.LabelInput>
//           <BaseRow gutter={[20, 20]} align={'middle'}>
//             <ItemPaymentMethod isSelected label="Cash" />
//             <ItemPaymentMethod isSelected label="Credit card" />
//             <ItemPaymentMethod label="Gift card" />
//           </BaseRow>
//           <BaseCol />
//           <BaseRow gutter={[30, 30]}>
//             <BaseCol>
//               <S.SwitchWrapper gutter={[10, 10]}>
//                 <BaseCol>
//                   <S.SwitchButton />
//                 </BaseCol>
//                 <S.LabelInput>{'Reedem Loyalty'}</S.LabelInput>
//               </S.SwitchWrapper>
//             </BaseCol>
//             <BaseCol>
//               <S.SwitchWrapper gutter={[10, 10]} align={'middle'}>
//                 <BaseCol>
//                   <S.SwitchButton />
//                 </BaseCol>
//                 <S.LabelInput>{'Reedem Discount'}</S.LabelInput>
//               </S.SwitchWrapper>
//             </BaseCol>
//           </BaseRow>
//         </BaseSpace>
//       </S.FormDataWrapper>
//     </S.RootWrapper>
//   )
// }

// export default memo(EditYourKioskLayout)
import { ArrowLeftOutlined } from '@ant-design/icons'
import { requestUpdateLanguageKiosk } from 'app/api/kiosk/api'
import { IUpdateKiosk } from 'app/api/kiosk/model'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { Loading } from 'app/components/common/Loading'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { LanguagePicker } from 'app/components/header/admin/components/SettingsDropdown/settings-overlay/LanguagePicker'
import * as SR from 'app/containers/admin/Settings/components/RightSide/styles'
import { URL_SETTING } from 'app/containers/admin/Settings/constant'
import { subscriptionPlans } from 'app/containers/landing-page/components/desktop-layout/LayoutThree'
import { notificationController } from 'app/controllers/notification-controller'
import { LanguageSupportType } from 'app/i18n/constant'
import { useUpdateKiosksByCode } from 'app/react-query/hook/kiosk'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { setPathSetting } from 'app/redux/slices/pathSettingSlice'
import { getServicePackage } from 'app/service/storage/storage-service'
import { isEmpty, isEqual } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SelectionRestaurant from '../../components/SelectionLocation'
import * as S from './styles'

interface IFormKiosk {
  name: string
}

const initData: IFormKiosk = {
  name: '',
}

export enum PaymentMethodEnum {
  cash = 'cash',
  creditCard = 'credit-card',
  giftCard = 'gift-card',
}

interface IPropsItemPaymentMethod {
  isSelected?: boolean
  keyItem: PaymentMethodEnum
  onChange?: (value: PaymentMethodEnum) => void
}

const listPaymentMethod = [
  PaymentMethodEnum.cash,
  PaymentMethodEnum.creditCard,
  PaymentMethodEnum.giftCard,
]

export const ItemPaymentMethod = ({
  isSelected,
  keyItem,
  onChange,
}: IPropsItemPaymentMethod) => {
  const { t, i18n } = useTranslation()

  const defineValue = useMemo(
    () => ({
      [PaymentMethodEnum.cash]: t(R.strings.payment_method_cash),
      [PaymentMethodEnum.creditCard]: t(R.strings.payment_method_credit_card),
      [PaymentMethodEnum.giftCard]: t(R.strings.payment_method_gift_card),
    }),
    [i18n.language],
  )

  const onChangePaymentMethod = () => {
    if (onChange) {
      onChange?.(keyItem)
    }
  }
  return (
    <BaseCol>
      <S.ItemPaymentMethod
        isSelected={isSelected}
        onClick={onChangePaymentMethod}
      >
        <BaseRow gutter={[10, 10]}>
          {isSelected && (
            <BaseCol>
              <S.IconChecked rev={undefined} />
            </BaseCol>
          )}
          <BaseCol>{defineValue[keyItem]}</BaseCol>
        </BaseRow>
      </S.ItemPaymentMethod>
    </BaseCol>
  )
}

const EditYourKioskLayout = () => {
  const { t, i18n } = useTranslation()
  const [form] = BaseForm.useForm<IFormKiosk>()

  const kioskUpdateItem = useAppSelector(
    state => state.pathSetting,
  ).kioskUpdateItem

  const dispatch = useAppDispatch()

  const goBack = () => {
    dispatch(setPathSetting(URL_SETTING.Kiosks))
  }

  if (!kioskUpdateItem) {
    goBack()
    return null
  }

  const packageData = getServicePackage()
  const [location, setLocation] = useState<string | undefined>(
    kioskUpdateItem?.location?.id,
  )

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodEnum[]>([])
  const [redeemLoyalty, setRedeemLoyalty] = useState<boolean>(false)
  const [redeemDiscount, setRedeemDiscount] = useState<boolean>(true)
  const [language, setLanguage] = useState<LanguageSupportType>(
    kioskUpdateItem?.language ?? 'en',
  )

  const servicePackage = useMemo(() => {
    const packageExist = subscriptionPlans().find(
      item => item?.package === packageData,
    )

    if (packageExist) {
      return packageExist
    }

    return subscriptionPlans()[0]
  }, [packageData, subscriptionPlans, i18n.language])

  useEffect(() => {
    if (kioskUpdateItem) {
      setLocation(kioskUpdateItem?.location?.id)

      const paymentMode: PaymentMethodEnum[] =
        kioskUpdateItem?.payment_mode ?? []

      const listCard: PaymentMethodEnum[] = []

      paymentMode?.map(item => {
        const listKey = Object.keys(PaymentMethodEnum)
        const index = listKey.findIndex(key => {
          return isEqual(PaymentMethodEnum[key], item)
        })
        const itemInListCard = listCard?.find(key =>
          isEqual(PaymentMethodEnum[key], item),
        )
        if (index !== -1 && !itemInListCard) {
          listCard.push(item)
        }
      })
      setPaymentMethod(listCard)
      setRedeemLoyalty(kioskUpdateItem?.reedem_loyalty ?? false)
      setRedeemDiscount(kioskUpdateItem?.reedem_discount ?? true)
      if (!isEmpty(kioskUpdateItem?.language)) {
        setLanguage(
          kioskUpdateItem?.language?.toLowerCase() as unknown as LanguageSupportType,
        )
      }

      form.setFieldsValue({
        name: kioskUpdateItem?.name,
      })
    }
  }, [kioskUpdateItem, form])

  const { mutateAsync: mutateAsyncUpdateKiosk, isLoading: loadingUpdate } =
    useUpdateKiosksByCode()

  const onSubmit = async (value: IFormKiosk) => {
    if (!servicePackage) {
      notificationController.error({
        message: t(R.strings.error_message_service_package),
      })

      return
    }

    if (!location) {
      notificationController.error({
        message: t(R.strings.error_message_select_location),
      })

      return
    }

    if (paymentMethod.length === 0) {
      notificationController.error({
        message: t(R.strings.error_message_select_payment_method),
      })

      return
    }

    if (!kioskUpdateItem || !kioskUpdateItem?.code) {
      notificationController.error({
        message: t(R.strings.error_message_select_kiosk),
      })
      goBack()
      return
    }

    const dataToSend: IUpdateKiosk = {
      name: value.name,
      type: 'kiosk',
      location: location,
      payment_mode: paymentMethod,
      pricing_tier: `${servicePackage.priceNumber}`,
      reedem_loyalty: redeemLoyalty,
      reedem_discount: redeemDiscount,
      code: kioskUpdateItem?.code,
      status: kioskUpdateItem?.status,
      language,
    }
    mutateAsyncUpdateKiosk(dataToSend)

    if (language && !isEqual(language, kioskUpdateItem?.language)) {
      await requestUpdateLanguageKiosk({
        code: kioskUpdateItem?.code,
        language,
      })
    }
  }

  const changePaymentMethod = (value: PaymentMethodEnum) => {
    const index = paymentMethod.findIndex(item => item === value)
    if (index !== -1) {
      const newPaymentMethod = paymentMethod.filter(item => item !== value)
      setPaymentMethod(newPaymentMethod)
    } else {
      setPaymentMethod([...paymentMethod, value])
    }
  }

  const onChangeLocation = (value: string) => {
    setLocation(value)
  }

  const onChangeRedeemLoyalty = () => {
    setRedeemLoyalty(!redeemLoyalty)
  }

  const onChangeRedeemDiscount = () => {
    setRedeemDiscount(!redeemDiscount)
  }

  const onChangeLanguageKiosk = (lang: LanguageSupportType) => {
    setLanguage(lang)
  }

  return (
    <S.RootWrapper>
      <S.HeaderWrapper justify={'space-between'} align={'middle'}>
        <S.BackButton onClick={goBack}>
          <ArrowLeftOutlined rev={undefined} />
        </S.BackButton>
        <SR.TitleSide>{t(R.strings.kiosk_edit_your_kiosk)}</SR.TitleSide>
        <BaseCol>
          <S.ButtonSeeYourRestaurant onClick={() => form.submit()}>
            {t(R.strings.save)}
          </S.ButtonSeeYourRestaurant>
        </BaseCol>
      </S.HeaderWrapper>

      <S.FormDataWrapper
        form={form}
        initialValues={initData}
        onFinish={onSubmit}
      >
        <BaseForm.Item
          name="name"
          label={t(R.strings.kiosk_kiosk_name)}
          rules={[
            {
              required: true,
              message: t(R.strings.input_validate_required_kiosk_name),
            },
          ]}
        >
          <S.InputCustom />
        </BaseForm.Item>

        <BaseRow justify={'space-between'} align={'middle'} gutter={[10, 10]}>
          <BaseCol xl={8}>
            <BaseForm.Item name="kioskCode" label={t(R.strings.restaurant)}>
              <SelectionRestaurant
                onChange={onChangeLocation}
                value={location}
                defaultValue={location}
              />
            </BaseForm.Item>
          </BaseCol>
          <BaseCol xl={8}>
            <BaseForm.Item
              name="kioskCode"
              label={t(R.strings.kiosk_kiosk_code)}
            >
              <S.SubWrapper direction="vertical" size={5}>
                <S.TextCodeTitle>{kioskUpdateItem.code}</S.TextCodeTitle>
              </S.SubWrapper>
            </BaseForm.Item>
          </BaseCol>
          <BaseCol xl={8}>
            <BaseForm.Item name="kioskCode" label={t(R.strings.language)}>
              <LanguagePicker
                onChange={onChangeLanguageKiosk}
                initValue={language}
              />
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>
        <BaseForm.Item
          name="subscription"
          label={t(R.strings.kiosk_your_subscription)}
        >
          <S.SubWrapper direction="vertical" size={5}>
            <S.TextSubTitle>{servicePackage.title}</S.TextSubTitle>
            <S.TextSubTitle>{servicePackage.price}</S.TextSubTitle>
          </S.SubWrapper>
        </BaseForm.Item>
        <BaseSpace direction="vertical" size={10}>
          <S.LabelInput>{t(R.strings.kiosk_payment_method)}</S.LabelInput>
          <BaseRow gutter={[20, 20]} align={'middle'}>
            {listPaymentMethod.map((keyItem, index) => {
              const isSelected =
                paymentMethod.findIndex(
                  keySelected => keySelected === keyItem,
                ) !== -1
              return (
                <ItemPaymentMethod
                  isSelected={isSelected}
                  key={index}
                  keyItem={keyItem}
                  onChange={changePaymentMethod}
                />
              )
            })}
          </BaseRow>
          <BaseCol />
          <BaseRow gutter={[30, 30]}>
            <BaseCol>
              <S.SwitchWrapper gutter={[10, 10]}>
                <BaseCol>
                  <S.SwitchButton
                    checked={redeemLoyalty}
                    onChange={onChangeRedeemLoyalty}
                  />
                </BaseCol>
                <S.LabelInput>{t(R.strings.kiosk_redeem_loyalty)}</S.LabelInput>
              </S.SwitchWrapper>
            </BaseCol>
            <BaseCol>
              <S.SwitchWrapper gutter={[10, 10]} align={'middle'}>
                <BaseCol>
                  <S.SwitchButton
                    checked={redeemDiscount}
                    onChange={onChangeRedeemDiscount}
                  />
                </BaseCol>
                <S.LabelInput>
                  {t(R.strings.kiosk_redeem_discount)}
                </S.LabelInput>
              </S.SwitchWrapper>
            </BaseCol>
          </BaseRow>
        </BaseSpace>
        {loadingUpdate && <Loading />}
      </S.FormDataWrapper>
    </S.RootWrapper>
  )
}

export default EditYourKioskLayout
