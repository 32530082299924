import { ArrowLeftOutlined } from "@ant-design/icons"
import { BackButton, SearchLayout, Text } from 'app/components/styles'
import { useAppDispatch } from "app/redux/hooks"
import { setPathReport } from "app/redux/slices/pathReportSlice"
import moment from "moment"
import { URL_REPORTS } from "../../constant"
import * as S from './styles'
import { useTranslation } from "react-i18next"
import R from "app/assets/R"

interface HeaderLayoutProps {
    title?: string;
    hideBackButton?: boolean;
}

const HeaderLayout = (props: HeaderLayoutProps) => {
    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation()
    const today = moment().locale(i18n.language)

    const goBack = () => {
        return dispatch(setPathReport(URL_REPORTS.Home))
    }

    return (
        <S.RootWrapper justify={'space-between'}>
            <S.LeftLayout align={'middle'}>
                <BackButton
                    onClick={goBack}
                    $hide={props.hideBackButton}
                >
                    <ArrowLeftOutlined rev={undefined} />
                </BackButton>
                <S.TitleWrapper>
                    <Text
                        $fontSize='24px'
                        $fontWeight='700'
                        $margin="0 0 4px 0"
                    >
                        {props.title}
                    </Text>
                    <Text
                        $fontSize='14px'
                        $fontWeight='400'
                        $color="#E0E6E9"
                        $capitalize
                    >
                        {today.format('dddd, D MMM YYYY')}
                    </Text>
                </S.TitleWrapper>
            </S.LeftLayout>
            <S.RightLayout align={'bottom'}>
                <SearchLayout placeholder={t(R.strings.input_placeholder_search)} />
            </S.RightLayout>
        </S.RootWrapper>
    )
}

export default HeaderLayout
