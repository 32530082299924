import { ArrowLeftOutlined } from '@ant-design/icons'
import { ICreateKiosk } from 'app/api/kiosk/model'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { Loading } from 'app/components/common/Loading'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { SpaceVertical } from 'app/components/styles'
import * as SR from 'app/containers/admin/Settings/components/RightSide/styles'
import { URL_SETTING } from 'app/containers/admin/Settings/constant'
import { subscriptionPlans } from 'app/containers/landing-page/components/desktop-layout/LayoutThree'
import { notificationController } from 'app/controllers/notification-controller'
import { useCreateKiosks } from 'app/react-query/hook/kiosk'
import { useAppDispatch } from 'app/redux/hooks'
import { setPathSetting } from 'app/redux/slices/pathSettingSlice'
import { getServicePackage } from 'app/service/storage/storage-service'
import { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SelectionRestaurant from '../../components/SelectionLocation'
import * as S from './styles'

interface IFormKiosk {
  name: string
}

const initData: IFormKiosk = {
  name: '',
}

enum PaymentMethodEnum {
  cash = 'cash',
  creditCard = 'credit-card',
  giftCard = 'gift-card',
}

interface IPropsItemPaymentMethod {
  isSelected?: boolean
  keyItem: PaymentMethodEnum
  onChange?: (value: PaymentMethodEnum) => void
}

const listPaymentMethod = [
  PaymentMethodEnum.cash,
  PaymentMethodEnum.creditCard,
  PaymentMethodEnum.giftCard,
]

export const ItemPaymentMethod = ({
  isSelected,
  keyItem,
  onChange,
}: IPropsItemPaymentMethod) => {
  const { t, i18n } = useTranslation()

  const defineValue = useMemo(
    () => ({
      [PaymentMethodEnum.cash]: t(R.strings.payment_method_cash),
      [PaymentMethodEnum.creditCard]: t(R.strings.payment_method_credit_card),
      [PaymentMethodEnum.giftCard]: t(R.strings.payment_method_gift_card),
    }),
    [i18n.language],
  )

  const onChangePaymentMethod = () => {
    if (onChange) {
      onChange?.(keyItem)
    }
  }

  return (
    <BaseCol>
      <S.ItemPaymentMethod
        isSelected={isSelected}
        onClick={onChangePaymentMethod}
      >
        <BaseRow gutter={[10, 10]}>
          {isSelected && (
            <BaseCol>
              <S.IconChecked rev={undefined} />
            </BaseCol>
          )}
          <BaseCol>{defineValue[keyItem]}</BaseCol>
        </BaseRow>
      </S.ItemPaymentMethod>
    </BaseCol>
  )
}

const AddYourKioskLayout = () => {
  const { t, i18n } = useTranslation()
  const [form] = BaseForm.useForm<IFormKiosk>()
  const dispatch = useAppDispatch()

  const packageData = getServicePackage()
  const [location, setLocation] = useState<string>('')
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodEnum[]>([])
  const [redeemLoyalty, setRedeemLoyalty] = useState<boolean>(false)
  const [redeemDiscount, setRedeemDiscount] = useState<boolean>(false)

  const goBack = () => {
    return dispatch(setPathSetting(URL_SETTING.Kiosks))
  }

  const servicePackage = useMemo(() => {
    const packageExist = subscriptionPlans().find(
      item => item?.package === packageData,
    )

    if (packageExist) {
      return packageExist
    }

    return subscriptionPlans()[0]
  }, [packageData, subscriptionPlans, i18n.language])

  const { mutateAsync: mutateAsyncCreateKiosk, isLoading: loadingCreate } =
    useCreateKiosks()

  const onSubmit = (value: IFormKiosk) => {
    if (!servicePackage) {
      notificationController.error({
        message: t(R.strings.error_message_service_package),
      })

      return
    }

    if (!location) {
      notificationController.error({
        message: t(R.strings.error_message_select_location),
      })

      return
    }

    if (paymentMethod?.length === 0) {
      notificationController.error({
        message: t(R.strings.error_message_select_payment_method),
      })

      return
    }

    const dataToSend: ICreateKiosk = {
      name: value.name,
      type: 'kiosk',
      location: location,
      payment_mode: paymentMethod,
      pricing_tier: `${servicePackage.priceNumber}`,
      reedem_loyalty: redeemLoyalty,
      reedem_discount: redeemDiscount,
    }
    mutateAsyncCreateKiosk(dataToSend)
  }

  const changePaymentMethod = (value: PaymentMethodEnum) => {
    const index = paymentMethod.findIndex(item => item === value)
    if (index !== -1) {
      const newPaymentMethod = paymentMethod.filter(item => item !== value)
      setPaymentMethod(newPaymentMethod)
    } else {
      setPaymentMethod([...paymentMethod, value])
    }
  }

  const onChangeLocation = (value: string) => {
    setLocation(value)
  }

  const onChangeRedeemLoyalty = () => {
    setRedeemLoyalty(!redeemLoyalty)
  }

  const onChangeRedeemDiscount = () => {
    setRedeemDiscount(!redeemDiscount)
  }

  return (
    <S.RootWrapper>
      <S.HeaderWrapper justify={'space-between'} align={'middle'}>
        <S.BackButton onClick={goBack}>
          <ArrowLeftOutlined rev={undefined} />
        </S.BackButton>
        <SR.TitleSide>{t(R.strings.kiosk_add_your_kiosk)}</SR.TitleSide>
        <BaseCol>
          <S.ButtonSeeYourRestaurant onClick={() => form.submit()}>
            {t(R.strings.save)}
          </S.ButtonSeeYourRestaurant>
        </BaseCol>
      </S.HeaderWrapper>

      <S.FormDataWrapper
        form={form}
        initialValues={initData}
        onFinish={onSubmit}
      >
        <BaseForm.Item
          name="name"
          label={t(R.strings.kiosk_kiosk_name)}
          rules={[
            {
              required: true,
              message: t(R.strings.input_validate_required_kiosk_name),
            },
          ]}
        >
          <S.InputCustom />
        </BaseForm.Item>

        <BaseForm.Item name="kioskCode" label={t(R.strings.restaurant)}>
          <SelectionRestaurant onChange={onChangeLocation} value={location} />
        </BaseForm.Item>
        <BaseForm.Item
          name="subscription"
          label={t(R.strings.kiosk_your_subscription)}
        >
          <S.SubWrapper direction="vertical" size={5}>
            <S.TextSubTitle>{servicePackage.title}</S.TextSubTitle>
            <S.TextSubTitle>{servicePackage.price}</S.TextSubTitle>
          </S.SubWrapper>
        </BaseForm.Item>
        <BaseSpace direction="vertical" size={10}>
          <S.LabelInput>{t(R.strings.kiosk_payment_method)}</S.LabelInput>
          <BaseRow gutter={[20, 20]} align={'middle'}>
            {listPaymentMethod.map((keyItem, index) => {
              const isSelected =
                paymentMethod.findIndex(
                  keySelected => keySelected === keyItem,
                ) !== -1
              return (
                <ItemPaymentMethod
                  isSelected={isSelected}
                  key={index}
                  keyItem={keyItem}
                  onChange={changePaymentMethod}
                />
              )
            })}
          </BaseRow>
          <BaseCol />
          <BaseRow gutter={[30, 30]}>
            <BaseCol>
              <S.SwitchWrapper gutter={[10, 10]}>
                <BaseCol>
                  <S.SwitchButton
                    checked={redeemLoyalty}
                    onChange={onChangeRedeemLoyalty}
                  />
                </BaseCol>
                <S.LabelInput>{t(R.strings.kiosk_redeem_loyalty)}</S.LabelInput>
              </S.SwitchWrapper>
            </BaseCol>
            <BaseCol>
              <S.SwitchWrapper gutter={[10, 10]} align={'middle'}>
                <BaseCol>
                  <S.SwitchButton
                    checked={redeemDiscount}
                    onChange={onChangeRedeemDiscount}
                  />
                </BaseCol>
                <S.LabelInput>
                  {t(R.strings.kiosk_redeem_discount)}
                </S.LabelInput>
              </S.SwitchWrapper>
            </BaseCol>
          </BaseRow>
        </BaseSpace>
        {loadingCreate && <Loading />}
      </S.FormDataWrapper>
      <SpaceVertical $gap={24} />
    </S.RootWrapper>
  )
}

export default memo(AddYourKioskLayout)
