/* eslint-disable @typescript-eslint/no-explicit-any */
import { IRestaurant } from 'app/api/restaurant/model'
import R from 'app/assets/R'
import { Loading } from 'app/components/common/Loading'
import { BaseButtonsForm } from 'app/components/common/forms/BaseButtonsForm'
import { BaseSelect, Option } from 'app/components/common/selects/BaseSelect'
import { LoadingWrapper } from 'app/components/styles'
import { usePagination } from 'app/hook'
import { useGetRestaurantList } from 'app/react-query/hook/restaurant'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IProps {
  onChange?: (value: string) => void
  onChangeItem?: (item: IRestaurant) => void
  value?: string
  defaultValue?: string
  keyAllValue?: string
  className?: string
  formItemName?: boolean
  allowSelectAll?: boolean
  selectFirstValue?: boolean
}

export const KEY_ALL = 'All'

function SelectionRestaurant({
  onChange,
  value,
  defaultValue,
  keyAllValue = '',
  className = '',
  formItemName = true,
  allowSelectAll = true,
  selectFirstValue,
  onChangeItem,
}: IProps) {
  const { data, isLoading: loading } = useGetRestaurantList()
  const { flattenData } = usePagination()
  const { t, i18n } = useTranslation()

  useEffect(() => {
    selectFirstValue &&
      dataConvert.length > 0 &&
      !value &&
      onChangeSelect(dataConvert[0].id)
  }, [data])

  const dataConvert = useMemo(() => {
    const list: IRestaurant[] = []

    allowSelectAll &&
      list.push({
        id: keyAllValue,
        name: t(R.strings.key_all_label),
      })

    return list.concat(flattenData(data))
  }, [data, keyAllValue, i18n.language])

  const onChangeSelect = (value: any) => {
    onChange && onChange(value)

    if (onChangeItem) {
      const item = dataConvert?.find(item => item?.id === value)
      if (item) {
        onChangeItem(item)
      }
    }
  }

  return (
    <LoadingWrapper>
      <BaseButtonsForm.Item
        name={formItemName ? 'select-multiple' : undefined}
        className={className}
      >
        <ChartHeaderSelectTime
          placeholder={t(R.strings.input_placeholder_select_location)}
          onChange={onChangeSelect}
          value={value}
          defaultValue={defaultValue}
        >
          {dataConvert?.map((item, index) => {
            return (
              <Option key={index} value={item?.id}>
                {item.name}
              </Option>
            )
          })}
        </ChartHeaderSelectTime>
      </BaseButtonsForm.Item>
      {loading && <Loading />}
    </LoadingWrapper>
  )
}

export default SelectionRestaurant

const ChartHeaderSelectTime = styled(BaseSelect)`
  min-width: 200px !important;
  height: 39px;
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #2d303e;
    color: var(--background-color);
    border: 0.7px solid rgba(45, 48, 62, 1);
  }

  svg {
    fill: var(--background-color) !important;
  }
`
