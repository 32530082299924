import {
  IDataReportOrder,
  IParamsListGetReportOrder,
} from 'app/api/dashboard/type'
import { IResponseQueryList } from 'app/api/product-management/model'
import R from 'app/assets/R'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import {
  RenderValueTableReport,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import { formatMoneyDollar, usePagination } from 'app/hook'
import { useGetReportOrder } from 'app/react-query/hook/dashboard'
import { BORDER_RADIUS, DateUtil, PADDING } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

enum StatusOrderEnum {
  Complete = 1,
  ForHere,
  Caution,
  ToGo,
}

export enum PaymentMethodEnum {
  Cash = 'Cash',
  CreditCard = 'CreditCard',
  GiftCard = 'GiftCard',
}

interface IDataTable {
  customer: string
  time: string
  order: string
  totalPayment: string
  paymentMethod: PaymentMethodEnum
  status: StatusOrderEnum
}

const renderStatus = (status: StatusOrderEnum) => {
  switch (status) {
    case StatusOrderEnum.Complete: {
      return 'Complete'
    }
    case StatusOrderEnum.Caution: {
      return 'Caution'
    }
    case StatusOrderEnum.ForHere: {
      return 'For here'
    }
    case StatusOrderEnum.ToGo: {
      return 'To go'
    }
  }
}

const convertStatusApiToStatusEnum = (status?: string) => {
  switch (status) {
    case 'For here': {
      return StatusOrderEnum.ForHere
    }
    default:
      return StatusOrderEnum.ForHere
  }
}

const TableOrderDashboard = ({
  params,
  onChangePage,
}: {
  params: IParamsListGetReportOrder
  onChangePage: (page: number, pageSize?: number) => void
}) => {
  const { t, i18n } = useTranslation()

  const { flattenDataList } = usePagination()

  const { data: dataReportOrder, isLoading } = useGetReportOrder(params)

  const reportOrder = useMemo(() => {
    const res: IResponseQueryList<IDataReportOrder[]> =
      flattenDataList(dataReportOrder)

    return (
      res ?? {
        data: [],
        total: 0,
      }
    )
  }, [dataReportOrder, params])

  const dataConvert = useMemo(() => {
    const res: IDataTable[] = reportOrder?.data?.map(item => {
      return {
        customer: item?.customer,
        order: item?.reference_id,
        paymentMethod: item?.payment_method,
        status: convertStatusApiToStatusEnum(item?.order_types),
        time: DateUtil.formatDDMMYYYHhss(item?.closed_at ?? new Date()),
        totalPayment: formatMoneyDollar(
          item?.total_payment ?? 0,
          item?.currency,
        ),
      } as IDataTable
    })
    return res
  }, [reportOrder])

  const column = useMemo(() => {
    return [
      convertColumnTable<IDataTable>({
        title: t(R.strings.table_customer),
        key: 'customer',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            valueString: record?.customer,
          }),
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.table_time),
        key: 'time',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            valueString: record?.time,
          }),
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.table_order),
        key: 'order',
        classNameOverwrite: 'medium-column',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            valueString: record?.order,
          }),
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.table_total_payment),
        key: 'totalPayment',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            valueString: record?.totalPayment ?? 0,
          }),
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.table_payment_method),
        key: 'paymentMethod',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            valueString: record?.paymentMethod,
          }),
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.table_status),
        key: 'status',
        render: (_, record) => {
          return (
            <StatusWrapper status={record?.status}>
              {renderStatus(record?.status)}
            </StatusWrapper>
          )
        },
      }),
    ]
  }, [i18n.language])
  return (
    <BaseTableReport
      columns={column}
      className="row-overwrite-style"
      dataSource={dataConvert}
      loading={isLoading}
      pagination={{
        current: params?.page,
        pageSize: params?.page_size,
        total: reportOrder.total ?? 0,
        showSizeChanger: false,
        position: ['bottomCenter'],
        onChange: onChangePage,
      }}
      scroll={{ x: true }}
    />
  )
}
export default TableOrderDashboard

const StatusWrapper = styled.div<{ status: StatusOrderEnum }>`
  color: ${props => {
    switch (props.status) {
      case StatusOrderEnum.Complete: {
        return '#29CC6A'
      }
      case StatusOrderEnum.Caution: {
        return '#FEF08A'
      }
      case StatusOrderEnum.ForHere: {
        return '#FC5555'
      }
      case StatusOrderEnum.ToGo: {
        return '#FFB572'
      }
    }
  }};
  background-color: ${props => {
    switch (props.status) {
      case StatusOrderEnum.Complete: {
        return 'rgba(41, 204, 106, 0.2)'
      }
      case StatusOrderEnum.Caution: {
        return 'rgba(254, 240, 138, 0.2)'
      }
      case StatusOrderEnum.ForHere: {
        return 'rgba(252, 85, 85, 0.2)'
      }
      case StatusOrderEnum.ToGo: {
        return 'rgba(255, 181, 114, 0.2)'
      }
    }
  }};
  padding: ${PADDING.xxxs};
  border-radius: ${BORDER_RADIUS};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
