import { BaseButton } from 'app/components/common/BaseButton'
import { BaseImage } from 'app/components/common/BaseImage'
import { PADDING } from 'parkway-web-common'
import { memo } from 'react'
import styled from 'styled-components'

const FilterButton = ({ onClick }: { onClick?: () => void }) => {
  return (
    <Button onClick={onClick}>
      <BaseImage
        src={'https://storage.googleapis.com/kiosify-01/images/ic_sort.png'}
        width={26}
        preview={false}
      />
    </Button>
  )
}

export default memo(FilterButton)

const Button = styled(BaseButton)`
  padding: ${PADDING.md} ${PADDING.xxxs} !important;
  background-color: transparent !important;
`
