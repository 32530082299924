import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseSpace)`
  // padding: ${PADDING.xxxxs};
  width: 100%;
`

export const HeaderWrapper = styled(BaseRow)``

export const BackButton = styled(BaseButton)`
  background-color: rgba(255, 255, 255, 0.1); // need change
  border-radius: ${BORDER_RADIUS};
  border-color: transparent;
  padding: ${PADDING.md};
  transition: all 1s ease;

  & svg {
    fill: var(--background-color);
  }

  &:hover {
    border-color: var(--background-color) !important;
    transition: all 1s ease;
  }
`

export const ButtonSeeYourRestaurant = styled(BaseButton)`
  background-color: var(--admin-text-focus-setting-menu-color);
  color: var(--background-color);
  font-weight: ${FONT_WEIGHT.medium};
  border-color: transparent;
  transition: all 1s ease;
  padding: ${PADDING.xxl} ${PADDING.xs};

  &:hover {
    border-color: var(--background-color) !important;
    color: var(--background-color) !important;
    transition: all 1s ease;
  }
`

// info

export const InfoWrapper = styled(BaseRow)``

export const InfoLeftSide = styled(BaseCol)`
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const InfoLeftSideImage = styled(BaseImage)`
  border-radius: 100px !important;
`

export const InfoRightSide = styled(BaseSpace)`
  width: 100%;
`

export const InfoRightSideItem = styled(BaseRow)`
  padding: ${PADDING.xxs} 0px;
  .label {
    color: var(--background-color);
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
  }
  .value {
    color: var(--background-color);
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.regular};
  }
`
export const SplitDivider = styled(BaseDivider)`
  background-color: rgba(113, 113, 122, 1);
  margin: 0px;
`

// option

export const OptionWrapper = styled(BaseSpace)`
  width: 100%;
  .title {
    color: var(--background-color);
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.regular};
  }
`

export const OptionItem = styled(BaseRow)`
  padding: ${PADDING.xxs};
  margin: 0px !important;
  border-radius: ${BORDER_RADIUS};
  background-color: rgba(255, 255, 255, 0.1); // need change
  .label {
    color: #29cc6a;
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
  }
  .value {
    color: #29cc6a;
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.regular};
  }
`

// UpSell
export const UpSellWrapper = styled(BaseSpace)`
  width: 100%;
  .title {
    color: var(--background-color);
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.regular};
  }
`

export const UpSellButtonAddMore = styled(BaseButton)`
  background-color: rgba(255, 255, 255, 0.1); // need change
  border-color: rgba(255, 255, 255, 0.1); // need change
  width: 100%;
  margin: 0px;
  height: 40px;
  .text {
    color: #29cc6a;
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
  }
  svg {
    fill: #29cc6a;
  }
`

export const UpSellItem = styled(BaseRow)`
  padding: ${PADDING.xxs};
  border-radius: ${BORDER_RADIUS};
  margin: 0px !important;
  background-color: rgba(255, 255, 255, 0.1); // need change
  .label {
    color: var(--background-color);
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
  }
  .svg {
    svg {
      fill: var(--background-color);
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
`
