import { IVariationProductManagement } from 'app/api/product-management/model'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { formatMoneyDollar } from 'app/hook'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

interface IProps {
  item: IVariationProductManagement
  onSelect: (value: IVariationProductManagement) => void
  isSelected?: boolean
}

const OptionItem = ({ item }: IProps) => {
  return (
    <OptionItemWrapper
      gutter={[10, 10]}
      justify={'space-between'}
      align={'middle'}
    >
      <BaseCol className="label">
        <BaseRow gutter={[10, 10]} align={'middle'}>
          <BaseCol>{item?.name}</BaseCol>
        </BaseRow>
      </BaseCol>
      <BaseCol className="value">
        {formatMoneyDollar(item.price_money ?? 0, item?.currency)}
      </BaseCol>
    </OptionItemWrapper>
  )
}

export default OptionItem

export const OptionItemWrapper = styled(BaseRow)`
  padding: ${PADDING.xxs};
  margin: 0px !important;
  border-radius: ${BORDER_RADIUS};
  background-color: rgba(255, 255, 255, 0.1); // need change
  .label {
    color: #29cc6a;
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
  }
  .value {
    color: #29cc6a;
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.regular};
  }
`
