/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICategory } from 'app/api/product-management/model'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { Loading } from 'app/components/common/Loading'
import { NotFound } from 'app/components/common/NotFound'
import { usePagination } from 'app/hook'
import { useGetCategoryList } from 'app/react-query/hook/product-management'
import { PADDING } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { ItemCategory } from '../../components/ItemCategory'
import ProductTabTableSetting from './table-order'

const CategoryTab = () => {
  const { data, isLoading: loading } = useGetCategoryList()
  const { flattenData } = usePagination()
  const [categorySelected, setCategorySelected] = useState<ICategory | null>(
    null,
  )

  const categories = useMemo(() => {
    const dataRs = flattenData(data)
    return dataRs
  }, [data])

  const handleSelectCategory = (item: ICategory) => {
    setCategorySelected(item)
  }

  const onGoBackProductCategoryDetail = () => {
    setCategorySelected(null)
  }

  return (
    <RootWrapper
      gutter={[15, 15]}
      justify={'start'}
      align={'middle'}
      wrap={false}
    >
      {!categorySelected &&
        categories?.map((item, index) => {
          return (
            <BaseCol key={index} xl={4}>
              <ItemCategory item={item} onClickItem={handleSelectCategory} />
            </BaseCol>
          )
        })}
      {categorySelected && (
        <ProductTabTableSetting
          onGoBack={onGoBackProductCategoryDetail}
          category={categorySelected}
        />
      )}
      {categories.length === 0 && <NotFound />}
      {loading && <Loading />}
    </RootWrapper>
  )
}

export default CategoryTab

const RootWrapper = styled(BaseRow)`
  padding: ${PADDING.xxs} 0px;
`
