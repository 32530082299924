import { EditOutlined } from '@ant-design/icons'
import { IKiosk } from 'app/api/kiosk/model'
import { IParamsUpdateStatus } from 'app/api/product-management/model'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseSwitch } from 'app/components/common/BaseSwitch'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import {
  RenderValueTableReport,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import { URL_SETTING } from 'app/containers/admin/Settings/constant'
import { usePagination } from 'app/hook'
import {
  useGetKioskList,
  useUpdateKioskStatusByCode,
} from 'app/react-query/hook/kiosk'
import { useAppDispatch } from 'app/redux/hooks'
import {
  setKioskUpdateItem,
  setPathSetting,
} from 'app/redux/slices/pathSettingSlice'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IDataKioskTable {
  id: number
  name: string
  kioskCode: string
  location: string
  status: number
  item?: IKiosk
}

const KioskTableSetting = ({ search }: { search?: string }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigateToEditYourKiosk = (item?: IKiosk) => {
    dispatch(setPathSetting(URL_SETTING.UpdateYourKiosk))
    dispatch(setKioskUpdateItem(item))
  }

  const { data, isLoading: loading } = useGetKioskList({
    page_size: 1000,
  })
  const { flattenDataList } = usePagination()

  const kioskData = useMemo(() => {
    const kiosk: IKiosk[] = flattenDataList(data)
    if (!search) return kiosk

    const keyword = search?.toLowerCase()

    const kioskFilter = kiosk.filter(
      contact =>
        contact?.code?.toString()?.toLowerCase().includes(keyword) ||
        contact?.name?.toLowerCase()?.includes(keyword) ||
        contact?.location?.name?.toLowerCase()?.includes(keyword),
    )

    return kioskFilter
  }, [data, search])

  const dataTable: IDataKioskTable[] = useMemo(() => {
    return kioskData?.map(item => {
      return {
        id: item?.code ?? '-',
        name: item?.name ?? '-',
        kioskCode: item?.code ?? '-',
        location: item?.location?.name ?? '-',
        status: item.status ? 1 : 0,
        item,
      } as IDataKioskTable
    })
  }, [kioskData])
  // console.log('xxx', dataTable, data)
  // const onChangePage = (page: number, pageSize?: number) => {
  //   setParams({
  //     ...params,
  //     page,
  //     page_size: pageSize ?? 10,
  //   })
  // }

  // const total = useMemo(() => {
  //   return kioskData?.length ?? 0
  // }, [kioskData])

  const {
    mutateAsync: mutateAsyncUpdateKioskStatusByCode,
    isLoading: loadingUpdate,
  } = useUpdateKioskStatusByCode()

  const updateStatusItem = (code: string, status: number) => {
    const params: IParamsUpdateStatus = {
      code,
      status: !!status,
    }

    mutateAsyncUpdateKioskStatusByCode(params)
  }

  const column = useMemo(() => {
    return [
      convertColumnTable<IDataKioskTable>({
        title: t(R.strings.table_name),
        key: 'name',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            valueString: record?.name,
          }),
      }),
      convertColumnTable<IDataKioskTable>({
        title: t(R.strings.table_kiosk_code),
        key: 'kioskCode',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            valueString: record?.kioskCode,
          }),
      }),

      convertColumnTable<IDataKioskTable>({
        title: t(R.strings.table_location),
        key: 'location',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            valueString: record?.location,
          }),
      }),
      convertColumnTable<IDataKioskTable>({
        title: t(R.strings.table_status),
        key: 'status',
        render: (_, record) => {
          return (
            <BaseRow justify={'space-between'} align={'middle'}>
              <BaseCol>
                <SwitchButton
                  checked={!!record?.status}
                  onChange={checked => {
                    updateStatusItem(record?.kioskCode, checked ? 1 : 0)
                  }}
                />
              </BaseCol>
              <BaseSpace direction="vertical" size={5}>
                <BaseCol>
                  <EditButton
                    rev={undefined}
                    onClick={() => navigateToEditYourKiosk(record?.item)}
                  />
                </BaseCol>
              </BaseSpace>
            </BaseRow>
          )
        },
      }),
    ]
  }, [i18n.language])

  return (
    <BaseTableReport
      columns={column}
      dataSource={dataTable}
      // pagination={{
      //   current: params?.page ?? 1,
      //   pageSize: params.page_size,
      //   total: total ?? 0,
      //   showSizeChanger: false,
      //   position: ['bottomCenter'],
      //   onChange: onChangePage,
      // }}
      pagination={false}
      scroll={{ x: true }}
      loading={loading || loadingUpdate}
    />
  )
}
export default KioskTableSetting

export const SwitchButton = styled(BaseSwitch)`
  &.ant-switch.ant-switch-checked {
    background: rgba(41, 204, 106, 1); // need change
  }

  &.ant-switch.ant-switch-checked:hover {
    background: rgba(41, 204, 106, 1); // need change
  }
`
const EditButton = styled(EditOutlined)`
  svg {
    width: 18px;
    height: 18px;
  }
  cursor: pointer;
`
