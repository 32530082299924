import { DASHBOARD_PATH } from 'app/components/router/route-path'
import { URL_REPORTS } from 'app/containers/admin/Reports/constant'
import { URL_SETTING } from 'app/containers/admin/Settings/constant'
import i18n from 'app/i18n'
import { LanguageSupportType } from 'app/i18n/constant'
import { KEY_STORAGE } from '../key'

export const getToken = () => {
  return localStorage.getItem(KEY_STORAGE.TOKEN)
}

export const refetchToken = () => {
  localStorage.setItem(KEY_STORAGE.TOKEN, '')
}

export const setToken = async (token: string) => {
  return localStorage.setItem(KEY_STORAGE.TOKEN, token)
}

export const getCurrentLanguage = () => {
  // const currentLanguage = localStorage.getItem(KEY_STORAGE.LANGUAGE)
  // if (!currentLanguage) {
  //   return LANGUAGE_SUPPORT.vi
  // }
  // return currentLanguage
  // return LANGUAGE_SUPPORT.en
  return i18n.language
}

export const setCurrentLanguage = (language: LanguageSupportType | string) => {
  return localStorage.setItem(KEY_STORAGE.LANGUAGE, language)
}

export const getDrawer = () => {
  return localStorage.getItem(KEY_STORAGE.DRAWER)
}

export const setDrawer = (data: string) => {
  return localStorage.setItem(KEY_STORAGE.DRAWER, data)
}

export const getCurrentPath = () => {
  return localStorage.getItem(KEY_STORAGE.PATH) ?? DASHBOARD_PATH
}

export const setCurrentPath = (data: string) => {
  return localStorage.setItem(KEY_STORAGE.PATH, data)
}

export const setCurrentPathSetting = (data: string) => {
  return localStorage.setItem(KEY_STORAGE.PATH_SETTING, data)
}

export const getCurrentPathSetting = () => {
  return (
    localStorage.getItem(KEY_STORAGE.PATH_SETTING) ?? URL_SETTING.Appearance
  )
}

export const setCurrentPathReport = (data: string) => {
  return localStorage.setItem(KEY_STORAGE.PATH_REPORT, data)
}

export const getCurrentPathReport = () => {
  return (
    localStorage.getItem(KEY_STORAGE.PATH_REPORT) ?? URL_REPORTS.Home
  )
}

export const setServicePackage = (data: string) => {
  return localStorage.setItem(KEY_STORAGE.SERVICE_PACKAGE, data)
}

export const getServicePackage = () => {
  return localStorage.getItem(KEY_STORAGE.SERVICE_PACKAGE)
}

export const setEmailAuth = (data: string) => {
  return localStorage.setItem(KEY_STORAGE.EMAIL_AUTH, data)
}

export const getEmailAuth = () => {
  return localStorage.getItem(KEY_STORAGE.EMAIL_AUTH)
}

export const setPassword = (data: string) => {
  return localStorage.setItem(KEY_STORAGE.PASSWORD, data)
}

export const getPassword = () => {
  return localStorage.getItem(KEY_STORAGE.PASSWORD)
}
