import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { SearchInput } from 'app/components/common/inputs/SearchInput'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { BORDER_RADIUS, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'
import { Text } from '../../styles'
import { BaseDivider } from 'app/components/common/BaseDivider'
import SelectionRestaurant from '../../components/SelectionLocation'

export const RootWrapper = styled(BaseSpace)`
  padding: ${PADDING.xxxxs};
  width: 100%;
`

export const HeaderWrapper = styled(BaseRow)``

export const BackButton = styled(BaseButton)`
  background-color: rgba(255, 255, 255, 0.1); // need change
  border-radius: ${BORDER_RADIUS};
  border-color: transparent;
  padding: ${PADDING.md};
  transition: all 1s ease;
  margin-right: 24px;

  & svg {
    fill: var(--background-color);
  }

  &:hover {
    border-color: var(--background-color) !important;
    transition: all 1s ease;
  }
`

export const SearchLayout = styled(SearchInput)`
  .ant-input-group-addon {
    display: none;
  }
  .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: ${BORDER_RADIUS};
    border: 0;
    background: transparent !important;
    color: var(--background-color);
    border: 0.7px solid rgba(45, 48, 62, 1);
  }
  input {
    background: transparent !important;
    color: var(--background-color);
  }
`

export const BodyWrapper = styled(BaseRow)``

export const BodyLeftSide = styled(BaseCol)`
  width: 244px;
`

export const BodyLeftSideTitle = styled.div`
  font-size: 14px;
  font-weight: ${FONT_WEIGHT.regular};
  margin-bottom: 8px;
  color: var(--background-color);
`

export const YourRestaurantWrapper = styled.div`
  border: 1px solid #d9d9d94d;
  border-radius: 10px;
  padding: 16px;
  color: var(--background-color);
`

export const RestaurantLocationSelect = styled(BaseSelect)`
  border: 1px solid #d9d9d94d;
  border-radius: 20px;
  margin-bottom: 16px;

  &.ant-select-disabled {
    background: #2f2d3a;
    & .ant-select-selection-item {
      color: #d9d9d94d !important;
    }
    & .ant-select-arrow {
      display: none;
    }
  }
  & .ant-select-selector {
    background-color: transparent !important;
    color: #ffffff;
    border: unset !important;
    border-radius: 20px;
    box-shadow: unset !important;
  }
  & .ant-select-arrow {
    top: 50% !important;
  }
  & .ant-select-selection-item {
    color: #ffffff !important;
  }
  & .ant-select-dropdown {
    background-color: #1f1d2b;
    color: #ffffff;
    border: 1px solid #d9d9d94d;
    & .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #d9d9d94d !important;
    }
    &
      .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
      .ant-select-item-option-content {
      color: #ffffff !important;
    }
  }
  & .ant-select-item-option-content {
    color: #ffffff;
  }
  & .ant-select-item-option:not(.ant-select-item-option-selected):hover {
    background-color: #d9d9d926 !important;
  }
`

export const CustomSelectionRestaurant = styled(SelectionRestaurant)`
  margin-bottom: 16px;
  .ant-select {
    border: 1px solid #d9d9d94d;
    border-radius: 20px;
    & .ant-select-selector {
      background-color: transparent !important;
      color: #ffffff;
      border: unset !important;
      border-radius: 20px;
      box-shadow: unset !important;
    }
    & .ant-select-arrow {
      top: 50% !important;
    }
    & .ant-select-selection-item {
      color: #ffffff !important;
    }
    & .ant-select-dropdown {
      background-color: #1f1d2b;
      color: #ffffff;
      border: 1px solid #d9d9d94d;
      & .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: #d9d9d94d !important;
      }
      &
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
        .ant-select-item-option-content {
        color: #ffffff !important;
      }
    }
    & .ant-select-item-option-content {
      color: #ffffff;
    }
    & .ant-select-item-option:not(.ant-select-item-option-selected):hover {
      background-color: #d9d9d926 !important;
    }
  }
`

export const BodyRightSide = styled(BaseCol)`
  min-width: 650px;
`

export const RightSideTitleWrapper = styled(BaseRow)`
  padding-top: 20px;
`

export const RestaurantAppearanceWrapper = styled(BaseRow)<{
  $bgColor: string
}>`
  background-color: ${props => props.$bgColor};
  background-clip: padding-box;
  margin-top: 8px;
  padding: 0 0 16px 16px;
  border: 1px solid #d9d9d94d;
  border-radius: 10px;
`

export const MenuAppearanceWrapper = styled(BaseCol)`
  width: 420px;
  padding-top: 16px;
`

export const RestaurantInfo = styled.div`
  // min-width: 420px;
`

export const RestaurantImage = styled.div`
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: var(--admin-background-focus-setting-menu-color);
  border-radius: ${BORDER_RADIUS};
  margin-right: 32px;
`

export const RestaurantName = styled.div`
  display: inline-block;
  div:nth-child(2) {
    margin-top: 4px;
    opacity: 0.8;
  }
`

export const FakeInputSearch = styled.div<{ $color: string }>`
  height: 26px;
  border: 0.5px solid #393c49;
  border-radius: 4px;
  background-clip: padding-box;
  background-color: transparent;
  color: ${props => props.$color};
  .anticon {
    padding-left: 8px;
    font-size: 8px;
  }
  .placeholder {
    padding: 0 8px;
    font-size: 8px;
    font-weight: 400;
    opacity: 0.8;
  }
`

export const FakeTab = styled.div<{ $color: string }>`
  margin-left: 62px;
  margin-top: 8px;
  padding-bottom: 4px;
  border-bottom: 1px solid #393c49;
  position: relative;
  .label {
    margin: 0 8px;
    font-size: 8px;
    padding-bottom: 4px;
    color: ${props => props.$color};
    &:first-child {
      margin-left: 0;
      font-weight: 600;
    }
  }
  .color-bar {
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 20px;
    height: 1px;
    background-color: ${props => props.$color};
  }
`

export const ChooseDishes = styled(Text)`
  margin-left: 62px;
  margin-top: 16px;
`

export const DishesWrapper = styled(BaseRow)`
  margin-top: 16px;
`

export const DishesItem = styled(BaseCol)<{ $color: string }>`
  padding-top: 35px;
  .dishes-data {
    border-radius: 8px;
    position: relative;
    width: 104px;
    height: 122px;
    background-color: #1f1d2b;
    .img {
      position: absolute;
      top: -35px;
      left: calc(50% - 35px);
      width: 70px;
      height: 70px;
    }
    .content {
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70px;
      color: ${props => props.$color};
      .name,
      .price,
      .number-available {
        font-size: 8px;
        text-align: center;
      }
      .number-available {
        opacity: 0.6;
      }
    }
  }
`

export const CheckoutAppearanceWrapper = styled(BaseCol)`
  margin-left: 16px;
  padding: 16px;
  background-color: #1f1d2b;
  border-radius: 4px 8px 4px 4px;
  min-width: 220px;
  flex: 1;
`

export const CheckoutHeaderItemWrapper = styled(BaseRow)`
  margin-top: 32px;
  .ant-col:nth-child(2) {
    padding: 0 16px 0 0;
  }
  .ant-col:nth-child(3) {
    width: 30px;
    text-align: right;
  }
`

export const SplitDivider = styled(BaseDivider)`
  margin-top: 16px;
  background-color: #393c49;
`

export const CheckoutHeaderItem = styled(BaseCol)<{ $color: string }>`
  font-size: 9px;
  color: ${props => props.$color};
`

export const CheckoutItemWrapper = styled.div`
  height: 246px;
  overflow: hidden;
  border-bottom: 0.5px solid #363845;
`

export const CheckoutItem = styled.div`
  margin-top: 16px;
`

export const CheckoutItemFirstRow = styled(BaseRow)`
  .input-qty {
    padding: 0 9px 0 0;
  }
  .price {
    font-size: 8px;
    line-height: 26px;
    width: 30px;
    text-align: right;
  }
  .food-info {
    min-width: 114px;
    img {
      display: inline-block;
      width: 22px;
      height: 22px;
      margin-right: 8px;
    }
    .info-data {
      display: inline-block;
      div:nth-child(2){
        margin-top: 4px;
        opacity: 0.8;
    }
  }
`

interface FakeInputProps {
  $color: string
  $placeholder: boolean
  $width?: string
  $bgColor?: string
  $borderColor?: string
  $iconColor?: string
  $textCenter?: boolean
  $margin?: string
}
export const FakeInput = styled.div<FakeInputProps>`
  position: relative;
  margin: ${props => props.$margin || '0'};
  width: ${props => props.$width || '27px'};
  height: 26px;
  border: 0.5px solid;
  border-color: ${props => props.$borderColor || '#393C49'};
  border-radius: 4px;
  background-color: ${props => props.$bgColor || '#2D303E'};
  padding-left: 10px;
  padding-right: 10px;
  text-align: ${props => (props.$textCenter ? 'center' : 'unset')};
  .content {
    font-size: 8px;
    color: ${props => props.$color};
    opacity: ${props => (props.$placeholder ? '0.5' : '1')};
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${props => props.$iconColor || '#393C49'};
  }
`

export const CheckoutItemSecondRow = styled(BaseRow)`
  margin-top: 4px;
`

export const TotalItem = styled(BaseRow)`
  margin-top: 12px;
`

export const SaveButton = styled(BaseButton)`
  background-color: var(--admin-text-focus-setting-menu-color);
  color: var(--background-color);
  font-weight: ${FONT_WEIGHT.medium};
  border-color: transparent;
  transition: all 1s ease;
  margin-top: 32px;
  float: right;

  &:hover {
    border-color: var(--background-color) !important;
    color: var(--background-color) !important;
    transition: all 1s ease;
  }
`
