import { BaseRow } from "app/components/common/BaseRow";
import styled from "styled-components";

export const RootWrapper = styled(BaseRow)`
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`

export const LeftLayout = styled(BaseRow)``

export const RightLayout = styled(BaseRow)``

export const TitleWrapper = styled.div``