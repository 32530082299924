import styled from 'styled-components'
import { BaseTypography } from '../BaseTypography'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'

export const NotFoundWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`

export const ImgWrapper = styled.div`
  max-width: 4rem;
`

export const Text = styled(BaseTypography.Text)`
  font-weight: ${FONT_WEIGHT.medium};
  font-size: ${FONT_SIZE.md};
  color: var(--background-color);
`
