/* eslint-disable @typescript-eslint/no-explicit-any */
import { BoxPlotFilled } from '@ant-design/icons'
import {
  IResponseQueryList,
  IUpSellProductManagement,
  ParamsListDefault,
} from 'app/api/product-management/model'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { usePagination } from 'app/hook'
import { useGetUpSellList } from 'app/react-query/hook/product-management'
import { useMemo, useState } from 'react'
import SearchComponent from '../../../../components/Search'
import SelectionCategory from '../../../../components/SelectionCategory'
import * as S from './styles'
import TableComp, { IDataTableUpSell } from './table'
import AddYourUpSellsModal from './components/AddYourUpSellsModal'
import { DefineValueUpSellAt } from './components/SelectionUpSellAt'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'

export const UpSell = () => {
  const { t } = useTranslation()
  const [params, setParams] = useState(ParamsListDefault)

  const { data, isLoading: loading } = useGetUpSellList(params)
  const { flattenDataList } = usePagination()

  const upSellData = useMemo(() => {
    const upSell: IResponseQueryList<IUpSellProductManagement[]> =
      flattenDataList(data)
    return upSell
  }, [data, params])

  const dataTable: IDataTableUpSell[] = useMemo(() => {
    return upSellData?.data?.map(item => {
      return {
        id: item?.id ?? '-',
        name: item?.item_id?.[0]?.name ?? '-',
        item: item?.item_id ?? '-',
        location: item?.present_at_location_names ?? ['-'],
        variant: item?.varation ?? '-',
        price: item?.price ?? 0,
        status: item?.status ?? 0,
        upsellAt: DefineValueUpSellAt[item?.upsell_at ?? ''] ?? '-',
        quantity: item?.max_qty ?? '-',
        image: item?.image,
        itemByUpSell: item?.item_by_upsell?.map(item => item.name) ?? [],
        currency: item?.currency,
      } as IDataTableUpSell
    })
  }, [upSellData])

  const totalItem = useMemo(() => {
    return upSellData?.total ?? 0
  }, [upSellData])

  const onChangePage = (page: number, pageSize?: number) => {
    setParams({
      ...params,
      page,
      page_size: pageSize ?? 10,
    })
  }

  const onChangeSearch = (value: string) => {
    setParams({
      ...params,
      name: value,
    })
  }

  const onChangeCategory = (value: string) => {
    setParams({
      ...params,
      category_id: value,
    })
  }

  return (
    <S.RootWrapper direction="vertical" size={5}>
      <S.FilterWrapper
        gutter={[20, 10]}
        align={'middle'}
        justify={'space-between'}
      >
        <BaseRow gutter={[20, 10]} align={'middle'}>
          <BaseCol>
            <SearchComponent
              initValue={params?.name}
              onChange={onChangeSearch}
            />
          </BaseCol>
          <BaseCol>
            <SelectionCategory onChange={onChangeCategory} />
          </BaseCol>
        </BaseRow>
        <BaseCol>
          <AddYourUpSellsModal />
        </BaseCol>
      </S.FilterWrapper>
      <BaseRow justify={'end'}>
        <S.TotalItemLayout gutter={[10, 10]} align={'middle'}>
          <BaseCol>
            <BoxPlotFilled rev={undefined} />
          </BaseCol>
          <BaseCol>
            <BaseCol className="text">{`${totalItem} ${t(
              R.strings.item,
            ).toLowerCase()}`}</BaseCol>
          </BaseCol>
        </S.TotalItemLayout>
      </BaseRow>
      <BaseCol>
        <TableComp
          data={dataTable}
          onChangePage={onChangePage}
          total={totalItem}
          loadingList={loading}
        />
      </BaseCol>
    </S.RootWrapper>
  )
}
