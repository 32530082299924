import { ApiClient } from 'app/service/api-service'
import { KioskApiEndpoint } from './contant'
import { ICreateKiosk, IParamsKioskList, IUpdateKiosk } from './model'
import { IParamsUpdateStatus } from '../product-management/model'
import { LanguageSupportType } from 'app/i18n/constant'

export const requestGetKioskList = (params: IParamsKioskList) =>
  ApiClient.Get({
    url: KioskApiEndpoint.GET_KIOSK_LIST,
    params: { params },
  })

export const requestGetKioskByCode = (code: number) =>
  ApiClient.Post({ url: `${KioskApiEndpoint.KIOSK}${code}/`, body: {} })

export const requestCreateKiosk = (body: ICreateKiosk) =>
  ApiClient.Post({ url: KioskApiEndpoint.KIOSK, body })

export const requestUpdateKiosk = (body: IUpdateKiosk) => {
  const { code, ...rest } = body
  return ApiClient.Put({ url: `${KioskApiEndpoint.KIOSK}${code}/`, body: rest })
}

export const requestUpdateLanguageKiosk = (body: {
  code: number
  language: LanguageSupportType
}) => {
  const { code, ...rest } = body
  return ApiClient.Put({
    url: `${KioskApiEndpoint.KIOSK}${code}/${KioskApiEndpoint.LANGUAGE}`,
    body: rest,
  })
}

export const requestChangeKioskStatusByCode = ({
  code,
  status,
}: IParamsUpdateStatus) =>
  ApiClient.Put({
    url: `${KioskApiEndpoint.KIOSK}${code}/status/`,
    body: { status },
  })
