import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseRow)<{ $isSelected?: boolean }>`
  padding: ${PADDING.xxl} ${PADDING.xl};
  padding-right: 0px;
  width: 100%;
  cursor: pointer;
  background-color: ${props =>
    props?.$isSelected
      ? 'var(--admin-background-focus-setting-menu-color)'
      : 'transparent'};

  transition: all 1s ease;
  border-radius: ${BORDER_RADIUS};
`

export const Image = styled(BaseImage)``

export const Title = styled.div<{ $isSelected?: boolean }>`
  color: ${props =>
    props?.$isSelected
      ? 'var(--admin-text-focus-setting-menu-color)'
      : 'var(--background-color)'};
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.medium};
  transition: all 1s ease;
`

export const Description = styled.div`
  color: var(--admin-text-description-setting-menu-color);
  font-size: ${FONT_SIZE.xxxs};
  font-weight: ${FONT_WEIGHT.regular};
`

export const Space = styled.div`
  height: 5px;
`

export const RightLine = styled(BaseCol)`
  background-color: var(--admin-text-focus-setting-menu-color);
  width: 3px;
  height: 33px;
  border-radius: ${BORDER_RADIUS};
  transition: all 2s ease;
`
