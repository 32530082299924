/* eslint-disable @typescript-eslint/no-explicit-any */
import { Option } from 'app/components/common/selects/BaseSelect'
import BaseSelectInUpSell from '../BaseSelect'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import { useMemo } from 'react'

export enum UpSellAtTypeUpSellEnum {
  ITEM = 'item',
  ORDER = 'order',
}

interface IProps {
  onChange?: (value: UpSellAtTypeUpSellEnum) => void
  value?: UpSellAtTypeUpSellEnum
  defaultValue?: UpSellAtTypeUpSellEnum
}

export const DefineValueUpSellAt = {
  [UpSellAtTypeUpSellEnum.ITEM]: R.strings.product_management_item_level,
  [UpSellAtTypeUpSellEnum.ORDER]: R.strings.product_management_order_level,
}

function SelectionLevel({ onChange, value, defaultValue }: IProps) {
  const { t, i18n } = useTranslation()
  const onChangeSelect = (value: any) => {
    onChange && onChange(value)
  }

  const data = useMemo(() => ([
    {
      id: UpSellAtTypeUpSellEnum.ITEM,
      name: t(DefineValueUpSellAt[UpSellAtTypeUpSellEnum.ITEM]),
    },
    {
      id: UpSellAtTypeUpSellEnum.ORDER,
      name: t(DefineValueUpSellAt[UpSellAtTypeUpSellEnum.ORDER]),
    },
  ]), [i18n.language])

  return (
    <BaseSelectInUpSell
      placeholder={t(R.strings.form_item_select_up_sell_at_p)}
      onChange={onChangeSelect}
      value={value}
      defaultValue={defaultValue}
      title={t(R.strings.form_item_select_up_sell_at_t)}
    >
      {data.map((item, index) => {
        return (
          <Option key={index} value={item?.id}>
            {item.name}
          </Option>
        )
      })}
    </BaseSelectInUpSell>
  )
}

export default SelectionLevel
