import { BaseImage } from 'app/components/common/BaseImage'
import { ADMIN_PATH } from 'app/components/router/route-path'
import { isEmpty, isEqual } from 'lodash'
import { BORDER_RADIUS } from 'parkway-web-common'
import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'
import * as S from './MainSider/styles'
import { useAppSelector } from 'app/redux/hooks'

interface SliderLogoProps {
  isSliderCollapsed: boolean
  toggleSlider: () => void
}
export const SliderLogo: React.FC<SliderLogoProps> = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const user = useAppSelector(state => state.user).user
  const isHomeSelected = useMemo(() => {
    return isEqual(location?.pathname, ADMIN_PATH.HOME)
  }, [location?.pathname])

  return (
    <S.SliderLogoDiv
      isHome={isHomeSelected}
      onClick={() => navigate(ADMIN_PATH.HOME)}
    >
      <S.SliderLogoLink to={ADMIN_PATH.DASHBOARD}>
        <BaseImage
          src={
            !isEmpty(user?.logo)
              ? user?.logo
              : 'https://storage.googleapis.com/kiosify-01/images/ic_logo_kiosify.png'
          }
          width={40}
          style={{ borderRadius: BORDER_RADIUS }}
          preview={false}
        />
      </S.SliderLogoLink>
    </S.SliderLogoDiv>
  )
}
