import { SvgPrinterIcon } from 'app/assets/svg-assets'
import { memo } from 'react'
import * as ST from '../styles'
import * as S from './styles'
import { useTranslation } from 'react-i18next';
import R from 'app/assets/R';

interface TabPrinterLayoutProps {
  printersData: string[];
  searchPrinters: string;
  onChangeSearchPrinters: (value: string) => void;
  onConnectPrinter: () => void;
  onTroubleshoot: () => void;
}

const TabPrinterLayout = (props: TabPrinterLayoutProps) => {
  const { t } = useTranslation()

  return (
    <>
      <ST.SearchTabPaneWrapper
        align={'middle'}
        justify={'space-between'}
      >
        <ST.SearchTabPaneInput
          value={props.searchPrinters}
          placeholder={t(R.strings.input_placeholder_search_printer)}
          onChange={event => props.onChangeSearchPrinters(event.target.value)}
        />
        <ST.TabPaneMainButton onClick={props.onConnectPrinter}>
          {t(R.strings.your_infomation_connect_printer)}
        </ST.TabPaneMainButton>
      </ST.SearchTabPaneWrapper>

      {
        props.printersData.length === 0 ?
          <>
            <ST.NoData>
              {t(R.strings.your_infomation_no_printer_connected)}
            </ST.NoData>

            <ST.SplitDivider />

            <ST.TroubleshootMessage>
              {t(R.strings.your_infomation_troubleshoot_d)}
              &nbsp;
              <ST.TroubleshootAction onClick={props.onTroubleshoot}>
                {t(R.strings.your_infomation_troubleshoot)}
              </ST.TroubleshootAction>
            </ST.TroubleshootMessage>
          </>
          :
          <S.PrintersDataWrapper
            align={'middle'}
            gutter={[16, 16]}
          >
            {
              props.printersData.map((item, index) => {
                return (
                  <S.PrintersDataItem
                    key={`${item} ${index}`}
                  >
                    <div className={`printer-icon-wrapper ${index === 1 || index === 8 ? 'active' : ''}`}>
                      <SvgPrinterIcon />
                    </div>
                    <div className="printer-name">
                      {item}
                    </div>
                  </S.PrintersDataItem>
                )
              })
            }
          </S.PrintersDataWrapper>
      }
    </>
  )
}

export default memo(TabPrinterLayout)
