/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IResponseQueryList,
  IVariationProductManagement,
} from 'app/api/product-management/model'
import { Loading } from 'app/components/common/Loading'
import { Option } from 'app/components/common/selects/BaseSelect'
import { usePagination } from 'app/hook'
import { useGetVariationListByItemId } from 'app/react-query/hook/product-management'
import { useMemo } from 'react'
import BaseSelectInUpSell from '../BaseSelect'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

interface IProps {
  onChange?: (value: IVariationProductManagement) => void
  value?: IVariationProductManagement
  defaultValue?: string
  keyAllValue?: string
  className?: string
  id?: string | number
}

export const KEY_ALL = R.strings.key_all_label

function SelectionVariation({
  onChange,
  value,
  defaultValue,
  keyAllValue = '',
  id,
}: IProps) {
  if (!id) return null
  const { t, i18n } = useTranslation()
  const { data, isLoading: loading } = useGetVariationListByItemId({ id })
  const { flattenDataList } = usePagination()

  const dataConvert = useMemo(() => {
    const variationData: IResponseQueryList<IVariationProductManagement[]> =
      flattenDataList(data)
    const list: IVariationProductManagement[] = [
      // {
      //   item_id: keyAllValue,
      //   name: t(KEY_ALL),
      // },
    ]
    return list.concat(variationData?.data ?? [])
  }, [data, keyAllValue, i18n.language])

  const onChangeSelect = (value: any) => {
    const itemVariationProductManagement = dataConvert?.find(
      item => item?.id === value,
    )
    if (!itemVariationProductManagement) return
    onChange && onChange(itemVariationProductManagement)
  }

  return (
    <div>
      <BaseSelectInUpSell
        placeholder={t(R.strings.form_item_select_item_variation_p)}
        onChange={onChangeSelect}
        value={value?.id}
        defaultValue={defaultValue}
        title={t(R.strings.form_item_select_item_variation_t)}
      >
        {dataConvert?.map((item, index) => {
          return (
            <Option key={index} value={item?.id}>
              {item.name}
            </Option>
          )
        })}
      </BaseSelectInUpSell>
      {loading && <Loading />}
    </div>
  )
}

export default SelectionVariation
