import { memo, useState } from 'react';
import * as ST from '../styles';
import * as S from './styles';
import { SvgLanguageIcon, SvgNoteIcon, SvgPageFlipIcon, SvgVoiceCircleIcon, SvgWalkingIcon } from 'app/assets/svg-assets';
import { ArrowLeftOutlined, RightOutlined } from '@ant-design/icons';
import { BaseSwitch } from 'app/components/common/BaseSwitch';
import { useTranslation } from 'react-i18next';
import R from 'app/assets/R';

interface TabProfileLayoutProps {
  searchProfile: string;
  onChangeSearchProfile: (value: string) => void;
  deviceName: string;
  onChangeDevicename: (value: string) => void;
  onTroubleshoot: () => void;
}

const TabProfileLayout = (props: TabProfileLayoutProps) => {
  const { t } = useTranslation()
  const [isCreateProfileModalOpen, setIsCreateProfileModalOpen] = useState(false)
  const [isReceiptsModalOpen, setIsReceiptsModalOpen] = useState(false)

  const createProfile = () => {
    // do something
    console.log('createProfile ran')

    setIsCreateProfileModalOpen(true)
  }

  const handleCreateProfileModalOk = () => {
    console.log('handleCreateProfileModalOk ran')
    setIsCreateProfileModalOpen(false)
  }

  const handleCreateProfileModalCancel = () => {
    console.log('handleCreateProfileModalCancel ran')
    setIsCreateProfileModalOpen(false)
  }

  const handleReceiptsModalCancel = () => {
    console.log('handleReceiptsModalCancel ran')
    setIsReceiptsModalOpen(false)
  }

  return (
    <>
      <ST.SearchTabPaneWrapper
        align={'middle'}
        justify={'space-between'}
      >
        <ST.SearchTabPaneInput
          value={props.searchProfile}
          placeholder={t(R.strings.input_placeholder_search_profile)}
          onChange={event => props.onChangeSearchProfile(event.target.value)}
        />
        <ST.TabPaneMainButton onClick={createProfile}>
          {t(R.strings.your_infomation_create_profile)}
        </ST.TabPaneMainButton>
      </ST.SearchTabPaneWrapper>

      {
        // false ?
        // <>{'Component that show profiles'}</>
        // :
        <>
          <ST.NoData>
            {t(R.strings.your_infomation_no_profile)}
          </ST.NoData>

          <ST.SplitDivider />

          <ST.TroubleshootMessage>
            {t(R.strings.your_infomation_troubleshoot_d)}
            &nbsp;
            <ST.TroubleshootAction onClick={props.onTroubleshoot}>
              {t(R.strings.your_infomation_troubleshoot)}
            </ST.TroubleshootAction>
          </ST.TroubleshootMessage>
        </>
      }

      <S.CreateProfileModal
        title={t(R.strings.your_infomation_create_profile)}
        width={543}
        footer={null}
        open={isCreateProfileModalOpen}
        onCancel={handleCreateProfileModalCancel}
      >
        <S.SaveCreateProfileModalButton onClick={handleCreateProfileModalOk}>
          {t(R.strings.save)}
        </S.SaveCreateProfileModalButton>

        <S.CreateProfileModalInput
          value={props.deviceName}
          placeholder={t(R.strings.input_placeholder_device_name)}
          onChange={event => props.onChangeDevicename(event.target.value)}
        ></S.CreateProfileModalInput>

        <S.PrinterDefaultTitle>
          {t(R.strings.your_infomation_printer_defaults)}
        </S.PrinterDefaultTitle>
        <S.PrinterDefaultDescription>
          {t(R.strings.your_infomation_printer_defaults_d)}
        </S.PrinterDefaultDescription>

        {/* Receitps */}
        <S.CreateProfilePrinterItem
          align={'middle'}
          justify={'space-between'}
          onClick={() => {
            setIsReceiptsModalOpen(true)
          }}
        >
          <S.PrinterItemLeftLayout>
            <SvgNoteIcon />

            <span className='left-layout-title'>{t(R.strings.your_infomation_receipts)}</span>
          </S.PrinterItemLeftLayout>

          <S.PrinterItemRightLayout>
            <span className='right-layout-status'>{t(R.strings.off)}</span>
            <RightOutlined rev={undefined} />
          </S.PrinterItemRightLayout>
        </S.CreateProfilePrinterItem>
        <ST.SplitDivider />
        {/* In - person order tickets */}
        <S.CreateProfilePrinterItem
          align={'middle'}
          justify={'space-between'}
          onClick={() => console.log('check')}
        >
          <S.PrinterItemLeftLayout>
            <SvgWalkingIcon />
            <span className='left-layout-title'>{t(R.strings.your_infomation_in_person_order_tickets)}</span>
          </S.PrinterItemLeftLayout>

          <S.PrinterItemRightLayout>
            <span className='right-layout-status'>{t(R.strings.off)}</span>
            <RightOutlined rev={undefined} />
          </S.PrinterItemRightLayout>
        </S.CreateProfilePrinterItem>
        <ST.SplitDivider />
        {/* Online order tickets */}
        <S.CreateProfilePrinterItem
          align={'middle'}
          justify={'space-between'}
          onClick={() => console.log('check')}
        >
          <S.PrinterItemLeftLayout>
            <SvgPageFlipIcon />
            <span className='left-layout-title'>{t(R.strings.your_infomation_online_order_tickets)}</span>
          </S.PrinterItemLeftLayout>

          <S.PrinterItemRightLayout>
            <span className='right-layout-status'>{t(R.strings.off)}</span>
            <RightOutlined rev={undefined} />
          </S.PrinterItemRightLayout>
        </S.CreateProfilePrinterItem>
        <ST.SplitDivider />
        {/* Order ticket stubs */}
        <S.CreateProfilePrinterItem
          align={'middle'}
          justify={'space-between'}
          onClick={() => console.log('check')}
        >
          <S.PrinterItemLeftLayout>
            <SvgLanguageIcon />
            <span className='left-layout-title'>{t(R.strings.your_infomation_order_ticket_stubs)}</span>
          </S.PrinterItemLeftLayout>

          <S.PrinterItemRightLayout>
            <span className='right-layout-status'>{t(R.strings.off)}</span>
            <RightOutlined rev={undefined} />
          </S.PrinterItemRightLayout>
        </S.CreateProfilePrinterItem>
        <ST.SplitDivider />
        {/* Void tickets */}
        <S.CreateProfilePrinterItem
          align={'middle'}
          justify={'space-between'}
          onClick={() => console.log('check')}
        >
          <S.PrinterItemLeftLayout>
            <SvgVoiceCircleIcon />
            <span className='left-layout-title'>{t(R.strings.your_infomation_void_tickets)}</span>
          </S.PrinterItemLeftLayout>

          <S.PrinterItemRightLayout>
            <span className='right-layout-status'>{t(R.strings.off)}</span>
            <RightOutlined rev={undefined} />
          </S.PrinterItemRightLayout>
        </S.CreateProfilePrinterItem>
        <ST.SplitDivider />
      </S.CreateProfileModal>

      <S.ReceiptsModal
        title={t(R.strings.your_infomation_receipts)}
        width={543}
        footer={null}
        closeIcon={<ArrowLeftOutlined rev={null} />}
        open={isReceiptsModalOpen}
        onCancel={handleReceiptsModalCancel}
      >
        <S.ReceiptsModalActionItem
          align={'middle'}
          justify={'space-between'}
        >
          <S.ActionItemLeftLayout>
            <div className="left-layout-title">
              {t(R.strings.your_infomation_use_this_printer_for_receipts)}
            </div>
            <div className="left-layout-description">
              {t(R.strings.your_infomation_use_this_printer_for_receipts_d)}
            </div>
          </S.ActionItemLeftLayout>
          <S.ActionItemRightLayout>
            <BaseSwitch defaultChecked />
          </S.ActionItemRightLayout>
        </S.ReceiptsModalActionItem>
        <ST.SplitDivider />
      </S.ReceiptsModal>
    </>
  )
}

export default memo(TabProfileLayout)
