import {
  IDataReportOrder,
  IParamsListGetReportOrder,
} from 'app/api/dashboard/type'
import { IResponseQueryList } from 'app/api/product-management/model'
import R from 'app/assets/R'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import {
  RenderValueTableReport,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import { formatMoneyDollar, usePagination } from 'app/hook'
import { useGetReportOrder } from 'app/react-query/hook/dashboard'
import { DateUtil } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

enum TypeOrderEnum {
  OnTime = 1,
  Late,
  ToGo,
}

export enum PaymentMethodEnum {
  Cash = 'Cash',
  CreditCard = 'CreditCard',
  GiftCard = 'GiftCard',
}

const convertStatusApiToStatusEnum = (status?: string) => {
  switch (status) {
    case 'Late': {
      return TypeOrderEnum.OnTime
    }
    default:
      return TypeOrderEnum.OnTime
  }
}

interface IDataTable {
  rowNumber: number
  customer: string
  customerId: string
  totalPayment: string
  time: string
  type: TypeOrderEnum
}

const TableOrderHistory = ({
  params,
  onChangePage,
}: {
  params: IParamsListGetReportOrder
  onChangePage: (page: number, pageSize?: number) => void
}) => {
  const { t, i18n } = useTranslation()

  const { flattenDataList } = usePagination()

  const { data: dataReportOrder, isLoading } = useGetReportOrder(params)

  const reportOrder = useMemo(() => {
    const res: IResponseQueryList<IDataReportOrder[]> =
      flattenDataList(dataReportOrder)

    return (
      res ?? {
        data: [],
        total: 0,
      }
    )
  }, [dataReportOrder, params])

  const dataConvert = useMemo(() => {
    const res: IDataTable[] = reportOrder?.data?.map((item, index) => {
      return {
        customer: item?.customer,
        order: item?.reference_id,
        paymentMethod: item?.payment_method,
        status: convertStatusApiToStatusEnum(item?.order_types),
        time: DateUtil.formatDDMMYYYHhss(item?.closed_at ?? new Date()),
        totalPayment: formatMoneyDollar(
          item?.total_payment ?? 0,
          item?.currency,
        ),
        rowNumber: index + 1,
        customerId: item?.reference_id,
        type: TypeOrderEnum.Late,
      } as IDataTable
    })
    return res
  }, [reportOrder])

  const column = useMemo(() => {
    return [
      convertColumnTable<IDataTable>({
        title: '',
        key: 'rowNumber',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            valueString: record?.rowNumber.toString(),
          }),
        className: '',
        width: 80,
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.table_customer),
        key: 'customer',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            valueString: record?.customer,
          }),
      }),
      convertColumnTable<IDataTable>({
        title: 'ID',
        key: 'customerId',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            valueString: record?.customerId,
          }),
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.table_total_payment),
        key: 'totalPayment',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            valueString: record?.totalPayment ?? 0,
            prefix: '$',
          }),
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.table_date),
        key: 'time',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            valueString: record?.time,
          }),
      }),
    ]
  }, [i18n.language])
  return (
    <BaseTableReport
      columns={column}
      className="no-border"
      dataSource={dataConvert}
      pagination={{
        current: params?.page,
        pageSize: params?.page_size,
        total: reportOrder.total ?? 0,
        showSizeChanger: false,
        position: ['bottomCenter'],
        onChange: onChangePage,
      }}
      loading={isLoading}
    />
  )
}
export default TableOrderHistory

// const TypeWrapper = styled.div<{ status: TypeOrderEnum }>`
//   color: ${props => {
//     switch (props.status) {
//       case TypeOrderEnum.OnTime: {
//         return '#29CC6A'
//       }
//       case TypeOrderEnum.ToGo: {
//         return '#FEF08A'
//       }
//       case TypeOrderEnum.Late: {
//         return '#FC5555'
//       }
//     }
//   }};
//   background-color: ${props => {
//     switch (props.status) {
//       case TypeOrderEnum.OnTime: {
//         return 'rgba(41, 204, 106, 0.2)'
//       }
//       case TypeOrderEnum.ToGo: {
//         return 'rgba(254, 240, 138, 0.2)'
//       }
//       case TypeOrderEnum.Late: {
//         return 'rgba(252, 85, 85, 0.2)'
//       }
//     }
//   }};
//   padding: ${PADDING.xxxs};
//   border-radius: ${BORDER_RADIUS};
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: center;
// `
