import { BaseDropdown } from 'app/components/common/BaseDropdown'
import { BaseRow } from 'app/components/common/BaseRow'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  margin-bottom: 16px;
`

export const TitleWrapper = styled(BaseRow)`
  margin-bottom: 8px;
  .title {
    font-size: 14px;
    font-weight: 400; 
  }
`

export const ActionDropdown = styled(BaseDropdown)`
  font-size: 8px;
  font-weight: 400; 
  color: #D9D9D94D;
  text-decoration: underline;
  cursor: pointer;
  ul.ant-dropdown-menu{
    background-color: #1f1d2b;
    border: 1px solid #D9D9D94D;
    li {
      color: #FAFAFA !important;
      &:hover {
        background-color: #54585b80 !important;
      }
    }
  }
`

export const ActionDropdownMenuItem = styled.div<{ $bgColor: string; }>`
  display: flex;
  align-items: center;  
  .color-box {
    display: inline-block;
    width: 40px;
    height: 16px;
    margin-right: 8px;
    background-color: ${props => props.$bgColor};
  }
`

export const QuickColorSelectItem = styled.div<{ $bgColor: string; }>`
  margin-bottom: 8px;
  cursor: pointer;
  .color-name {
    margin-bottom: 6px;
    font-size: 12px;
    font-weight: 400; 
  } 
  .color-bar {
    height: 6px;
    border-radius: 8px;
    background-color: ${props => props.$bgColor};
  }
`