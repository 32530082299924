import { BaseMenu } from 'app/components/common/BaseMenu'
import { BaseRow } from 'app/components/common/BaseRow'
import { FONT_SIZE, FONT_WEIGHT, PADDING, colors } from 'parkway-web-common'
import styled from 'styled-components'

export const Menu = styled(BaseMenu)`
  background: transparent;
  border-right: 0;

  a {
    width: 100%;
    display: block;
  }

  .ant-menu-item,
  .ant-menu-submenu {
    font-size: ${FONT_SIZE.xs};
  }

  .ant-menu-item-icon {
    width: 1.25rem;
  }

  .ant-menu-submenu-title {
    padding-left: 0px !important;
    width: 100%;
  }

  .ant-menu-title-content {
  }
`

export const LeftPoint = styled.div<{ isSelected?: boolean }>`
  transition: 0.5s ease;
  border-top-right-radius: ${PADDING.md};
  border-bottom-right-radius: ${PADDING.md};
  height: 17px;
  width: 5px;
  margin-right: 6px;
  background: ${props =>
    props.isSelected ? colors.secondaryText : 'transparent'};
`

export const ItemMenuCustom = styled(BaseRow)<{
  isSelected: boolean
  isTopItemSelected?: boolean
  isBottomItemSelected?: boolean
}>`
  background-color: ${props =>
    props?.isSelected
      ? `var(--admin-background-primary-color)`
      : 'var(--admin-collapse-background-color)'};
  width: 100%;
  height: 100%;
  padding: ${PADDING.xxxs};
  border-radius: ${PADDING.md};
  border-top-right-radius: ${props =>
    props?.isTopItemSelected ? PADDING.md : '0px'};
  border-bottom-right-radius: ${props =>
    props?.isBottomItemSelected ? PADDING.md : '0px'};
`

export const ItemMenuCustomWrapper = styled(BaseRow)<{
  isSelected: boolean
  isTopItemSelected?: boolean
  isBottomItemSelected?: boolean
}>`
  background-color: var(--admin-collapse-background-color);
  width: 100%;
  height: 100%;
  padding-left: 5px;
  border-top-right-radius: ${props =>
    props?.isTopItemSelected ? PADDING.md : '0px'};
  border-bottom-right-radius: ${props =>
    props?.isBottomItemSelected ? PADDING.md : '0px'};
`

export const LabelCustom = styled.div<{
  isSelected: boolean
  isSubMenu?: boolean
}>`
  font-weight: ${props =>
    props.isSelected ? FONT_WEIGHT.bold : FONT_WEIGHT.medium};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${PADDING.xxs};
  background-color: ${props =>
    props.isSelected
      ? 'var(--admin-icon-menu-background-color)'
      : 'transparent'};
  padding: ${PADDING.xxxs};
`
