/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IParamsUpdateStatus,
  IPropsTableProductManagement,
} from 'app/api/product-management/model'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseSwitch } from 'app/components/common/BaseSwitch'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { BORDER_RADIUS_2 } from 'app/constant'
import { useUpdateModifierStatusById } from 'app/react-query/hook/product-management'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export interface IDataTableModifier {
  id: string
  name: string
  location: string[]
  option: string[]
  status: number
  items?: string[]
}

const TableComp = ({
  data,
  onChangePage,
  total,
  loadingList,
}: IPropsTableProductManagement<IDataTableModifier>) => {
  const {
    mutateAsync: mutateAsyncUpdateModifierStatusById,
    isLoading: loading,
  } = useUpdateModifierStatusById()

  const { t, i18n } = useTranslation()

  const updateModifier = (id: string, status: number) => {
    const params: IParamsUpdateStatus = {
      id,
      status: !!status,
    }

    mutateAsyncUpdateModifierStatusById(params)
  }

  const column = useMemo(() => {
    return [
      convertColumnTable<IDataTableModifier>({
        title: t(R.strings.table_up_sell_item),
        classNameOverwrite: 'big-column',
        key: 'name',
        render: (_, record) => {
          return (
            <BaseRow gutter={[15, 10]} align={'middle'} wrap={false}>
              <BaseCol>
                <ImageProduct
                  src={
                    'https://storage.googleapis.com/kiosify-01/images/img_bg_login.png'
                  }
                  width={35}
                  height={35}
                  preview={false}
                />
              </BaseCol>
              <TextItemName>{record?.name}</TextItemName>
            </BaseRow>
          )
        },
      }),
      convertColumnTable<IDataTableModifier>({
        title: t(R.strings.table_location),
        key: 'location',
        classNameOverwrite: 'big-column',
        render: (_, record) => {
          return (
            <BaseSpace direction="vertical">
              {record?.location?.map(item => {
                return <BaseCol>{item}</BaseCol>
              })}
            </BaseSpace>
          )
        },
      }),
      convertColumnTable<IDataTableModifier>({
        title: t(R.strings.table_options),
        key: 'option',
        classNameOverwrite: 'very-big-column',
        render: (_, record) => {
          return (
            <BaseSpace direction="vertical">
              {record?.option?.map(item => {
                return <BaseCol>{item}</BaseCol>
              })}
            </BaseSpace>
          )
        },
      }),
      convertColumnTable<IDataTableModifier>({
        title: t(R.strings.item),
        key: 'items',
        render: (_, record) => {
          return (
            <BaseSpace direction="vertical">
              {record?.items?.map(item => {
                return <BaseCol>{item}</BaseCol>
              })}
            </BaseSpace>
          )
        },
      }),
      convertColumnTable<IDataTableModifier>({
        title: t(R.strings.table_status),
        key: 'status',
        render: (_, record) => {
          return (
            <BaseRow
              gutter={[20, 20]}
              justify={'space-between'}
              align={'middle'}
            >
              <BaseCol>
                <SwitchButton
                  checked={!!record?.status}
                  onChange={checked => {
                    updateModifier(record?.id?.toString(), checked ? 1 : 0)
                  }}
                />
              </BaseCol>
            </BaseRow>
          )
        },
      }),
    ]
  }, [i18n.language])

  return (
    <Table
      columns={column}
      className="row-overwrite-style"
      dataSource={data}
      pagination={{
        current: 1,
        pageSize: 10,
        total: total ?? 0,
        showSizeChanger: false,
        position: ['bottomCenter'],
        onChange: onChangePage,
      }}
      scroll={{ x: true }}
      loading={loadingList || loading}
    />
  )
}
export default TableComp

export const Table = styled(BaseTableReport)``

export const SwitchButton = styled(BaseSwitch)`
  &.ant-switch.ant-switch-checked {
    background: rgba(41, 204, 106, 1); // need change
  }

  &.ant-switch.ant-switch-checked:hover {
    background: rgba(41, 204, 106, 1); // need change
  }
`

export const ImageProduct = styled(BaseImage)`
  border-radius: ${BORDER_RADIUS_2};
`

export const TextItemName = styled(BaseCol)`
  color: var(--background-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.medium};
`
