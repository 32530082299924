export const handleDownloadFile = async ({
  fileUrl,
  fileName,
}: {
  fileUrl: string
  fileName: string
}) => {
  try {
    const response = await fetch(fileUrl)
    const blob = await response.blob()
    const url = window.URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  } catch (error) {
    console.error('Error downloading file:', error)
  }
}

export const getImageBase64URL = (file): Promise<string | null> => {
  if (!file) return Promise.resolve(null)

  return new Promise((resolve) => {
    const reader = new FileReader()

    reader.onload = () => {
      const base64Image = reader.result as string
      resolve(base64Image)
    };

    reader.onerror = () => resolve(null)

    reader.readAsDataURL(file)
  });
}
