import {
  IParamsUpdateStatus,
  IPropsTableProductManagement,
} from 'app/api/product-management/model'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseSwitch } from 'app/components/common/BaseSwitch'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import {
  RenderValueTableReport,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import { BORDER_RADIUS_2 } from 'app/constant'
import { formatMoneyDollar } from 'app/hook'
import { useUpdateItemStatusById } from 'app/react-query/hook/product-management'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export interface IDataTableItem {
  id: number | string
  name: string
  category: string
  location: string[]
  variant: string[]
  price: number[]
  status: number
  image?: string
  currency?: string
}

const ItemsTabTableSetting = ({
  data,
  total,
  onChangePage,
  loadingList,
  params,
}: IPropsTableProductManagement<IDataTableItem>) => {
  const { t, i18n } = useTranslation()
  const { mutateAsync: mutateAsyncUpdateItemStatusById, isLoading: loading } =
    useUpdateItemStatusById()

  const updateItem = (id: string, status: number) => {
    const params: IParamsUpdateStatus = {
      id,
      status: !!status,
    }

    mutateAsyncUpdateItemStatusById(params)
  }

  const column = useMemo(() => {
    return [
      convertColumnTable<IDataTableItem>({
        title: t(R.strings.table_item_name),
        classNameOverwrite: 'big-column',
        key: 'name',
        render: (_, record) => {
          return (
            <BaseRow gutter={[15, 10]} align={'middle'} wrap={false}>
              <BaseCol>
                <ImageProduct
                  src={
                    record?.image ??
                    'https://storage.googleapis.com/kiosify-01/images/ic_logo_kiosify.png'
                  }
                  width={35}
                  height={35}
                  preview={false}
                />
              </BaseCol>
              <TextItemName>{record?.name}</TextItemName>
            </BaseRow>
          )
        },
      }),
      convertColumnTable<IDataTableItem>({
        title: t(R.strings.table_category),
        key: 'category',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            valueString: record?.category,
          }),
      }),

      convertColumnTable<IDataTableItem>({
        title: t(R.strings.table_variation),
        key: 'variant',
        classNameOverwrite: 'very-big-column',
        render: (_, record) => {
          return (
            <BaseSpace direction="vertical">
              {record?.variant?.map(item => {
                return <BaseCol>{item}</BaseCol>
              })}
            </BaseSpace>
          )
        },
      }),
      convertColumnTable<IDataTableItem>({
        title: t(R.strings.table_location),
        key: 'location',
        render: (_, record) => {
          return (
            <BaseSpace direction="vertical">
              {record?.location?.map(item => {
                return <BaseCol>{item}</BaseCol>
              })}
            </BaseSpace>
          )
        },
      }),
      convertColumnTable<IDataTableItem>({
        title: t(R.strings.table_price),
        key: 'price',
        classNameOverwrite: 'very-big-column',
        render: (_, record) => {
          return (
            <BaseRow
              gutter={[20, 20]}
              justify={'space-between'}
              align={'middle'}
            >
              <BaseSpace direction="vertical">
                {record?.price?.length ? (
                  record?.price?.map(item => {
                    return (
                      <BaseCol>
                        {formatMoneyDollar(item ?? 0, record?.currency)}
                      </BaseCol>
                    )
                  })
                ) : (
                  <BaseCol>{formatMoneyDollar(0, record?.currency)}</BaseCol>
                )}
              </BaseSpace>

              <BaseRow
                gutter={[20, 20]}
                justify={'space-between'}
                align={'middle'}
              >
                <BaseCol>
                  <SwitchButton
                    checked={!!record?.status}
                    onChange={checked => {
                      updateItem(record?.id?.toString(), checked ? 1 : 0)
                    }}
                  />
                </BaseCol>
              </BaseRow>
            </BaseRow>
          )
        },
      }),
    ]
  }, [i18n.language])

  return (
    <Table
      columns={column}
      className="row-overwrite-style"
      dataSource={data}
      pagination={{
        current: params?.page,
        pageSize: 10,
        total: total ?? 0,
        showSizeChanger: false,
        position: ['bottomCenter'],
        onChange: onChangePage,
      }}
      scroll={{ x: true }}
      loading={loadingList || loading}
    />
  )
}
export default ItemsTabTableSetting

export const Table = styled(BaseTableReport)`
  width: 100%;
`

export const SwitchButton = styled(BaseSwitch)`
  &.ant-switch.ant-switch-checked {
    background: rgba(41, 204, 106, 1); // need change
  }

  &.ant-switch.ant-switch-checked:hover {
    background: rgba(41, 204, 106, 1); // need change
  }
`

export const ImageProduct = styled(BaseImage)`
  border-radius: ${BORDER_RADIUS_2};
`

export const TextItemName = styled(BaseCol)`
  color: var(--background-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.medium};
`
