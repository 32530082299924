import { BaseButton } from 'app/components/common/BaseButton'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { SearchInput } from 'app/components/common/inputs/SearchInput'
import { BORDER_RADIUS_2 } from 'app/constant'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseSpace)`
  padding: ${PADDING.xxxxs};
  width: 100%;
`

export const HeaderWrapper = styled(BaseRow)`
  padding: 0px;
`

export const BackButton = styled(BaseButton)`
  background-color: rgba(255, 255, 255, 0.1); // need change
  border-radius: ${BORDER_RADIUS};
  border-color: transparent;
  padding: ${PADDING.md};
  transition: all 1s ease;

  & svg {
    fill: var(--background-color);
  }

  &:hover {
    border-color: var(--background-color) !important;
    transition: all 1s ease;
  }
`

export const ButtonSeeYourRestaurant = styled(BaseButton)`
  background-color: var(--admin-text-focus-setting-menu-color);
  color: var(--background-color);
  font-weight: ${FONT_WEIGHT.medium};
  border-color: transparent;
  transition: all 1s ease;
  padding: ${PADDING.xxl} ${PADDING.xs};

  &:hover {
    border-color: var(--background-color) !important;
    color: var(--background-color) !important;
    transition: all 1s ease;
  }
`

export const TextEx = styled.div`
  color: rgba(217, 217, 217, 0.3); // need change
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
`

// UpSell
export const UpSellWrapper = styled(BaseSpace)`
  width: 100%;
`

export const SearchWrapper = styled(BaseSpace)`
  width: 100%;
  .title {
    color: var(--background-color);
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
  }
`

export const SearchLayout = styled(SearchInput)`
  .ant-input-group-addon {
    display: none;
  }
  .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: ${BORDER_RADIUS};
    border: 0;
    background: transparent !important;
    color: var(--background-color);
    border: 0.7px solid rgba(45, 48, 62, 1);
  }
  input {
    background: transparent !important;
    color: var(--background-color);
  }
`

export const ImageItem = styled(BaseImage)`
  border-radius: ${BORDER_RADIUS_2};
`

export const UpSellButtonAddMore = styled(BaseButton)`
  background-color: rgba(255, 255, 255, 0.1); // need change
  border-color: rgba(255, 255, 255, 0.1); // need change
  width: 100%;
  margin: 0px;
  height: 40px;
  .text {
    color: #29cc6a;
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
  }
  svg {
    fill: #29cc6a;
  }
`

export const UpSellItem = styled(BaseRow)`
  padding: ${PADDING.xxs};
  border-radius: ${BORDER_RADIUS};
  margin: 0px !important;
  background-color: rgba(255, 255, 255, 0.1); // need change
  .label {
    color: var(--background-color);
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
  }
  .svg {
    svg {
      fill: var(--background-color);
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
`
