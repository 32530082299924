import { EyeOutlined } from '@ant-design/icons'
import { ICategory } from 'app/api/product-management/model'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import * as S from './styles'
import { useTranslation } from 'react-i18next'

interface Props {
  item: ICategory
  onClickItem?: (item: ICategory) => void
}

export const ItemCategory = ({ item, onClickItem }: Props) => {
  const { t } = useTranslation()
  const onClick = () => {
    onClickItem?.(item)
  }
  return (
    <S.ItemCategoryWrapper direction="vertical" size={15}>
      <S.ItemCategoryContentWrapper direction="vertical" size={15}>
        <S.ImageItemCategory
          src={
            item?.image_upload ??
            'https://storage.googleapis.com/kiosify-01/images/ic_logo_kiosify.png'
          }
          width={110}
          height={110}
          preview={false}
        />
        <S.TextNameItemCategory>{item?.name}</S.TextNameItemCategory>
      </S.ItemCategoryContentWrapper>
      <S.ButtonViewMore onClick={onClick}>
        <BaseRow gutter={[5, 5]} align={'middle'}>
          <BaseCol>
            <EyeOutlined rev={undefined} />
          </BaseCol>
          <S.TextButtonViewMore>{t(R.strings.see_more)}</S.TextButtonViewMore>
        </BaseRow>
      </S.ButtonViewMore>
    </S.ItemCategoryWrapper>
  )
}
