import { BoxPlotFilled } from '@ant-design/icons'
import {
  ICategory,
  IItemProductManagement,
  IParamsUpdateStatus,
  IResponseQueryList,
  ParamsListDefault,
} from 'app/api/product-management/model'
import R from 'app/assets/R'
import BaseBackButton from 'app/components/common/BaseBackButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseSwitch } from 'app/components/common/BaseSwitch'
import UploadImage from 'app/components/common/BaseUpload/components/UploadImage'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { BORDER_RADIUS_2 } from 'app/constant'
import { URL_SETTING } from 'app/containers/admin/Settings/constant'
import { formatMoneyDollar, usePagination } from 'app/hook'
import {
  useGetItemList,
  useUpdateItemStatusById,
  useUploadImageCategory,
} from 'app/react-query/hook/product-management'
import { useAppDispatch } from 'app/redux/hooks'
import {
  setPathSetting,
  setProductInProductManagement,
} from 'app/redux/slices/pathSettingSlice'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import * as S from './styles'

export interface IDataTableItem {
  id: number | string
  name: string
  category: string
  location: string[]
  variant: string[]
  price: number[]
  status: number
  image?: string
  currency?: string
}

interface IProps {
  category: ICategory | null
  onGoBack: () => void
}

const ProductTabTableSetting = ({ category, onGoBack }: IProps) => {
  const { t, i18n } = useTranslation()
  if (!category) return null

  const dispatch = useAppDispatch()

  const [params, setParams] = useState(ParamsListDefault)

  const { flattenDataList } = usePagination()

  const { data, isLoading: loading } = useGetItemList({
    ...params,
    category_id: category?.id,
  })

  const {
    mutateAsync: mutateAsyncUpdateItemStatusById,
    isLoading: loadingUpdate,
  } = useUpdateItemStatusById()
  const { mutate: mutateUploadImage, isLoading: loadingUploadImage } =
    useUploadImageCategory()

  const updateItem = (id: string, status: number) => {
    const params: IParamsUpdateStatus = {
      id,
      status: !!status,
    }

    mutateAsyncUpdateItemStatusById(params)
  }

  const onChangePage = (page: number, pageSize?: number) => {
    setParams({
      ...params,
      page,
      page_size: pageSize ?? 10,
    })
  }

  const itemProductManagementData = useMemo(() => {
    const itemProductManagement: IResponseQueryList<IItemProductManagement[]> =
      flattenDataList(data)
    return itemProductManagement
  }, [data, params])

  const dataTable: IDataTableItem[] = useMemo(() => {
    const itemProductManagements = itemProductManagementData?.data ?? []
    return itemProductManagements.map(item => {
      return {
        id: item?.item_id ?? '-',
        name: item?.name ?? '-',
        category: item?.category ?? '-',
        location: item?.present_at_location_names ?? '-',
        variant: item?.variations ?? '-',
        price: item?.price_list ?? 0,
        status: item.status ? 1 : 0,
        image: item?.image,
        currency: item?.currency_list?.[0],
      } as IDataTableItem
    })
  }, [itemProductManagementData])

  const totalItem = useMemo(() => {
    return itemProductManagementData?.total ?? 0
  }, [itemProductManagementData])

  const onClickItem = (record: IDataTableItem) => {
    const itemProductManagements = itemProductManagementData?.data ?? []
    const item = itemProductManagements.find(itm => itm.item_id === record?.id)

    if (!item) return
    dispatch(setProductInProductManagement(item))
    dispatch(setPathSetting(URL_SETTING.ProductsDetail))
  }

  const column = useMemo(() => {
    return [
      convertColumnTable<IDataTableItem>({
        title: t(R.strings.table_product_name),
        classNameOverwrite: 'big-column',
        key: 'name',
        render: (_, record) => {
          return (
            <ItemName
              gutter={[15, 10]}
              align={'middle'}
              wrap={false}
              onClick={() => onClickItem(record)}
            >
              <BaseCol>
                <ImageProduct
                  src={
                    record?.image ??
                    'https://storage.googleapis.com/kiosify-01/images/ic_logo_kiosify.png'
                  }
                  width={35}
                  height={35}
                  preview={false}
                />
              </BaseCol>
              <TextItemName>{record?.name}</TextItemName>
            </ItemName>
          )
        },
      }),
      convertColumnTable<IDataTableItem>({
        title: t(R.strings.table_price),
        key: 'price',
        classNameOverwrite: 'big-column',
        render: (_, record) => {
          return (
            <BaseRow
              gutter={[20, 20]}
              justify={'space-between'}
              align={'middle'}
            >
              <BaseSpace direction="vertical">
                {record?.price?.length ? (
                  record?.price?.map(item => {
                    return (
                      <BaseCol>
                        {formatMoneyDollar(item ?? 0, record?.currency)}
                      </BaseCol>
                    )
                  })
                ) : (
                  <BaseCol>{formatMoneyDollar(0, record?.currency)}</BaseCol>
                )}
              </BaseSpace>
            </BaseRow>
          )
        },
      }),
      convertColumnTable<IDataTableItem>({
        title: t(R.strings.table_status),
        key: 'status',
        render: (_, record) => {
          return (
            <BaseRow
              gutter={[20, 20]}
              justify={'space-between'}
              align={'middle'}
            >
              <BaseCol>
                <SwitchButton
                  checked={!!record?.status}
                  onChange={checked => {
                    updateItem(record?.id?.toString(), checked ? 1 : 0)
                  }}
                />
              </BaseCol>
            </BaseRow>
          )
        },
      }),
    ]
  }, [itemProductManagementData, i18n.language])

  const handleChangeImage = (imageFile: File) => {
    if (!imageFile) return

    const body = new FormData()
    body.append('id', category?.id ?? '')
    body.append('image', imageFile)

    mutateUploadImage(body)
  }

  return (
    <TableWrapper direction="vertical" size={10}>
      <BaseBackButton goBack={onGoBack} />
      <BaseRow justify={'end'}>
        <S.TotalItemLayout gutter={[10, 10]} align={'middle'}>
          <BaseCol>
            <BoxPlotFilled rev={undefined} />
          </BaseCol>
          <BaseCol>
            <BaseCol className="text">{`${totalItem} ${t(
              R.strings.item,
            ).toLowerCase()}`}</BaseCol>
          </BaseCol>
        </S.TotalItemLayout>
      </BaseRow>

      <S.InfoCategoryWrapper align={'middle'} gutter={[30, 10]}>
        <BaseCol>
          <UploadImage
            src={
              category?.image_upload ??
              'https://storage.googleapis.com/kiosify-01/images/ic_logo_kiosify.png'
            }
            isLoading={loadingUploadImage}
            onChange={handleChangeImage}
            circleLayout
            width="85px"
            height="85px"
          />
        </BaseCol>
        <BaseCol xl={15}>
          <BaseSpace direction="vertical" size={10}>
            <S.TitleCategory>{category?.name}</S.TitleCategory>
            <S.DescriptionCategory>
              {category?.description ?? '-'}
            </S.DescriptionCategory>
          </BaseSpace>
        </BaseCol>
      </S.InfoCategoryWrapper>
      <Table
        columns={column}
        className="row-overwrite-style"
        dataSource={dataTable}
        pagination={{
          current: 1,
          pageSize: 10,
          total: totalItem ?? 0,
          showSizeChanger: false,
          position: ['bottomCenter'],
          onChange: onChangePage,
        }}
        loading={loading || loadingUpdate}
      />
    </TableWrapper>
  )
}
export default ProductTabTableSetting

export const Table = styled(BaseTableReport)`
  width: 100%;
`

export const TableWrapper = styled(BaseSpace)`
  width: 100%;
  padding: 0px ${PADDING.xxxs};
`

export const SwitchButton = styled(BaseSwitch)`
  &.ant-switch.ant-switch-checked {
    background: rgba(41, 204, 106, 1); // need change
  }

  &.ant-switch.ant-switch-checked:hover {
    background: rgba(41, 204, 106, 1); // need change
  }
`

export const ImageProduct = styled(BaseImage)`
  border-radius: ${BORDER_RADIUS_2};
`

export const TextItemName = styled(BaseCol)`
  color: var(--background-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.medium};
`

export const ItemName = styled(BaseRow)`
  cursor: pointer;
`
