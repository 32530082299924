import { ApiClient } from 'app/service/api-service'
import { BackupApiEndpoint } from './constant'
import { IParamsBackupList } from './model'

export const requestGetBackup = (params?: IParamsBackupList) =>
  ApiClient.Get({
    url: BackupApiEndpoint.GET_BACKUP_LIST,
    params: { params },
  })

export const requestRevertBackup = (id: string) =>
  ApiClient.Post({
    url: BackupApiEndpoint.REVERT_BACKUP,
    body: { id },
  })

export const requestSyncAllType = () =>
  ApiClient.Post({
    url: BackupApiEndpoint.SYNC_ALL_TYPE,
    body: {},
  })
