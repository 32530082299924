import { ApiClient } from 'app/service/api-service'
import { KioskKitchenApiEndpoint } from './constant'
import { IKioskKitchen } from './model'

export const requestGetKioskKitchen = () =>
  ApiClient.Get({
    url: KioskKitchenApiEndpoint.GET_KIOSK_KITCHEN,
    params: {},
  })

export const requestUpdateKioskKitchen = (body: IKioskKitchen) =>
  ApiClient.Post({ url: KioskKitchenApiEndpoint.UPDATE_BY_KIOSK_KITCHEN, body })
