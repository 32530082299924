/* eslint-disable @typescript-eslint/no-explicit-any */
import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IProps {
  locationsName?: string[]
}

export const KEY_ALL = 'All'

function SelectionLocation({ locationsName }: IProps) {
  const { t } = useTranslation()
  console.log({ locationsName })

  return (
    <BaseSpaceInUpSell direction="vertical" size={10}>
      <Title>{t(R.strings.table_location)}</Title>
      {locationsName?.map((item, index) => {
        return <OptionWrapper key={index}>{item}</OptionWrapper>
      })}
    </BaseSpaceInUpSell>
  )
}

export default SelectionLocation

const Title = styled.div`
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.medium};
  color: var(--background-color);
`

const BaseSpaceInUpSell = styled(BaseSpace)`
  width: 100%;
`

const OptionWrapper = styled.div`
  background-color: #2d303e;
  color: var(--background-color);
  border: 0.7px solid rgba(45, 48, 62, 1);
  padding: ${PADDING.xxxs};
  border-radius: ${BORDER_RADIUS};
`
