export const ProductManagementApiEndpoint = {
  GET_CATEGORY_LIST: '/catalog/category/get-category/',
  GET_ITEM_LIST: '/catalog/item/get-item/',
  GET_MODIFIER_LIST: '/catalog/modifier-list/get-modifiers/',
  GET_TAX_LIST: '/catalog/tax/get-taxs/',
  GET_DISCOUNT_LIST: '/catalog/discount/get-discounts/',
  GET_UPSELL_LIST: '/catalog/upsell/get-upsell/',
  GET_UPSELL_LIST_BY_ITEM: '/catalog/upsell/get-list-upsell-by-item/',

  DISCOUNT: '/catalog/discount/',
  TAX: '/catalog/tax/',
  MODIFIER: '/catalog/modifier-list/',
  ITEM: '/catalog/item/',
  CATEGORY: '/catalog/category/',
  UPSELL: '/catalog/upsell/',
  UPLOAD_IMAGE_CATEGORY: '/catalog/category/image/',
  GET_ITEM_VARIATION: '/get-item-variations/',
}
