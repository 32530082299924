/* eslint-disable @typescript-eslint/no-explicit-any */
import { IBackup, IBodySyncById } from 'app/api/backup/model'
import {
  IResponseQueryList,
  ParamsListDefault,
} from 'app/api/product-management/model'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import {
  RenderValueTableReport,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import * as SR from 'app/containers/admin/Settings/components/RightSide/styles'
import { usePagination } from 'app/hook'
import {
  useGetBackupList,
  useSyncRevertBackupById,
} from 'app/react-query/hook/backup'
import { DateUtil, FORMAT_CENTER_YYYY_MM_DD, moment } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BoxTotalItem from '../../components/BoxTotalItem'
import SelectionRestaurant from '../../components/SelectionLocation'
import * as S from './styles'

const BackupLayout = () => {
  const { t, i18n } = useTranslation()

  const [params, setParams] = useState({
    ...ParamsListDefault,
    location: 'ALL',
  })

  const { flattenDataList } = usePagination()

  const { data, isLoading: loading } = useGetBackupList(params)

  const {
    mutateAsync: mutateAsyncSyncRevertBackupById,
    isLoading: loadingUpdate,
  } = useSyncRevertBackupById()

  const syncData = (id_version?: string) => {
    const params: IBodySyncById = {
      id_version,
    }

    mutateAsyncSyncRevertBackupById(params)
  }

  const onChangePage = (page: number, pageSize?: number) => {
    setParams({
      ...params,
      page,
      page_size: pageSize ?? 10,
    })
  }

  const itemProductManagementData = useMemo(() => {
    console.log({ params })

    const itemProductManagement: IResponseQueryList<IBackup[]> =
      flattenDataList(data)
    return itemProductManagement
  }, [data, params])

  const dataTable: IBackup[] = useMemo(() => {
    const itemProductManagements = itemProductManagementData?.data ?? []
    return itemProductManagements.map(item => {
      return {
        created_at: item?.created_at ?? '-',
        id_version: item?.id ?? '-',
        location: item?.location ?? '-',
      } as IBackup
    })
  }, [itemProductManagementData])

  const totalItem = useMemo(() => {
    return itemProductManagementData?.total ?? 0
  }, [itemProductManagementData])

  const onChangeLocation = (value: string) => {
    setParams({
      ...params,
      location: value,
    })
  }

  const onChangeDate = (_: any, dateString: moment.MomentInput[]) => {
    const startDate = moment(
      dateString[0],
      FORMAT_CENTER_YYYY_MM_DD,
    ).toISOString()
    const endDate = moment(
      dateString[1],
      FORMAT_CENTER_YYYY_MM_DD,
    ).toISOString()

    setParams({
      ...params,
      start_date: startDate,
      end_date: endDate,
    })
  }

  const column = useMemo(() => {
    return [
      convertColumnTable<IBackup>({
        title: t(R.strings.table_time),
        classNameOverwrite: 'very-big-column',
        key: 'created_at',
        render: (_, record) => {
          return (
            <RenderValueTableReport
              valueString={DateUtil.formatDDMMYYYHhssmm(
                record?.created_at ?? '',
              )}
              record={record}
            />
          )
        },
      }),
      convertColumnTable<IBackup>({
        title: t(R.strings.table_location),
        key: 'location',
        classNameOverwrite: 'big-column',
        render: (_, record) => {
          return (
            <RenderValueTableReport
              valueString={record?.location ?? ''}
              record={record}
            />
          )
        },
      }),
      convertColumnTable<IBackup>({
        title: ' ',
        key: 'status',
        classNameOverwrite: 'big-column',
        render: (_, record) => {
          return (
            <BaseRow align={'middle'} justify={'center'}>
              <S.SyncIcon
                rev={undefined}
                onClick={() => syncData(record?.id)}
                $color={record?.is_sync ? '#29CC6A' : undefined}
              />
            </BaseRow>
          )
        },
      }),
    ]
  }, [i18n.language, data])

  return (
    <S.RootWrapper direction="vertical" size={10}>
      <SR.TitleSide>{t(R.strings.setting_menu_backup_t)}</SR.TitleSide>
      <BoxTotalItem total={totalItem} />

      <S.FilterWrapper gutter={[20, 15]} align={'middle'}>
        <BaseCol>
          <S.BaseRangePicker onChange={onChangeDate} />
        </BaseCol>
        <BaseCol>
          <SelectionRestaurant
            onChange={onChangeLocation}
            value={params?.location_id}
            keyAllValue={'ALL'}
          />
        </BaseCol>
      </S.FilterWrapper>

      <BaseTableReport
        columns={column}
        className="row-overwrite-style"
        dataSource={dataTable}
        pagination={{
          current: params.page,
          pageSize: params?.page_size,
          total: totalItem ?? 0,
          showSizeChanger: false,
          position: ['bottomCenter'],
          onChange: onChangePage,
        }}
        loading={loading || loadingUpdate}
      />
    </S.RootWrapper>
  )
}

export default BackupLayout
