import {
  IParamsUpdateStatus,
  IPropsTableProductManagement,
} from 'app/api/product-management/model'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseSwitch } from 'app/components/common/BaseSwitch'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import {
  RenderValueTableReport,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import { BORDER_RADIUS_2 } from 'app/constant'
import { useUpdateDiscountStatusById } from 'app/react-query/hook/product-management'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export interface IDataTableDiscount {
  id: number | string
  discountName: string
  location: string[]
  status: number
  discountType?: string
  price?: number
  discountApply?: string
}

const TableComp = ({
  data,
  onChangePage,
  total,
  loadingList,
}: IPropsTableProductManagement<IDataTableDiscount>) => {
  const {
    mutateAsync: mutateAsyncUpdateDiscountStatusById,
    isLoading: loading,
  } = useUpdateDiscountStatusById()

  const { t, i18n } = useTranslation()

  const updateDiscount = (id: string, status: number) => {
    const params: IParamsUpdateStatus = {
      id,
      status: !!status,
    }

    mutateAsyncUpdateDiscountStatusById(params)
  }

  const column = useMemo(() => {
    return [
      convertColumnTable<IDataTableDiscount>({
        title: t(R.strings.table_discount_name),
        classNameOverwrite: 'big-column',
        key: 'discountName',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            valueString: record?.discountName,
          }),
      }),
      convertColumnTable<IDataTableDiscount>({
        title: t(R.strings.table_location),
        key: 'location',
        classNameOverwrite: 'big-column',
        render: (_, record) => {
          return (
            <BaseSpace direction="vertical">
              {record?.location?.map(item => {
                return <BaseCol>{item}</BaseCol>
              })}
            </BaseSpace>
          )
        },
      }),
      convertColumnTable<IDataTableDiscount>({
        title: t(R.strings.table_discount_type),
        key: 'discountType',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            valueString: record?.discountType,
          }),
      }),
      convertColumnTable<IDataTableDiscount>({
        title: t(R.strings.table_price),
        key: 'price',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            childValue: record?.price,
            prefix: '$',
          }),
      }),
      convertColumnTable<IDataTableDiscount>({
        title: t(R.strings.table_discount_apply),
        key: 'discountApply',
        classNameOverwrite: 'very-big-column',
        render: (_, record) => {
          return (
            <BaseRow
              gutter={[20, 20]}
              justify={'space-between'}
              align={'middle'}
            >
              <BaseCol>{record?.discountApply}</BaseCol>
              <BaseRow
                gutter={[10, 10]}
                justify={'space-between'}
                align={'middle'}
              >
                <BaseCol>
                  <SwitchButton
                    checked={!!record?.status}
                    onChange={checked => {
                      updateDiscount(record?.id?.toString(), checked ? 1 : 0)
                    }}
                  />
                </BaseCol>
              </BaseRow>
            </BaseRow>
          )
        },
      }),
    ]
  }, [i18n.language])
  return (
    <Table
      columns={column}
      className="row-overwrite-style"
      dataSource={data}
      pagination={{
        current: 1,
        pageSize: 10,
        total,
        showSizeChanger: false,
        position: ['bottomCenter'],
        onChange: onChangePage,
      }}
      scroll={{ x: true }}
      loading={loadingList || loading}
    />
  )
}
export default TableComp

export const Table = styled(BaseTableReport)``

export const SwitchButton = styled(BaseSwitch)`
  &.ant-switch.ant-switch-checked {
    background: rgba(41, 204, 106, 1); // need change
  }

  &.ant-switch.ant-switch-checked:hover {
    background: rgba(41, 204, 106, 1); // need change
  }
`

export const ImageProduct = styled(BaseImage)`
  border-radius: ${BORDER_RADIUS_2};
`

export const TextItemName = styled(BaseCol)`
  color: var(--background-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.medium};
`
