import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
`

export const TitleWrapper = styled(BaseRow)`
  margin-bottom: 8px;
  .title {
    font-size: 14px;
    font-weight: 400; 
  }
`

export const CustomTargetSelect = styled(BaseSelect)`
  border-bottom: 1px solid #D9D9D94D;
  margin-bottom: 16px;
  min-width: 120px !important;
  & .ant-select-selector
  {
    background-color: transparent !important;
    color: #FFFFFF;
    border: unset !important;
    box-shadow: unset !important;
  }
  & .ant-select-arrow {
    top: 50% !important;
  }
  & .ant-select-selection-item{
    color: #D9D9D94D !important;
  }
  & .ant-select-dropdown {
    background-color: #1f1d2b;
    color: #FFFFFF;
    border: 1px solid #D9D9D94D;
    & .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #D9D9D94D !important;
    }
    & .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-content{
      color: #FFFFFF !important;
    }
  }
  & .ant-select-item-option-content{
    color: #FFFFFF;
  }
  & .ant-select-item-option:not(.ant-select-item-option-selected):hover {
    background-color: #d9d9d926 !important;
  }
`

export const ColorCircleWrapper = styled(BaseRow)``

export const ColorCircleItem = styled(BaseCol) <{ $bgColor: string; }>`
  .color-cicle{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${props => props.$bgColor};
    cursor: pointer;
    &.active {
      box-shadow: 0px 0px 10px 0px #14FF00;
    }
  }
`

export const ColorCircleGradientItem = styled(BaseCol)`
  .gradient-area{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;
    background: linear-gradient(135deg, #FF0808 0%, #047941 52.6%, #CD05FF 100%);
    .white-area{
      width: 80%;
      height: 80%;
      border-radius: 50%;
      background-color: #FFFFFF;
    }
  }
`