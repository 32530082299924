/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetKioskKitchen, requestUpdateKioskKitchen } from 'app/api/kiosk-kitchen/api'
import {
  IKioskKitchen,
  ResponseKioskKitchen,
} from 'app/api/kiosk-kitchen/model'
import { useMutation, useQuery } from 'react-query'
import { KioskKitchenKeys } from '../query-key/kiosk-kitchen'
import { useNavigate } from 'react-router'
import { LOGOUT_PATH } from 'app/components/router/route-path'

export function useGetKioskKitchen(enabled = true) {
  const navigate = useNavigate()
  return useQuery<IKioskKitchen, undefined>(
    KioskKitchenKeys.kioskKitchenInfo(),
    async () => {
      try {
        const response: ResponseKioskKitchen = await requestGetKioskKitchen()
        return response?.data
      } catch (error: any) {
        console.log({ error }, 'kiosk-kitchen.ts')
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
        }
        return {}
      }
    },
    {
      enabled,
      keepPreviousData: true
    },
  )
}

export const useUpdateKioskKitchen = () => {
  const navigate = useNavigate()
  return useMutation(
    async (body: IKioskKitchen) => {
      try {
        console.log('body', body)
        const res = await requestUpdateKioskKitchen(body)
        return res
      } catch (error: any) {
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
        }
      }
    },
  )
}