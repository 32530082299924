/* eslint-disable @typescript-eslint/no-explicit-any */
import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { BORDER_RADIUS, FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IProps {
  onChange?: (value: string) => void
  value?: string
  placeholder?: string
  isDisabled?: boolean
  currency?: string
}

const InputPriceComponent = ({
  onChange,
  value = '0',
  placeholder,
  isDisabled,
  currency = '$',
}: IProps) => {
  const { t } = useTranslation()

  const handleSearch = (value: any) => {
    onChange && onChange(value)
  }

  const prefix = useMemo(() => {
    switch (currency) {
      case 'USD':
        return 'Cent (USD)'
      default:
        return currency
    }
  }, [currency])

  return (
    <BaseSpaceInUpSell direction="vertical" size={10}>
      <Title>{t(R.strings.price)}</Title>
      <InputNumberLayout
        value={value}
        prefix={prefix}
        placeholder={placeholder ?? t(R.strings.price)}
        onChange={handleSearch}
        disabled={isDisabled}
        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      />
    </BaseSpaceInUpSell>
  )
}
export default InputPriceComponent

const InputNumberLayout = styled(InputNumber)`
  .ant-input-group-addon {
    display: none;
  }
  &.ant-input-number-affix-wrapper {
    border-radius: ${BORDER_RADIUS};
    background: #2d303e !important;
    color: var(--background-color);
    border: 0.8px solid rgba(45, 48, 62, 1);
    width: 100%;

    .ant-input-number {
      background: #2d303e !important;
      .ant-input-number-input-wrap > input {
        color: var(--background-color);
        background: transparent !important;
      }

      .ant-input-number-handler-wrap {
        background: #2d303e !important;
      }

      .ant-input-number-disabled {
        padding: 5px 0px;
      }
    }
  }
`

export const Title = styled.div`
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.medium};
  color: var(--background-color);
`

export const BaseSpaceInUpSell = styled(BaseSpace)`
  width: 100%;
`
