import { BaseLayout } from 'app/components/common/BaseLayout'
import { colors, media } from 'parkway-web-common'
import styled from 'styled-components'

export const LayoutMaster = styled(BaseLayout)`
  height: 100vh;
  background: var(--admin-background-primary-color);
`

export const LayoutMain = styled(BaseLayout)`
  background: ${colors.white};
  @media only screen and ${media.md} {
  }

  @media only screen and ${media.xl} {
    margin-left: unset;
  }
`
