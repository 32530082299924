import { PlusOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { SpaceVertical } from 'app/components/styles'
import * as SR from 'app/containers/admin/Settings/components/RightSide/styles'
import { URL_SETTING } from 'app/containers/admin/Settings/constant'
import { useAppDispatch } from 'app/redux/hooks'
import { setPathSetting } from 'app/redux/slices/pathSettingSlice'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SearchComponent from '../../components/Search'
import * as S from './styles'
import KioskTableSetting from './table'

const YourKioskLayout = () => {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')

  const dispatch = useAppDispatch()
  const navigateToAddYourKiosk = () => {
    dispatch(setPathSetting(URL_SETTING.AddYourKiosk))
  }
  return (
    <S.RootWrapper>
      <S.HeaderWrapper justify={'space-between'} align={'middle'}>
        <SR.TitleSide>{t(R.strings.setting_menu_your_kiosk_t)}</SR.TitleSide>

        <BaseCol>
          <BaseRow align={'middle'}>
            {/* <FilterButtonSetting /> */}
            <S.ButtonHeader onClick={navigateToAddYourKiosk}>
              <BaseRow gutter={[10, 10]}>
                <BaseCol>
                  <PlusOutlined rev={undefined} />
                </BaseCol>
                <BaseCol>{t(R.strings.kiosk_add_new_kiosk)}</BaseCol>
              </BaseRow>
            </S.ButtonHeader>
          </BaseRow>
        </BaseCol>
      </S.HeaderWrapper>

      <SpaceVertical $gap={16} />

      <S.TableWrapper size={20} direction="vertical">
        <BaseCol xl={7}>
          <SearchComponent
            initValue={search}
            onChange={setSearch}
            placeholder={t(R.strings.input_placeholder_search_your_kiosk)}
          />
        </BaseCol>
        <BaseCol>
          <KioskTableSetting search={search} />
        </BaseCol>
      </S.TableWrapper>
    </S.RootWrapper>
  )
}

export default memo(YourKioskLayout)
