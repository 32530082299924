import { ArrowLeftOutlined } from '@ant-design/icons'
import {
  IParamsUpdateStatus,
  IVariationProductManagement,
} from 'app/api/product-management/model'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseSwitch } from 'app/components/common/BaseSwitch'
import { Loading } from 'app/components/common/Loading'
import * as SR from 'app/containers/admin/Settings/components/RightSide/styles'
import { URL_SETTING } from 'app/containers/admin/Settings/constant'
import { formatMoneyDollar } from 'app/hook'
import { useUpdateItemStatusById } from 'app/react-query/hook/product-management'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import {
  setPathSetting,
  setProductInProductManagement,
  updateBestSellerProduct,
  updateStatusProduct,
} from 'app/redux/slices/pathSettingSlice'
import { memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SelectionOptions from './components/SelectionOptions'
import { UpSellList } from './components/UpSellList'
import * as S from './styles'
import { BORDER_RADIUS } from 'parkway-web-common'

const ProductDetail = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [optionsSelected, setOptionsSelected] = useState<
    IVariationProductManagement[]
  >([])

  const dataSetting = useAppSelector(state => state.pathSetting)

  const product = dataSetting.productInProductManagement

  const goBack = () => {
    dispatch(setPathSetting(URL_SETTING.ProductsManagement))
    dispatch(setProductInProductManagement(undefined))
  }

  useEffect(() => {
    if (!product) {
      goBack()
    }
  }, [product])

  const {
    mutateAsync: mutateAsyncUpdateItemStatusById,
    isLoading: loadingUpdate,
  } = useUpdateItemStatusById()

  const updateItem = (id?: string, status?: number) => {
    const params: IParamsUpdateStatus = {
      id,
      status: !!status,
    }

    mutateAsyncUpdateItemStatusById(params)
    dispatch(updateStatusProduct(!!status))
  }

  const updateBestSellerItem = (id?: string, bestseller?: number) => {
    const params: IParamsUpdateStatus = {
      id,
      bestseller: !!bestseller,
    }

    mutateAsyncUpdateItemStatusById(params)
    dispatch(updateBestSellerProduct(!!bestseller))
  }

  const onChangeOptions = (value: IVariationProductManagement) => {
    if (!value) return
    const itemExist = optionsSelected.find(item => item?.id === value?.id)

    if (itemExist) {
      setOptionsSelected(optionsSelected.filter(item => item?.id !== value?.id))
    } else {
      setOptionsSelected([...optionsSelected, value])
    }
  }

  const price = useMemo(() => {
    const currency = product?.currency_list?.[0]
    if (!product?.price_list?.length) return formatMoneyDollar('0')

    if (product?.price_list?.length === 1) {
      return formatMoneyDollar(product?.price_list?.[0] ?? '0', currency)
    }

    return `${formatMoneyDollar(
      product?.price_list?.[0] ?? '0',
      currency,
    )} - ${formatMoneyDollar(
      product?.price_list?.[product?.price_list?.length - 1] ?? '0',
      currency,
    )}`
  }, [product])

  return (
    <S.RootWrapper direction="vertical" size={15}>
      <S.HeaderWrapper justify={'space-between'} align={'middle'}>
        <S.BackButton onClick={goBack}>
          <ArrowLeftOutlined rev={undefined} />
        </S.BackButton>
        <SR.TitleSide>{product?.name}</SR.TitleSide>
        <BaseCol />
      </S.HeaderWrapper>

      <S.InfoWrapper
        gutter={[15, 15]}
        justify={'space-between'}
        align={'middle'}
      >
        <BaseCol xl={6}>
          <BaseImage
            src={
              product?.image ??
              'https://storage.googleapis.com/kiosify-01/images/ic_logo_kiosify.png'
            }
            style={{ borderRadius: BORDER_RADIUS }}
          />
        </BaseCol>
        <BaseCol xl={18}>
          <S.InfoRightSide
            direction="vertical"
            size={10}
            split={<S.SplitDivider />}
          >
            <S.InfoRightSideItem
              gutter={[10, 10]}
              justify={'space-between'}
              align={'middle'}
            >
              <BaseCol className="label">{t(R.strings.table_location)}</BaseCol>
              <BaseCol className="value">
                <BaseSpace direction="vertical" size={10}>
                  {product?.present_at_location_names?.map(item => {
                    return <BaseCol className="value">{item}</BaseCol>
                  })}
                </BaseSpace>
              </BaseCol>
            </S.InfoRightSideItem>
            <S.InfoRightSideItem
              gutter={[10, 10]}
              justify={'space-between'}
              align={'middle'}
            >
              <BaseCol className="label">{t(R.strings.table_category)}</BaseCol>
              <BaseCol className="value">{product?.category ?? '-'}</BaseCol>
            </S.InfoRightSideItem>
            <S.InfoRightSideItem
              gutter={[10, 10]}
              justify={'space-between'}
              align={'middle'}
            >
              <BaseCol className="label">
                {t(R.strings.table_item_price)}
              </BaseCol>
              <BaseCol className="value">{price}</BaseCol>
            </S.InfoRightSideItem>

            <S.InfoRightSideItem
              gutter={[10, 10]}
              justify={'space-between'}
              align={'middle'}
            >
              <BaseCol className="label">{t(R.strings.table_active)}</BaseCol>
              <BaseCol className="value">
                <BaseSwitch
                  checked={product?.status}
                  onChange={() => {
                    if (!product?.item_id) return
                    updateItem(product?.item_id, product?.status ? 0 : 1)
                  }}
                />
              </BaseCol>
            </S.InfoRightSideItem>
            <S.InfoRightSideItem
              gutter={[10, 10]}
              justify={'space-between'}
              align={'middle'}
            >
              <BaseCol className="label">
                {t(R.strings.table_bestseller)}
              </BaseCol>
              <BaseCol className="value">
                <BaseSwitch
                  checked={product?.bestseller}
                  onChange={() => {
                    if (!product?.item_id) return
                    updateBestSellerItem(
                      product?.item_id,
                      product?.bestseller ? 0 : 1,
                    )
                  }}
                />
              </BaseCol>
            </S.InfoRightSideItem>
          </S.InfoRightSide>
        </BaseCol>
        {loadingUpdate && <Loading />}
      </S.InfoWrapper>

      <SelectionOptions
        onSelect={onChangeOptions}
        id={product?.item_id}
        optionsSelected={optionsSelected}
      />

      {product && <UpSellList item={product} />}
    </S.RootWrapper>
  )
}

export default memo(ProductDetail)
