import { BaseButton } from 'app/components/common/BaseButton'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  width: 100%;
`

export const HeaderWrapper = styled(BaseRow)`
  padding: ${PADDING.xxs} 0px;
`

export const BackButton = styled(BaseButton)`
  background-color: rgba(255, 255, 255, 0.1); // need change
  border-radius: ${BORDER_RADIUS};
  border-color: transparent;
  padding: ${PADDING.md};
  transition: all 1s ease;

  & svg {
    fill: var(--background-color);
  }

  &:hover {
    border-color: var(--background-color) !important;
    transition: all 1s ease;
  }
`

export const SaveButton = styled(BaseButton)`
  background-color: var(--admin-text-focus-setting-menu-color);
  color: var(--background-color);
  font-weight: ${FONT_WEIGHT.medium};
  border-color: transparent;
  transition: all 1s ease;

  &:hover {
    border-color: var(--background-color) !important;
    color: var(--background-color) !important;
    transition: all 1s ease;
  }
`

export const DeviceNameInput = styled(BaseInput)`
  background-color: rgba(255, 255, 255, 0.1); // need change
  color: #ffffff;
  height: 52px;
  font-weight: ${FONT_WEIGHT.medium};
  outline: none;
  border: unset;
  border-radius: 5px;
  margin: 16px 0;
  padding: 4px 22px;
  &::placeholder {
    color: #475569;
  }
  &:focus {
    box-shadow: unset;
  }
`

export const DeviceNameDescription = styled.div`
  color: #475569;
  font-weight: ${FONT_WEIGHT.regular};
`

export const SplitDivider = styled(BaseDivider)`
  margin: 24px 0 8px 0;
  background-color: #475569;
`

export const DeviceIdWraper = styled(BaseRow)`
  width: 100%;
  padding: 8px 0;
`

export const DeviceIdTitle = styled.div`
  color: var(--background-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
`

export const DeviceId = styled.div`
  color: var(--background-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
`