/* eslint-disable @typescript-eslint/no-explicit-any */
import { TypeDateEnum } from 'app/api/report/type'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import R from 'app/assets/R'

interface ChartHeaderLayoutProps {
  hideFilter?: boolean
  onChange?: (value: TypeDateEnum) => void
  params?: TypeDateEnum
}

const DEFINE_TYPE_DATE = {
  [TypeDateEnum.daily]: {
    keyI18n: R.strings.reports_common_chart_header_daily,
  },
  [TypeDateEnum.weekly]: {
    keyI18n: R.strings.reports_common_chart_header_weekly,
  },
  [TypeDateEnum.monthly]: {
    keyI18n: R.strings.reports_common_chart_header_monthly,
  },
  [TypeDateEnum.yearly]: {
    keyI18n: R.strings.reports_common_chart_header_yearly,
  },
}

const ChartHeaderLayout = (props: ChartHeaderLayoutProps) => {
  const { t } = useTranslation()

  const handleClick = (event: any, value: TypeDateEnum) => {
    const actionItems = document.querySelectorAll('.action-items span')
    actionItems.forEach(item => {
      item.classList.contains('active') && item.classList.remove('active')
    })
    ;(event.target as HTMLSpanElement).classList.add('active')

    props.onChange && props.onChange(value)
  }

  return (
    <S.RootWrapper justify={'space-between'} align={'top'}>
      <S.LeftLayout className="action-items">
        {Object.keys(TypeDateEnum).map((key, index) => {
          return (
            <S.ActionItem
              key={index}
              className={`${props?.params === key && 'active'}`}
              onClick={event => handleClick(event, key as TypeDateEnum)}
            >
              {t(DEFINE_TYPE_DATE[key].keyI18n)}
            </S.ActionItem>
          )
        })}
      </S.LeftLayout>
    </S.RootWrapper>
  )
}

export default ChartHeaderLayout
