import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BORDER_RADIUS, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

export const PrintersDataWrapper = styled(BaseRow)`
  margin-top: 16px;
`

export const PrintersDataItem = styled(BaseCol)`
  width: 105px;
  height: 105px;
  
  .printer-icon-wrapper {
    height: 85px;
    background-color: #393C49;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${BORDER_RADIUS};
    &.active {
      background-color: #29CC6A4D;
    }
  }
  .printer-name {
    text-align: center;
    font-size: 12px;
    font-weight: ${FONT_WEIGHT.regular};
    color: #ABBBC2;
  }
`