import R from 'app/assets/R'
import { BaseModal } from 'app/components/common/BaseModal'
import { useSyncAllType } from 'app/react-query/hook/backup'
import { t } from 'i18next'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import { useState } from 'react'
import styled from 'styled-components'
import { URL_SETTING } from '../../constant'
import { ItemMenuCpn } from '../ItemMenu'

const ModalSyncSquare = () => {
  const [isVisible, setIsVisible] = useState(false)

  const { mutateAsync: mutateAsyncAllData, isLoading } = useSyncAllType()

  const onCloseModal = () => {
    setIsVisible(false)
  }

  const onConfirmModal = async () => {
    await mutateAsyncAllData({})
    onCloseModal()
  }

  return (
    <>
      <ItemMenuCpn
        item={{
          icon: R.images.ic_cloud_sync,
          iconFocus: R.images.ic_cloud_sync,
          title: R.strings.setting_menu_sync_data_from_square_t,
          url: URL_SETTING.SyncDataFromSquare,
          description: R.strings.setting_menu_sync_data_from_square_d,
        }}
        onPress={() => {
          setIsVisible(true)
        }}
        type="action"
      />
      <BaseModal
        open={isVisible}
        onOk={onConfirmModal}
        onCancel={onCloseModal}
        okText={t(R.strings.confirm)}
        cancelText={t(R.strings.cancel)}
        title={t(R.strings.notification)}
        confirmLoading={isLoading}
        width={400}
      >
        <TextDescription>{t(R.strings.didnt_sync_data)}</TextDescription>
      </BaseModal>
    </>
  )
}

export default ModalSyncSquare

export const TextDescription = styled.div`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.medium};
  font-color: var(--primary-color);
`
