import { IKioskKitchen, IKioskKitchenOrderType, IKioskKitchenStatusColor } from "app/api/kiosk-kitchen/model"
import R from "app/assets/R";

export const STANDARD_SCREEN = {
  Default: 0,
  Classic: 1,
}

interface StandardScreenOtherOption {
  title: string;
  key: keyof Pick<IKioskKitchen, 'is_for_here' | 'is_to_go' | 'is_pick_up'>
}
export const STANDARD_SCREEN_OTHER_OPTIONS: StandardScreenOtherOption[] = [
  {
    title: R.strings.label_common_for_here,
    key: 'is_for_here'
  },
  {
    title: R.strings.label_common_to_go,
    key: 'is_to_go'
  },
  {
    title: R.strings.label_common_pick_up,
    key: 'is_pick_up'
  },
]

export interface TransitionTimesData {
  title: string
  listValue: TransitionTimesListValueData[]
}

export interface TransitionTimesListValueData {
  label: string
  key: keyof IKioskKitchen
}

export const TRANSITION_TIMES_FOR_HERE: TransitionTimesData = {
  title: R.strings.label_common_for_here,
  listValue: [
    {
      label: R.strings.kds_caution_time,
      key: 'caution_time_for_here'
    },
    {
      label: R.strings.kds_late_time,
      key: 'late_time_for_here'
    },
  ]
}

export const TRANSITION_TIMES_PICKUP: TransitionTimesData = {
  title: R.strings.label_common_pick_up,
  listValue: [
    {
      label: R.strings.kds_caution_time,
      key: 'caution_time_pick_up'
    },
    {
      label: R.strings.kds_late_time,
      key: 'late_time_pick_up'
    },
  ]
}

export const TRANSITION_TIMES_TO_GO: TransitionTimesData = {
  title: R.strings.label_common_to_go,
  listValue: [
    {
      label: R.strings.kds_caution_time,
      key: 'caution_time_to_go'
    },
    {
      label: R.strings.kds_late_time,
      key: 'late_time_to_go'
    },
  ]
}

export const FONT_DATA = [
  {
    title: R.strings.label_common_small,
    size: 0
  },
  {
    title: R.strings.label_common_medium,
    size: 1
  },
  {
    title: R.strings.label_common_large,
    size: 2
  },
  {
    title: R.strings.label_common_extra_large,
    size: 3
  },
]

interface StatusColor {
  title: string;
  key: keyof IKioskKitchenStatusColor
}
export const STATUS_COLORS_DATA: StatusColor[] = [
  {
    title: R.strings.label_common_on_time,
    key: 'on_time'
  },
  {
    title: R.strings.label_common_caution,
    key: 'caution'
  },
  {
    title: R.strings.label_common_late,
    key: 'late'
  },
]

interface OrderTypeColor {
  title: string;
  key: keyof IKioskKitchenOrderType
}
export const ORDER_TYPE_COLORS_DATA: OrderTypeColor[] = [
  {
    title: R.strings.label_common_for_here,
    key: 'for_here'
  },
  {
    title: R.strings.label_common_to_go,
    key: 'to_go'
  },
  {
    title: R.strings.label_common_pick_up,
    key: 'pick_up'
  },
]

interface Messaging {
  title: string;
  key: keyof Pick<IKioskKitchen, 'order_received_sms' | 'order_kds_screen_sms' | 'order_ready_to_pickup_sms'>
}
export const MESSAGING_DATA: Messaging[] = [
  {
    title: R.strings.kds_order_received_sms,
    key: 'order_received_sms'
  },
  {
    title: R.strings.kds_order_bumped_from_kds_screen_sms,
    key: 'order_kds_screen_sms'
  },
  {
    title: R.strings.kds_order_is_ready_for_pick_up_sms,
    key: 'order_ready_to_pickup_sms'
  },
]