import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { PaddingMain } from 'app/constant'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  width: 100%;
`

export const HeaderWrapper = styled(BaseRow)`
  padding: ${PADDING.xxs} 0px;
`

export const BackButton = styled(BaseButton)`
  background-color: rgba(255, 255, 255, 0.1); // need change
  border-radius: ${BORDER_RADIUS};
  border-color: transparent;
  padding: ${PADDING.md};
  transition: all 1s ease;

  & svg {
    fill: var(--background-color);
  }

  &:hover {
    border-color: var(--background-color) !important;
    transition: all 1s ease;
  }
`

export const ButtonSeeYourRestaurant = styled(BaseButton)`
  background-color: var(--admin-text-focus-setting-menu-color);
  color: var(--background-color);
  font-weight: ${FONT_WEIGHT.medium};
  border-color: transparent;
  transition: all 1s ease;

  &:hover {
    border-color: var(--background-color) !important;
    color: var(--background-color) !important;
    transition: all 1s ease;
  }
`

// info
export const InfoWrapper = styled(BaseRow)`
  padding: ${PADDING.xxxs};
  width: 100%;
`

export const InfoImageWrapper = styled(BaseCol)`
  width: 20%;
  border-radius: ${BORDER_RADIUS};
  height: 210px;
`

export const InfoContentWrapper = styled(BaseSpace)`
  width: 79%;
`

export const SplitDivider = styled(BaseDivider)`
  margin: 0 0.5rem;
  background-color: rgba(255, 255, 255, 0.1); // need change
`

export const InfoContentItemWrapper = styled(BaseCol)``

export const SpaceVertical = styled.div`
  height: 5px;
`
export const InfoContentItemTitle = styled.span`
  color: #abbbc2; // need change
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.regular};
`

export const InfoContentItemDescription = styled.span`
  color: var(--background-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
`
// end info

// data revenue

export const RevenueWrapper = styled(BaseRow)`
  padding: ${PADDING.xxs};
`
export const RevenueDataWrapper = styled(BaseSpace)`
  width: 39%;
`

export const RevenueDataItemWrapper = styled(BaseSpace)`
  padding: ${PADDING.xs};
  border-radius: ${BORDER_RADIUS};
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
`

export const RevenueDataItemIcon = styled.div`
  padding: ${PADDING.xxs};
  border-radius: ${BORDER_RADIUS};
  background-color: #252836;
`
export const RevenueDataItemTextPercent = styled.div<{ isUp?: boolean }>`
  color: ${props =>
    props?.isUp
      ? 'var(--admin-text-focus-setting-menu-color)'
      : '#FF7CA3'}; // need change
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.regular};
`
export const RevenueDataItemTextValue = styled.span`
  color: var(--background-color);
  font-size: ${FONT_SIZE.xl};
  font-weight: ${FONT_WEIGHT.bold};
`
export const RevenueDataItemTextDescription = styled.span`
  color: #abbbc2; // need change
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
`

export const ChartWrapper = styled.div`
  padding: 0px ${PADDING.md};
  width: 59%;
`

export const ChartHeaderWrapper = styled(BaseRow)`
  width: 100%;
  padding: 0px ${PaddingMain.Desktop.defaultHz};
  padding-bottom: ${PADDING.md};
`

export const ChartHeaderTitle = styled.div`
  color: var(--background-color);
  font-size: ${FONT_SIZE.xxl};
  font-weight: ${FONT_WEIGHT.bold};
`

export const ChartHeaderSelectTime = styled(BaseSelect)`
  min-width: 150px;
`

export const OpenTimeWrapper = styled.div`
  padding: 16px 8px;
`
