import { SvgHalfMoonIcon, SvgSunLightIcon } from 'app/assets/svg-assets'
import * as S from './styles'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

interface ThemeSwitchComponentProps {
  value?: boolean
  onChange?: (value: boolean) => void
}

const ThemeSwitchComponent = (props: ThemeSwitchComponentProps) => {
  const { t } = useTranslation()

  return (
    <S.RootWrapper>
      <input
        type="checkbox"
        id="darkmode-toggle"
        // checked={props.value ?? false}
        onChange={(event) => (props.onChange && props.onChange(event.target.checked))}
      />
      <label htmlFor="darkmode-toggle">
        <SvgHalfMoonIcon className='moon' />
        <SvgSunLightIcon className='sun' />

        <div className="switch-handle">
          <div className="darkmode-title">
            <SvgHalfMoonIcon />
            {t(R.strings.dark_mode)}
          </div>
          <div className="lightmode-title">
            {t(R.strings.light_mode)}
            <SvgSunLightIcon />
          </div>
        </div>
      </label>
    </S.RootWrapper>
  )
}
export default ThemeSwitchComponent