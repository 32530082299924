import { TimePickerProps } from 'antd/lib/time-picker'
import { Dimension } from 'parkway-web-common'
import React from 'react'
import * as S from './styles'
import { SvgTimerIcon } from 'app/assets/svg-assets'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

export interface BaseTimePickerProps extends TimePickerProps {
  width?: Dimension
  okText?: string
}

export const BaseTimePicker: React.FC<BaseTimePickerProps> = (props) => {
  const { t } = useTranslation()

  return (
    <S.CustomTimePicker
      getPopupContainer={triggerNode => triggerNode}
      placeholder={props.placeholder ?? t(R.strings.input_placeholder_select_time)}
      suffixIcon={<SvgTimerIcon />}
      $width={props.width}
      $okText={props.okText ?? t(R.strings.save)}
      {...props}
    />
  )
}
