/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestGetBackup,
  requestRevertBackup,
  requestSyncAllType,
} from 'app/api/backup/api'
import {
  IBackup,
  IBodySyncById,
  IParamsBackupList,
  ResponseBackup,
} from 'app/api/backup/model'
import { IResponseQueryList } from 'app/api/product-management/model'
import { LOGOUT_PATH } from 'app/components/router/route-path'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { BackupKeys } from '../query-key/backup'
import { IResponseDefine } from 'app/common/model'
import { KioskKeys } from '../query-key/kiosk'
import { RestaurantKeys } from '../query-key/restaurant'
import { ProductManagementKeys } from '../query-key/product-management'
import { notificationController } from 'app/controllers/notification-controller'
import { t } from 'i18next'
import R from 'app/assets/R'

export function useGetBackupList(params?: IParamsBackupList, enabled = true) {
  const navigate = useNavigate()
  return useInfiniteQuery<IResponseQueryList<IBackup[]>, IParamsBackupList>(
    BackupKeys.backupList(params),
    async () => {
      try {
        let newParams: IParamsBackupList = {
          location: params?.location ?? '',
          page: params?.page ?? 1,
          page_size: params?.page_size ?? 10,
        }

        if (params?.start_date && params?.end_date) {
          newParams = {
            ...newParams,
            start_date: params?.start_date,
            end_date: params?.end_date,
          }
        }

        const response: ResponseBackup = await requestGetBackup(newParams)
        return {
          data: response?.results ?? [],
          total: response?.count ?? 0,
        }
      } catch (error: any) {
        console.log({ error }, 'useGetUpSellList')
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
        }
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!params ?? enabled,
      cacheTime: 5000,
    },
  )
}

export const useSyncRevertBackupById = () => {
  const navigate = useNavigate()
  const query = useQueryClient()
  return useMutation<IResponseDefine<boolean>, unknown, IBodySyncById, unknown>(
    async (params: any) => {
      try {
        const res = await requestRevertBackup(params)
        return res
      } catch (error: any) {
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
        }
      }
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: BackupKeys.all,
        })
      },
    },
  )
}

export const useSyncAllType = () => {
  const navigate = useNavigate()
  const query = useQueryClient()
  return useMutation<IResponseDefine<boolean>, unknown, unknown, unknown>(
    async () => {
      try {
        const res = await requestSyncAllType()
        notificationController.success({
          message: t(R.strings.you_sync_data_success),
        })
        return res
      } catch (error: any) {
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
        }
      }
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: BackupKeys.all,
        })
        query.invalidateQueries({
          queryKey: KioskKeys.all,
        })
        query.invalidateQueries({
          queryKey: RestaurantKeys.all,
        })
        query.invalidateQueries({
          queryKey: ProductManagementKeys.allItemProductManagement,
        })
      },
    },
  )
}
