import { BaseRow } from 'app/components/common/BaseRow'
import { BaseTabs } from 'app/components/common/BaseTabs'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div``
export const HeaderWrapper = styled(BaseRow)`
  padding: ${PADDING.xxxxs} 0px;
  max-height: 630px;
  width: 100%;
`

export const TabContent = styled(BaseTabs)`
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #29cc6a;
  }

  .ant-tabs-tab {
    color: var(--background-color);
  }

  .ant-tabs-ink-bar {
    background: #29cc6a;
    border-radius: ${BORDER_RADIUS};
  }

  &.ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1.5px solid #393c49;
  }
`
