import { isEqual } from 'lodash'
import { PADDING, useResponsive } from 'parkway-web-common'
import React, { useMemo } from 'react'
import { Overlay } from '../../../../../common/Overlay'
import { SliderLogo } from '../SiderLogo'
import SliderMenu from '../SiderMenu'
import { sidebarNavigation } from '../sidebar-navigation'
import * as S from './styles'

interface MainSliderProps {
  isCollapsed: boolean
  setCollapsed: (isCollapsed: boolean) => void
}

const MainSlider: React.FC<MainSliderProps> = ({
  isCollapsed,
  setCollapsed,
  ...props
}) => {
  const { mobileOnly, tabletOnly } = useResponsive()

  const isCollapsible = useMemo(
    () => mobileOnly && tabletOnly,
    [mobileOnly, tabletOnly],
  )

  const toggleSlider = () => setCollapsed(!isCollapsed)

  const isLastedMenu = useMemo(() => {
    return isEqual(
      location?.pathname,
      sidebarNavigation?.[sidebarNavigation?.length - 1]?.url,
    )
  }, [location?.pathname])

  return (
    <>
      <S.Slider
        trigger={null}
        collapsed={isCollapsed}
        collapsedWidth={60}
        collapsible={isCollapsible}
        width={240}
        {...props}
      >
        <SliderLogo
          isSliderCollapsed={isCollapsed}
          toggleSlider={toggleSlider}
        />
        <S.SliderContent>
          <SliderMenu setCollapsed={setCollapsed} isCollapsed={isCollapsed} />
        </S.SliderContent>
        <div
          style={{
            width: '100%',
            backgroundColor: 'var(--admin-collapse-background-color)',
            height: 350,
            borderTopRightRadius: isLastedMenu ? PADDING.md : 0,
            borderBottomRightRadius: PADDING.md,
          }}
        />
      </S.Slider>
      {mobileOnly && <Overlay onClick={toggleSlider} show={!isCollapsed} />}
    </>
  )
}

export default MainSlider
