import {
  IFilterReport,
  IReportRevenue,
  TypeDateEnum,
} from 'app/api/report/type'
import R from 'app/assets/R'
import { BaseChart } from 'app/components/common/charts/BaseChart'
import { usePagination } from 'app/hook'
import { useGetReportRevenue } from 'app/react-query/hook/report'
import { t } from 'i18next'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BAR_CHART_SERIES_COLORS } from '../../constant'
import ChartHeaderLayout from '../ChartHeader'
import * as S from './styles'

interface Series {}

const DEFAULT_CHART_DATA: number[][] = [
  [31000, 28000, 10000, 6000, 6000, 6000, 6000],
  [12000, 15000, 18000, 9000, 9000, 9000, 9000],
]

const DEFAULT_SERIES_NAMES: string[] = [
  R.strings.reports_common_chart_serrie_total_gross,
  R.strings.reports_common_chart_serrie_total_net,
]

const TotalRevenueLayout = () => {
  const { i18n } = useTranslation()
  const [params, setParams] = useState<IFilterReport>({
    type_date: TypeDateEnum.daily,
  })

  const { data: dataReport } = useGetReportRevenue(params)

  const { flattenDataList } = usePagination()

  const chartData = useMemo(() => {
    const data: IReportRevenue[] = flattenDataList(dataReport)

    const grossData = data?.map(item => item?.current?.total_gross ?? 0)
    const netData = data?.map(item => item?.current?.total_net ?? 0)
    const labels = data?.map(item => item?.date ?? '-')
    const currency = data?.find(itm => itm?.current?.currency)

    return {
      data: [grossData, netData],
      labels,
      currency: currency?.current?.currency,
    }
  }, [DEFAULT_CHART_DATA, dataReport])

  const xAxisLabel = useMemo(() => {
    switch (params?.type_date) {
      case TypeDateEnum.daily:
        return chartData?.labels?.map(item => {
          const hour = +item
          return hour > 9
            ? `${hour}:00 - ${hour}:59`
            : `0${hour}:00 - 0${hour}:59`
        })

      case TypeDateEnum.weekly:
        return chartData?.labels?.map(item => `${item}`)
      case TypeDateEnum.monthly:
        return chartData?.labels?.map(item => `${item}`)
      case TypeDateEnum.yearly:
        return chartData?.labels?.map(item => `Month ${item}`)
    }
  }, [chartData, params])

  const dataZoom = useMemo(() => {
    switch (params?.type_date) {
      case TypeDateEnum.daily:
        return [
          {
            type: 'inside',
            start: 0,
            end: 35,
            show: false,
            zoomLock: true,
          },
        ]

      case TypeDateEnum.weekly:
        return [
          {
            type: 'inside',
            start: 0,
            end: 100,
            show: false,
            zoomLock: true,
          },
        ]
      case TypeDateEnum.monthly:
        return [
          {
            type: 'inside',
            start: 0,
            end: 35,
            show: false,
            zoomLock: true,
          },
        ]
      case TypeDateEnum.yearly:
        return [
          {
            type: 'inside',
            start: 0,
            end: 60,
            show: false,
            zoomLock: true,
          },
        ]
    }
  }, [params])

  const option = useMemo(() => {
    const series: Series[] = []
    for (let i = 0; i < chartData.data.length; i++) {
      const newData = chartData.data[i]
      const newName = t(DEFAULT_SERIES_NAMES[i])

      const newSeries = {
        data: newData,
        name: newName,
        type: 'bar',
        label: {
          show: true,
          position: 'top',
          align: i === 0 ? 'right' : 'left',
          offset: i === 0 ? [12, 0] : [-12, 0],
          distance: 3,
          formatter: params => `${params.value.toLocaleString('en-US')}`,
        },
        barGap: '0',
        barWidth: '27px',
        itemStyle: {
          color: BAR_CHART_SERIES_COLORS[i],
          borderRadius: [5, 5, 0, 0],
        },
      }

      series.push(newSeries)
    }

    return {
      backgroundColor: 'transparent',
      tooltip: {},
      grid: {
        top: 50,
        left: 0,
        right: 0,
        bottom: 0,
        containLabel: true,
      },
      legend: {
        right: 0,
        top: 16,
        icon: 'circle',
        itemWidth: 12,
        itemHeight: 12,
      },
      xAxis: {
        data: xAxisLabel,
        type: 'category',
        axisLine: {
          lineStyle: {
            color: '#FFFFFF',
            type: 'dashed',
          },
        },
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        axisLabel: {
          formatter: params => `${params / 1000}k ${chartData?.currency ?? ''}`,
        },
        splitLine: {
          lineStyle: {
            color: '#FFFFFF',
            type: 'dashed',
          },
        },
      },
      series: [...series],
      dataZoom,
    }
  }, [chartData, i18n.language, xAxisLabel, dataZoom])

  const onChange = (type_date: TypeDateEnum) => {
    setParams({
      type_date,
    })
  }

  return (
    <S.RootWrapper>
      <ChartHeaderLayout onChange={onChange} params={params.type_date} />
      <BaseChart
        option={{ ...option }}
        theme={'dark'}
        width={'100%'}
        height={500}
      />
    </S.RootWrapper>
  )
}

export default TotalRevenueLayout
