import {
  IResponseQueryList,
  IVariationProductManagement,
} from 'app/api/product-management/model'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { Loading } from 'app/components/common/Loading'
import { usePagination } from 'app/hook'
import { useGetVariationListByItemId } from 'app/react-query/hook/product-management'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import { useMemo } from 'react'
import styled from 'styled-components'
import OptionItem from './OptionItem'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

interface IProps {
  onSelect: (value: IVariationProductManagement) => void
  id?: string | number
  optionsSelected: IVariationProductManagement[]
}

const SelectionOptions = ({ onSelect, id, optionsSelected }: IProps) => {
  const { t } = useTranslation()
  const { data, isLoading: loading } = useGetVariationListByItemId({
    id,
  })

  const { flattenDataList } = usePagination()

  const modifierData = useMemo(() => {
    const modifierList: IResponseQueryList<IVariationProductManagement[]> =
      flattenDataList(data)

    let modifier = modifierList?.data

    modifier = modifier?.map(item => {
      const isSelected = optionsSelected?.find(
        option => option?.id === item?.id,
      )
      return {
        ...item,
        isSelected: isSelected ? true : false,
      }
    })

    return modifier
  }, [data, id, optionsSelected])

  return (
    <OptionWrapper direction="vertical" size={15}>
      <div className="title">{t(R.strings.table_options)}</div>
      {modifierData?.map((item, index) => {
        return <OptionItem item={item} onSelect={onSelect} key={index} />
      })}
      {loading && <Loading />}
    </OptionWrapper>
  )
}

export default SelectionOptions
const OptionWrapper = styled(BaseSpace)`
  width: 100%;
  .title {
    color: var(--background-color);
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.regular};
  }
`
