import { cyan, generate, green, presetPalettes, red } from '@ant-design/colors';
import { ColorPicker, ColorPickerProps, theme } from 'antd';
import R from 'app/assets/R';
import { Loading } from 'app/components/common/Loading';
import { APPERANCE_COLOR_TARGETS } from 'app/containers/admin/Settings/constant';
import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

interface ColorCustomProps {
  title: string;
  currentValue: string;
  isLoading?: boolean;
  circleColors?: string[];
  onChange?: (colorCode: string) => void;
  onTargetChange?: (target: string) => void;
}

type Presets = Required<ColorPickerProps>['presets'][number];

const genPresets = (presets = presetPalettes) =>
  Object.entries(presets).map<Presets>(([label, colors]) => ({ label, colors }));

const DEFAULT_CIRCLE_COLORS = [
  '#0099FF',
  '#29CC6A',
  '#7B61FF',
  '#FC5555',
  '#000000',
  '#86EFAC',
  '#EF4444',
  '#2563EB',
  '#475569',
]

const ColorCustom = (props: ColorCustomProps) => {
  const { token } = theme.useToken()
  const presets = genPresets({ primary: generate(token.colorPrimary), red, green, cyan })
  const { t, i18n } = useTranslation()
  const [selectedValue, setSelectedValue] = useState(APPERANCE_COLOR_TARGETS.Background)

  const TARGET_SELECT_OPTIONS = useMemo(() => [
    { value: `${APPERANCE_COLOR_TARGETS.Background}`, label: t(R.strings.background) },
    { value: `${APPERANCE_COLOR_TARGETS.Text}`, label: t(R.strings.text) },
    { value: `${APPERANCE_COLOR_TARGETS.Button}`, label: t(R.strings.button) },
  ], [i18n.language])

  const targetSelectChange = (value) => {
    setSelectedValue(value)
    props.onTargetChange && props.onTargetChange(value)
  }

  return (
    <S.RootWrapper>
      <S.TitleWrapper
        justify={'space-between'}
        align={'middle'}
      >
        <div className="title">
          {props.title}
        </div>
      </S.TitleWrapper>

      <S.CustomTargetSelect
        value={selectedValue}
        size='small'
        onChange={targetSelectChange}
        options={TARGET_SELECT_OPTIONS}
      />

      <S.ColorCircleWrapper
        gutter={[8, 8]}
      >
        {
          (props.circleColors || DEFAULT_CIRCLE_COLORS).map((item, index) => {
            return (
              <S.ColorCircleItem
                key={index}
                $bgColor={item}
              >
                <div
                  className={`color-cicle ${props.currentValue === item && 'active'}`}
                  onClick={() => props.onChange?.(item)}
                ></div>
              </S.ColorCircleItem>
            )
          })
        }
        <ColorPicker
          value={props.currentValue}
          presets={presets}
          onChangeComplete={(color) => props.onChange?.(color.toHexString())}
        >
          <S.ColorCircleGradientItem>
            <div className="gradient-area">
              <div className="white-area"></div>
            </div>
          </S.ColorCircleGradientItem>
        </ColorPicker>
      </S.ColorCircleWrapper>
      {props.isLoading && <Loading />}
    </S.RootWrapper>
  )
}

export default memo(ColorCustom)