import styled from "styled-components";

const LABEL_WIDTH = 174
const LABEL_AFTER_WIDTH = 120
const LABEL_AFTER_LEFT_UNCHECK = 6
export const RootWrapper = styled.span`
label {
	width: ${LABEL_WIDTH}px;
	height: 48px;
	position: relative;
	display: block;
	border: 1px solid #D9D9D94D;
    box-sizing: content-box;
	border-radius: 200px;
	cursor: pointer;
}
label .switch-handle {
	content: "";
	width: ${LABEL_AFTER_WIDTH}px;
	height: 36px;
	position: absolute;
	z-index: 11;
	left: ${LABEL_AFTER_LEFT_UNCHECK}px;
    top: 50%;
    transform: translateY(-50%);
	background: #FFFFFF;
	border-radius: 20px;
	transition: all 0.3s;
    .darkmode-title,
    .lightmode-title {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width:100%;
	    height: 100%;
        color: black;
        font-size: 12px;
        font-weight: 400;
        user-select: none;
    }
    .darkmode-title svg{
        fill: black;
        margin-right: 8px;
    }
    .lightmode-title svg{
        fill: black;
        margin-left: 8px;
    }
}
input {
	width: 0;
	height: 0;
	display: none;
}
input:checked + label .switch-handle {
	left: calc(${LABEL_WIDTH - LABEL_AFTER_LEFT_UNCHECK}px - ${LABEL_AFTER_WIDTH}px);
}
label svg {
    width: 24px;
    height: 24px;
}
label svg.sun,
label svg.moon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
	z-index: 10;
    transition: all 0.2s;
}
label svg.sun {
    fill: #5b5d67;
    opacity: 1;
	right: 10px;
}
input:checked + label svg.sun {
    opacity: 0;
}
label svg.moon {
    opacity: 0;
	left: 10px;
}
input:checked + label svg.moon {
	fill: #5b5d67;
    opacity: 1;
}
input + label .switch-handle .darkmode-title {
    opacity: 1;
}
input:checked + label .switch-handle .darkmode-title {
    opacity: 0;
}
input + label .switch-handle .lightmode-title {
    opacity: 0;
}
input:checked + label .switch-handle .lightmode-title {
    opacity: 1;
}
`