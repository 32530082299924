import { ArrowLeftOutlined } from '@ant-design/icons'
import { BaseCol } from 'app/components/common/BaseCol'
import * as SR from 'app/containers/admin/Settings/components/RightSide/styles'
import { URL_SETTING } from 'app/containers/admin/Settings/constant'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { setPathSetting } from 'app/redux/slices/pathSettingSlice'
import { memo, useEffect, useState } from 'react'
import * as S from './styles'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

const DeviceDetailLayout = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const deviceData = useAppSelector(state => state.pathSetting,)?.deviceData
  const [deviceName, setDeviceName] = useState(deviceData?.name || '')

  useEffect(() => {
    if (!deviceData) {
      dispatch(setPathSetting(URL_SETTING.Information))
    }
  }, [])

  useEffect(() => {
    console.log('deviceName is:', deviceName)
  }, [deviceName])

  const goBack = () => {
    return dispatch(setPathSetting(URL_SETTING.Information))
  }

  const save = () => {
    // do something
    console.log('save ran')
  }

  return (
    <S.RootWrapper>
      <S.HeaderWrapper justify={'space-between'} align={'middle'}>
        <S.BackButton onClick={goBack}>
          <ArrowLeftOutlined rev={undefined} />
        </S.BackButton>
        <SR.TitleSide>{deviceData?.name ?? '-'}</SR.TitleSide>
        <BaseCol>
          <S.SaveButton onClick={save}>
            {t(R.strings.save)}
          </S.SaveButton>
        </BaseCol>
      </S.HeaderWrapper>

      <S.DeviceNameInput
        value={deviceName}
        placeholder={t(R.strings.input_placeholder_device_name)}
        onChange={event => setDeviceName(event.target.value)}
      />

      <S.DeviceNameDescription>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Blanditiis vitae fugiat odio architecto veniam! Quae architecto dolores ipsa nostrum ea quaerat odio doloribus quia porro tempore iste nulla, esse voluptates.
      </S.DeviceNameDescription>

      <S.SplitDivider />

      <S.DeviceIdWraper
        align={'middle'}
        justify={'space-between'}
      >
        <S.DeviceIdTitle>{t(R.strings.your_infomation_device_id)}</S.DeviceIdTitle>
        <S.DeviceId>{deviceData?.id || '-'}</S.DeviceId>
      </S.DeviceIdWraper>
    </S.RootWrapper>
  )
}

export default memo(DeviceDetailLayout)
