import { DeleteFilled } from '@ant-design/icons'
import {
  IParamsUpdateStatus,
  IPropsTableProductManagement,
} from 'app/api/product-management/model'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BasePopconfirm } from 'app/components/common/BasePopconfirm'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseSwitch } from 'app/components/common/BaseSwitch'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import {
  RenderValueTableReport,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import { BORDER_RADIUS_2 } from 'app/constant'
import { formatMoneyDollar } from 'app/hook'
import {
  useDeleteUpSellStatusById,
  useUpdateUpSellStatusById,
} from 'app/react-query/hook/product-management'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export interface IDataTableUpSell {
  id: number | string
  name: string
  item: string
  location: string[]
  variant: string
  price: number
  status: number
  upsellAt: string
  quantity?: number
  image?: string
  itemByUpSell?: string[]
  currency?: string
}

const TableComp = ({
  data,
  onChangePage,
  total,
  loadingList,
}: IPropsTableProductManagement<IDataTableUpSell>) => {
  const { t, i18n } = useTranslation()
  const {
    mutateAsync: mutateAsyncUpdateUpSellStatusById,
    isLoading: loadingUpdate,
  } = useUpdateUpSellStatusById()

  const updateUpSell = (id: string, status: number) => {
    const params: IParamsUpdateStatus = {
      id,
      status: !!status,
    }

    mutateAsyncUpdateUpSellStatusById(params)
  }

  const {
    mutateAsync: mutateAsyncDeleteUpSellStatusById,
    isLoading: loadingDelete,
  } = useDeleteUpSellStatusById()

  const deleteUpSell = (id: string) => {
    const params: IParamsUpdateStatus = {
      id,
    }

    mutateAsyncDeleteUpSellStatusById(params)
  }
  const column = useMemo(() => {
    return [
      convertColumnTable<IDataTableUpSell>({
        title: t(R.strings.table_up_sell_item),
        classNameOverwrite: 'big-column',
        key: 'name',
        fixed: 'left',
        render: (_, record) => {
          return (
            <BaseRow gutter={[15, 10]} align={'middle'} wrap={false}>
              <BaseCol>
                <ImageProduct
                  src={
                    record?.image ??
                    'https://storage.googleapis.com/kiosify-01/images/ic_logo_kiosify.png'
                  }
                  width={35}
                  height={35}
                  preview={false}
                />
              </BaseCol>
              <TextItemName>{record?.name}</TextItemName>
            </BaseRow>
          )
        },
      }),
      convertColumnTable<IDataTableUpSell>({
        title: t(R.strings.table_up_sell_at),
        key: 'upsellAt',
        fixed: 'left',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            valueString: t(record?.upsellAt),
          }),
      }),

      convertColumnTable<IDataTableUpSell>({
        title: t(R.strings.table_variation),
        key: 'variant',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            valueString: record?.variant,
          }),
      }),
      convertColumnTable<IDataTableUpSell>({
        title: t(R.strings.table_location),
        key: 'location',
        render: (_, record) => {
          return (
            <BaseSpace direction="vertical">
              {record?.location?.map(item => {
                return <BaseCol>{item}</BaseCol>
              })}
            </BaseSpace>
          )
        },
      }),
      convertColumnTable<IDataTableUpSell>({
        title: t(R.strings.table_price),
        key: 'price',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            valueString: formatMoneyDollar(record?.price, record?.currency),
          }),
      }),
      convertColumnTable<IDataTableUpSell>({
        title: t(R.strings.table_quantity),
        key: 'quantity',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            childValue: record?.quantity,
            prefix: '',
          }),
      }),
      convertColumnTable<IDataTableUpSell>({
        title: t(R.strings.item),
        key: 'itemByUpSell',
        render: (_, record) => {
          return (
            <BaseSpace direction="vertical">
              {record?.itemByUpSell?.map(item => {
                return <BaseCol>{item}</BaseCol>
              })}
            </BaseSpace>
          )
        },
      }),
      convertColumnTable<IDataTableUpSell>({
        title: t(R.strings.table_action),
        key: 'item',
        classNameOverwrite: 'big-column',
        render: (_, record) => {
          return (
            <BaseRow
              gutter={[20, 20]}
              justify={'space-between'}
              align={'middle'}
            >
              <BaseRow
                gutter={[20, 20]}
                justify={'space-between'}
                align={'middle'}
              >
                <BaseCol>
                  <SwitchButton
                    checked={!!record?.status}
                    onChange={checked => {
                      updateUpSell(record?.id?.toString(), checked ? 1 : 0)
                    }}
                  />
                </BaseCol>
                {/* <BaseCol>
                  <EditButton rev={undefined} />
                </BaseCol> */}

                <BaseCol>
                  <BasePopconfirm
                    title={t(R.strings.product_management_delete_item_up_sell)}
                    description={`${t(
                      R.strings
                        .product_management_delete_item_up_sell_description,
                    )} ${record?.name}`}
                    onConfirm={() => deleteUpSell(record?.id?.toString())}
                  >
                    <DeleteButton rev={undefined} />
                  </BasePopconfirm>
                </BaseCol>
              </BaseRow>
            </BaseRow>
          )
        },
      }),
    ]
  }, [i18n.language])
  return (
    <Table
      columns={column}
      className="row-overwrite-style"
      dataSource={data}
      pagination={{
        current: 1,
        pageSize: 10,
        total,
        showSizeChanger: false,
        position: ['bottomCenter'],
        onChange: onChangePage,
      }}
      loading={loadingList || loadingUpdate || loadingDelete}
    />
  )
}
export default TableComp

export const Table = styled(BaseTableReport)``

export const SwitchButton = styled(BaseSwitch)`
  &.ant-switch.ant-switch-checked {
    background: rgba(41, 204, 106, 1); // need change
  }

  &.ant-switch.ant-switch-checked:hover {
    background: rgba(41, 204, 106, 1); // need change
  }
`
// const EditButton = styled(EditOutlined)`
//   svg {
//     width: 18px;
//     height: 18px;
//   }
//   cursor: pointer;
// `

const DeleteButton = styled(DeleteFilled)`
  svg {
    width: 18px;
    height: 18px;
  }
  cursor: pointer;
`

export const ImageProduct = styled(BaseImage)`
  border-radius: ${BORDER_RADIUS_2};
`

export const TextItemName = styled(BaseCol)`
  color: var(--background-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.medium};
`
