import { ApiClient } from 'app/service/api-service'
import { ProductManagementApiEndpoint } from './contant'
import {
  IParamsGetVariationByItemId,
  IParamsList,
  IParamsUpdateStatus,
} from './model'
import { IFormCreateUpSell } from 'app/containers/admin/Settings/components/RightSide/layout/ProductManagement/tabs/UpSell/components/AddYourUpSellsModal'

export const requestGetCategoryList = (params: IParamsList) =>
  ApiClient.Get({
    url: ProductManagementApiEndpoint.GET_CATEGORY_LIST,
    params: { params },
  })

export const requestGetItemList = (params?: IParamsList) =>
  ApiClient.Get({
    url: ProductManagementApiEndpoint.GET_ITEM_LIST,
    params: { params },
  })

export const requestUpdateItemById = (params: IParamsUpdateStatus) =>
  ApiClient.Put({
    url: `${ProductManagementApiEndpoint.ITEM}${params.id}/detail/`,
    body: { ...params },
  })

export const requestGetVariationByItemId = (
  params: IParamsGetVariationByItemId,
) =>
  ApiClient.Get({
    url: `${ProductManagementApiEndpoint.ITEM}${params.id}${ProductManagementApiEndpoint.GET_ITEM_VARIATION}`,
    params,
  })

export const requestGetModifierList = (params: IParamsList) =>
  ApiClient.Get({
    url: ProductManagementApiEndpoint.GET_MODIFIER_LIST,
    params: { params },
  })

export const requestUpdateModifierById = (params: IParamsUpdateStatus) =>
  ApiClient.Put({
    url: `${ProductManagementApiEndpoint.MODIFIER}${params.id}/`,
    body: { status: params.status },
  })

export const requestGetTaxList = (params: IParamsList) =>
  ApiClient.Get({
    url: ProductManagementApiEndpoint.GET_TAX_LIST,
    params: { params },
  })

export const requestGetDiscountList = (params: IParamsList) =>
  ApiClient.Get({
    url: ProductManagementApiEndpoint.GET_DISCOUNT_LIST,
    params: { params },
  })

export const requestUpdateDiscountById = (params: IParamsUpdateStatus) =>
  ApiClient.Put({
    url: `${ProductManagementApiEndpoint.DISCOUNT}${params.id}/`,
    body: { status: params.status },
  })

export const requestGetUpSellList = (params: IParamsList) =>
  ApiClient.Get({
    url: ProductManagementApiEndpoint.GET_UPSELL_LIST,
    params: { params },
  })


export const requestGetUpSellListByItem = (params: IParamsList) =>
  ApiClient.Get({
    url: ProductManagementApiEndpoint.GET_UPSELL_LIST_BY_ITEM,
    params: { params },
  })


export const requestUpdateUpSellById = (params: IParamsUpdateStatus) =>
  ApiClient.Put({
    url: `${ProductManagementApiEndpoint.UPSELL}${params.id}/`,
    body: { status: params.status },
  })

export const requestUpdateStatusUpSellById = (params: IParamsUpdateStatus) =>
  ApiClient.Put({
    url: `${ProductManagementApiEndpoint.UPSELL}${params.id}/status`,
    body: { status: params.status },
  })

export const requestDeleteUpSellById = (params: IParamsUpdateStatus) =>
  ApiClient.Delete({
    url: `${ProductManagementApiEndpoint.UPSELL}${params.id}/`,
    body: { status: params.status },
  })

export const requestCreateUpSell = (body: IFormCreateUpSell) =>
  ApiClient.Post({
    url: `${ProductManagementApiEndpoint.UPSELL}`,
    body,
  })

export const requestUploadImageCategory = (body: FormData) =>
  ApiClient.Post({ url: ProductManagementApiEndpoint.UPLOAD_IMAGE_CATEGORY, body })
