import {
  DayOfTheWeekEnum,
  IPayloadUpdateLocationTime,
} from 'app/api/restaurant/model'
import R from 'app/assets/R'
import { usePagination } from 'app/hook'
import {
  useCreateOpenTime,
  useDeleteOpenTime,
  useGetRestaurantDetail,
  useUpdateOpenTime,
} from 'app/react-query/hook/restaurant'
import { useAppSelector } from 'app/redux/hooks'
import moment from 'moment'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IOpenTimeItem, ITimeOpenRestaurant } from './type'

export const useHook = () => {
  const { t } = useTranslation()

  const { flattenDataList } = usePagination()

  const { mutateAsync: mutateAsyncCreate, isLoading: loadingCreate } =
    useCreateOpenTime()
  const { mutateAsync: mutateAsyncDelete, isLoading: loadingDelete } =
    useDeleteOpenTime()
  const { mutateAsync: mutateAsyncUpdate, isLoading: loadingUpdate } =
    useUpdateOpenTime()

  const restaurantDetail = useAppSelector(
    state => state.pathSetting,
  )?.restaurantData

  const { data: restaurantData, isLoading: loadingList } =
    useGetRestaurantDetail(restaurantDetail?.id)

  const days = useMemo(() => {
    const restaurant = flattenDataList(restaurantData)
    const listTime = restaurant?.business_hours ?? []

    const res: IOpenTimeItem[] = Object.values(DayOfTheWeekEnum)?.map(itm => {
      const listTimeOfDayExist = listTime?.filter(
        time => time.day_of_week === itm,
      )
      const checked = !!listTimeOfDayExist?.length

      let times: ITimeOpenRestaurant[] = []

      if (listTimeOfDayExist?.length) {
        times = listTimeOfDayExist?.map(itemTime => {
          return {
            endTime: itemTime?.end_local_time
              ? timeToMilliseconds(itemTime?.end_local_time)
              : new Date(),
            startTime: itemTime?.start_local_time
              ? timeToMilliseconds(itemTime?.start_local_time)
              : new Date(),
            id: itemTime.id,
          }
        })
      }

      return {
        checked,
        dayId: `${getOrdinalNumberOfDayOfTheWeek(itm)}`,
        title: t(getTextDayOfDayOfTheWeek(itm)),
        key: itm,
        times,
      }
    })
    return res
  }, [restaurantData])

  const onAddTimeRange = async (key: string) => {
    const payload: IPayloadUpdateLocationTime = {
      day_of_week: key,
      end_local_time: '00:00:00',
      location_fk: restaurantDetail?.id,
      start_local_time: '00:00:00',
    }

    await mutateAsyncCreate(payload)
  }

  const onDeleteRangeTime = async (id: string) => {
    await mutateAsyncDelete({ id })
  }

  const onChangeRangeTime = async ({
    newItem,
    key,
  }: {
    newItem: ITimeOpenRestaurant
    key: string
  }) => {
    const payload: IPayloadUpdateLocationTime = {
      day_of_week: key,
      end_local_time: moment(newItem.endTime).format('HH:mm:ss'),
      location_fk: restaurantDetail?.id,
      start_local_time: moment(newItem.startTime).format('HH:mm:ss'),
      id: newItem.id,
    }
    console.log({ payload })

    await mutateAsyncUpdate(payload)
  }

  return {
    days,
    onAddTimeRange,
    onDeleteRangeTime,
    onChangeRangeTime,
    isLoading: loadingCreate || loadingDelete || loadingList || loadingUpdate,
  }
}

const getOrdinalNumberOfDayOfTheWeek = (day: DayOfTheWeekEnum) => {
  switch (day) {
    case DayOfTheWeekEnum.MON:
      return 1
    case DayOfTheWeekEnum.TUE:
      return 2
    case DayOfTheWeekEnum.WED:
      return 3
    case DayOfTheWeekEnum.THU:
      return 4
    case DayOfTheWeekEnum.FRI:
      return 5
    case DayOfTheWeekEnum.SAT:
      return 6
    case DayOfTheWeekEnum.SUN:
      return 7
  }
}

const getTextDayOfDayOfTheWeek = (day: DayOfTheWeekEnum) => {
  switch (day) {
    case DayOfTheWeekEnum.MON:
      return R.strings.time_monday
    case DayOfTheWeekEnum.TUE:
      return R.strings.time_tuesday
    case DayOfTheWeekEnum.WED:
      return R.strings.time_wednesday
    case DayOfTheWeekEnum.THU:
      return R.strings.time_thursday
    case DayOfTheWeekEnum.FRI:
      return R.strings.time_friday
    case DayOfTheWeekEnum.SAT:
      return R.strings.time_satuday
    case DayOfTheWeekEnum.SUN:
      return R.strings.time_sunday
  }
}

export function millisecondsToTimeString(milliseconds: number): string {
  const totalSeconds = Math.floor(milliseconds / 1000)
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60

  const formattedHours = String(hours).padStart(2, '0')
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(seconds).padStart(2, '0')

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
}

function timeToMilliseconds(time: string): Date {
  const [hours, minutes, seconds] = time.split(':').map(Number)
  const date = new Date()
  date.setHours(hours)
  date.setMinutes(minutes)
  date.setSeconds(seconds)
  return date
}
