import styled from 'styled-components'
import { BaseButton } from '../BaseButton'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'

export const BackButton = styled(BaseButton)`
  background-color: rgba(255, 255, 255, 0.1); // need change
  border-radius: ${BORDER_RADIUS};
  border-color: transparent;
  padding: ${PADDING.xl} ${PADDING.md};
  transition: all 1s ease;

  & svg {
    fill: var(--background-color);
  }

  &:hover {
    border-color: var(--background-color) !important;
    transition: all 1s ease;
  }
`
