/* eslint-disable @typescript-eslint/no-explicit-any */
import R from 'app/assets/R'
import { SearchInput } from 'app/components/common/inputs/SearchInput'
import { BORDER_RADIUS } from 'parkway-web-common'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IProps {
  onChange?: (value: string) => void
  initValue?: string
  placeholder?: string
}

const SearchComponent = ({
  onChange,
  initValue = '',
  placeholder = R.strings.input_placeholder_search_only,
}: IProps) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState(initValue)

  const handleSearch = (e: { target: { value: any } }) => {
    setSearch(e.target.value)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      onChange && onChange(search)
    }, 500)
    return () => clearTimeout(timer)
  }, [search])

  return (
    <SearchLayout
      width="100%"
      value={search}
      placeholder={t(placeholder)}
      onChange={handleSearch}
      enterButton={null}
      addonAfter={null}
    />
  )
}
export default SearchComponent

const SearchLayout = styled(SearchInput)`
  .ant-input-group-addon {
    display: none;
  }
  .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: ${BORDER_RADIUS};
    border: 0;
    background: #2d303e !important;
    color: var(--background-color);
    border: 0.7px solid rgba(45, 48, 62, 1);
  }
  input {
    background: #2d303e !important;
    color: var(--background-color);
  }
`
