import { TabsProps } from 'antd'
import CategoryTab from './Category'
import { ItemTabs } from './Items'
import { UpSell } from './UpSell'
import { Modifiers } from './Modifier'
import { Discount } from './Discount'
import { t } from 'i18next'
import R from 'app/assets/R'

export const getItemTabs = () => {
  const ItemsTabs: TabsProps['items'] = [
    {
      key: '1',
      label: t(R.strings.product_management_category),
      children: <CategoryTab />,
    },
    {
      key: '2',
      label: t(R.strings.product_management_items),
      children: <ItemTabs />,
    },
    {
      key: '3',
      label: t(R.strings.product_management_up_sell),
      children: <UpSell />,
    },
    {
      key: '4',
      label: t(R.strings.product_management_modifiers),
      children: <Modifiers />,
    },
    {
      key: '5',
      label: t(R.strings.product_management_discounts),
      children: <Discount />,
    },
  ]

  return ItemsTabs
}
