import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import * as SR from 'app/containers/admin/Settings/components/RightSide/styles'
import { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import { getItemTabs } from './tabs'

const ProductManagementLayout = () => {
  const { t, i18n } = useTranslation()
  const [tab, setTab] = useState('1')

  const ItemsTabs = useMemo(() => getItemTabs(), [i18n.language])

  const onChange = (key: string) => {
    setTab(key)
  }

  return (
    <S.RootWrapper>
      <S.HeaderWrapper justify={'space-between'} align={'middle'}>
        <SR.TitleSide>
          {t(R.strings.setting_menu_product_management_t)}
        </SR.TitleSide>
        <BaseCol>{/* <FilterButtonSetting /> */}</BaseCol>
      </S.HeaderWrapper>

      <S.TabContent
        defaultActiveKey={tab}
        items={ItemsTabs}
        onChange={onChange}
      />
    </S.RootWrapper>
  )
}

export default memo(ProductManagementLayout)
