import { PADDING } from "parkway-web-common";
import styled from "styled-components";

export const RootWrapper = styled.div`
  height: 100%;
  padding: ${PADDING.xxl};
`

export const CurrentLayoutWrapper = styled.div<{ $bgColor: string; }>`
  background-color: ${props => props.$bgColor};
  border-radius: 8px;
`