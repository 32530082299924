/* eslint-disable @typescript-eslint/no-explicit-any */
import { IResponseDefine } from 'app/common/model'

export interface ICategory {
  id?: string
  name?: string
  item_count?: number
  status?: boolean
  image_upload?: string
  description?: string
}

export interface IParamsList {
  page_size?: number
  page?: number
  search?: string
  location_id?: string
  category_id?: string
  name?: string
  start_date?: string
  end_date?: string
  location?: string
  item_id?: string
}

export interface IParamsUpdateStatus {
  id?: string
  status?: boolean
  bestseller?: boolean
  code?: string
}

export const ParamsListDefault: IParamsList = {
  page_size: 10,
  page: 1,
  search: '',
  name: '',
  category_id: '',
  location_id: '',
  location: '',
}

export interface IResponseQueryList<T> {
  total?: number
  data: T
}

export interface IPropsTableProductManagement<T> {
  data: T[]
  total: number
  onChangePage: (page: number, pageSize?: number) => void
  loadingList?: boolean
  params?: IParamsList
}

export interface ITax {
  tax_id?: string
  name?: string
  present_at_all_locations?: boolean
  present_at_location_ids?: string[]
  present_at_location_names?: string[]
  percentage?: string
}

export interface IModifier {
  modifierlist_id?: string
  set_name?: string
  present_at_all_locations?: boolean
  present_at_location_ids?: string[]
  present_at_location_names?: string[]
  status?: boolean
  options?: string[]
  edit?: {
    list_id?: string[]
    modifiers?: string[]
    price?: number[]
    list_currency?: string[]
    image?: string[]
    list_items: IItemProductManagement[]
  }
}

export interface IItemProductManagement {
  item_id?: string
  name?: string
  category?: string
  present_at_all_locations?: boolean
  present_at_location_ids?: string[]
  present_at_location_names?: string[]
  upsell?: IUpSellProductManagement[]
  status?: boolean
  variations?: string[]
  variation_ids?: string[]
  price_range?: number[]
  price_list?: number[]
  currency_list?: string[]
  isSelected?: boolean
  bestseller?: boolean
  image?: string
  upsell_count?: number
}

export interface IDiscountProductManagement {
  discount_id?: string
  name?: string
  present_at_all_locations?: boolean
  present_at_location_ids?: string[]
  present_at_location_names?: string[]
  discount_type?: string
  money_amount?: string
  apply_to?: string
  status?: boolean
}

interface IITemValue {
  id: string
  name?: string
}

export interface IUpSellProductManagement {
  id?: string
  item_id?: IITemValue[]
  upsell_at?: string
  item?: string
  selection_type?: string
  varation?: string
  price?: string
  max_qty?: number
  present_at_all_locations?: boolean
  present_at_location_ids?: string[]
  present_at_location_names?: string[]
  absent_at_location_ids?: string[]
  status?: string
  user_fk?: number
  item_by_upsell?: IITemValue[]
  image?: string
  name?: string
  currency?: string
}

export interface IParamsGetVariationByItemId {
  id?: string | number
}

export interface IVariationProductManagement {
  currency?: string
  id?: string
  item_id?: string
  location_overrides?: any
  measurement_unit_id?: string
  name?: string
  ordinal?: number
  price_money?: string
  user_fk?: number
  version?: number

  // using in cpn
  isSelected?: boolean
}

export type ResponseCategory = IResponseDefine<ICategory[]>

export type ResponseTax = IResponseDefine<ITax[]>

export type ResponseModifier = IResponseDefine<IModifier[]>

export type ResponseItem = IResponseDefine<IItemProductManagement[]>

export type ResponseDiscount = IResponseDefine<IDiscountProductManagement[]>

export type ResponseUpSell = IResponseDefine<IUpSellProductManagement[]>

export type ResponseVariation = IResponseDefine<IVariationProductManagement[]>
