import { ArrowLeftOutlined } from '@ant-design/icons'
import {
  IItemProductManagement,
  IResponseQueryList,
} from 'app/api/product-management/model'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseSwitch } from 'app/components/common/BaseSwitch'
import { Loading } from 'app/components/common/Loading'
import * as SR from 'app/containers/admin/Settings/components/RightSide/styles'
import { URL_SETTING } from 'app/containers/admin/Settings/constant'
import { usePagination } from 'app/hook'
import { useGetItemList } from 'app/react-query/hook/product-management'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import {
  setPathSetting,
  setUpSellSelected,
} from 'app/redux/slices/pathSettingSlice'
import { memo, useMemo, useState } from 'react'
import SearchComponent from '../../components/Search'
import * as S from './styles'
import { useTranslation } from 'react-i18next'

const Item = ({
  item,
  onSelect,
}: {
  item: IItemProductManagement
  onSelect: (item: IItemProductManagement, value: boolean) => void
}) => {
  return (
    <S.UpSellItem gutter={[10, 10]} justify={'space-between'} align={'middle'}>
      <BaseCol>
        <BaseRow gutter={[10, 10]} align={'middle'}>
          <BaseCol>
            <S.ImageItem
              width={40}
              height={40}
              src={
                'https://storage.googleapis.com/kiosify-01/images/img_bg_login.png'
              }
            />
          </BaseCol>
          <BaseCol className="label">
            <BaseSpace size={10} direction="vertical">
              <BaseCol>{item?.name}</BaseCol>
              <BaseCol>{item?.category}</BaseCol>
            </BaseSpace>
          </BaseCol>
        </BaseRow>
      </BaseCol>
      <BaseCol>
        <BaseSwitch
          checked={item?.isSelected}
          onChange={(value: boolean) => onSelect(item, value)}
        />
      </BaseCol>
    </S.UpSellItem>
  )
}

const ProductSelectUpSell = () => {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const upSellSelected = useAppSelector(
    state => state.pathSetting,
  )?.upSellSelected

  const [listSelected, setListSelected] = useState<IItemProductManagement[]>(
    upSellSelected ?? [],
  )
  const dispatch = useAppDispatch()

  const goBack = () => {
    dispatch(setPathSetting(URL_SETTING.ProductsDetail))
  }

  const { data, isLoading: loading } = useGetItemList({
    page_size: 100,
    name: search,
  })

  const { flattenDataList } = usePagination()

  const itemProductManagementData = useMemo(() => {
    const itemProductManagement: IResponseQueryList<IItemProductManagement[]> =
      flattenDataList(data)
    const res = itemProductManagement?.data?.map(item => {
      const itemExist = listSelected?.findIndex(
        itemSelected => itemSelected?.item_id === item?.item_id,
      )

      if (itemExist !== -1) {
        return {
          ...item,
          isSelected: true,
        }
      } else {
        return {
          ...item,
          isSelected: false,
        }
      }
    })
    return res
  }, [data, listSelected])

  const onChangeSelected = (item: IItemProductManagement) => {
    if (!listSelected) return
    // listSelected
    const itemExist = listSelected?.findIndex(
      itemSelected => itemSelected?.item_id === item?.item_id,
    )

    if (itemExist !== -1) {
      const newUpSellSelected = listSelected?.filter(
        itemSelected => itemSelected?.item_id !== item?.item_id,
      )
      setListSelected(newUpSellSelected)
    } else {
      const newUpSellSelected = [...listSelected, item]
      setListSelected(newUpSellSelected)
    }
  }

  const onSave = () => {
    dispatch(setUpSellSelected(listSelected))
    dispatch(setPathSetting(URL_SETTING.ProductsDetail))
  }

  return (
    <S.RootWrapper direction="vertical" size={15}>
      <S.HeaderWrapper justify={'space-between'} align={'middle'}>
        <S.BackButton onClick={goBack}>
          <ArrowLeftOutlined rev={undefined} />
        </S.BackButton>
        <SR.TitleSide>{t(R.strings.select_item_for_upsells)}</SR.TitleSide>
        <BaseCol>
          <S.ButtonSeeYourRestaurant onClick={onSave}>
            {t(R.strings.save)}
          </S.ButtonSeeYourRestaurant>
        </BaseCol>
      </S.HeaderWrapper>
      <S.SearchWrapper direction="vertical" size={15}>
        <div className="title">{t(R.strings.recommended_foods)}</div>
        <SearchComponent initValue={search} onChange={setSearch} />
      </S.SearchWrapper>
      <S.TextEx>
        {t(R.strings.ex)}:{' '}
        {t(R.strings.spicy_seasoned_seafood_noodles_strawberry)}
      </S.TextEx>
      <S.UpSellWrapper direction="vertical" size={15}>
        {itemProductManagementData?.map((item, index) => {
          return <Item key={index} item={item} onSelect={onChangeSelected} />
        })}
      </S.UpSellWrapper>
      {loading && <Loading />}
    </S.RootWrapper>
  )
}

export default memo(ProductSelectUpSell)
