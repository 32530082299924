import { IParamsList } from 'app/api/product-management/model'

export const RestaurantKeys = {
  all: ['All'] as const,
  restaurantLists: () => [...RestaurantKeys.all, 'RestaurantList'] as const,
  restaurantList: (filters?: IParamsList) =>
    [...RestaurantKeys.restaurantLists(), { filters }] as const,

  restaurantDetailAlls: () =>
    [...RestaurantKeys.all, 'RestaurantDetail'] as const,
  restaurantDetail: (filters: { id?: string }) =>
    [...RestaurantKeys.restaurantDetailAlls(), { filters }] as const,
}
