/* eslint-disable @typescript-eslint/no-explicit-any */
import { Option } from 'app/components/common/selects/BaseSelect'
import BaseSelectInUpSell from '../BaseSelect'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import { useMemo } from 'react'

export enum PriceTypeUpSellEnum {
  ITEM = 'item',
  DISCOUNT = 'discount',
}

interface IProps {
  onChange?: (value: PriceTypeUpSellEnum) => void
  value?: PriceTypeUpSellEnum
  defaultValue?: PriceTypeUpSellEnum
}

function SelectionPrice({ onChange, value, defaultValue }: IProps) {
  const { t, i18n } = useTranslation()
  const onChangeSelect = (value: any) => {
    onChange && onChange(value)
  }

  const data = useMemo(() => ([
    {
      id: PriceTypeUpSellEnum.ITEM,
      name: t(R.strings.product_management_menu_price)
    },
    {
      id: PriceTypeUpSellEnum.DISCOUNT,
      name: t(R.strings.product_management_discount_price),
    },
  ]), [i18n.language])

  return (
    <BaseSelectInUpSell
      placeholder={t(R.strings.form_item_select_price_p)}
      onChange={onChangeSelect}
      value={value}
      defaultValue={defaultValue}
      title={t(R.strings.form_item_select_price_t)}
    >
      {data.map((item, index) => {
        return (
          <Option key={index} value={item?.id}>
            {item.name}
          </Option>
        )
      })}
    </BaseSelectInUpSell>
  )
}

export default SelectionPrice
