import { BaseRow } from 'app/components/common/BaseRow'
import styled from 'styled-components'
import { TabPaneMainButton } from '../styles'
import { DeviceNameInput } from '../../../DeviceDetail/styles'
import { BaseModal } from 'app/components/common/BaseModal'

const modalTitleHeight = '44px'
export const CreateProfileModal = styled(BaseModal)`
  .ant-modal-content {
    padding: 20px;
    height: 570px;
  }
  .ant-modal-content, .ant-modal-header {
    background-color: #252836;
  }
  .ant-modal-title, .ant-modal-body {
    color: #FFFFFF;
  }
  .ant-modal-title{
    text-align: center;
    line-height: ${modalTitleHeight};
    height: ${modalTitleHeight};
  }
  .ant-modal-close{
    background-color: #393c49;
    inset-inline-end: unset;
    inset-inline-start: 20px;
    top: 20px;
    width: ${modalTitleHeight};
    height: ${modalTitleHeight};
    color: #FFFFFF;
    &:hover {
      color: #ffffffad;
      background-color: #393c49d9;
    }
  }
`

export const SaveCreateProfileModalButton = styled(TabPaneMainButton)`
  position: absolute;
  top: 20px;
  inset-inline-end: 20px;
  z-index: 1010;
  margin: 0;
`

export const CreateProfileModalInput = styled(DeviceNameInput)``

export const PrinterDefaultTitle = styled.div`
  color: #ffffff;
  margin: 8px 0;
`

export const PrinterDefaultDescription = styled.div`
  color: #6B7280;
  margin-bottom: 16px;
`

export const CreateProfilePrinterItem = styled(BaseRow)`
  width: 100%;
  margin-bottom: 12px;
  padding: 8px 0;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
`

export const PrinterItemLeftLayout = styled.div`
  display: flex;
  align-items: center;
  .left-layout-title {
    margin-left: 8px;
  }
`

export const PrinterItemRightLayout = styled.div`
  color: #6B7280;
  .right-layout-status {
    margin-right: 16px;
  }
`

export const ReceiptsModal = styled(CreateProfileModal)``

export const ReceiptsModalActionItem = styled(BaseRow)``

export const ActionItemLeftLayout = styled.div`
  padding: 4px 0;
  margin-top: 8px;
  margin-right: 8px;
  .left-layout-title {
    font-weight: 700;
  }
  .left-layout-description {
    color: #6B7280;
    font-weight: 400;
    font-size: 10px;
  }
`

export const ActionItemRightLayout = styled.div`
  color: #6B7280;
  .right-layout-status {
    margin-right: 16px;
  }
`