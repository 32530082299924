/* eslint-disable @typescript-eslint/no-explicit-any */
import { BoxPlotFilled } from '@ant-design/icons'
import {
  IModifier,
  IResponseQueryList,
  ParamsListDefault,
} from 'app/api/product-management/model'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { usePagination } from 'app/hook'
import { useGetModifierList } from 'app/react-query/hook/product-management'
import { useMemo, useState } from 'react'
import SearchComponent from '../../../../components/Search'
import SelectionRestaurant from '../../../../components/SelectionLocation'
import * as S from './styles'
import TableComp, { IDataTableModifier } from './table'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

export const Modifiers = () => {
  const { t } = useTranslation()
  const [params, setParams] = useState(ParamsListDefault)

  const { data, isLoading: loading } = useGetModifierList(params)
  const { flattenDataList } = usePagination()

  const modifierData = useMemo(() => {
    const modifier: IResponseQueryList<IModifier[]> = flattenDataList(data)
    return modifier
  }, [data, params])

  const dataTable: IDataTableModifier[] = useMemo(() => {
    return modifierData?.data?.map(item => {
      return {
        id: item?.modifierlist_id ?? '-',
        name: item?.set_name ?? '-',
        location: item?.present_at_location_names ?? '-',
        option: item?.options ?? '-',
        status: item.status ? 1 : 0,
        items: item?.edit?.list_items?.map(item => item.name) ?? [],
      } as IDataTableModifier
    })
  }, [modifierData])

  console.log({ dataTable })

  const totalItem = useMemo(() => {
    return modifierData?.total ?? 0
  }, [modifierData])

  const onChangePage = (page: number, pageSize?: number) => {
    setParams({
      ...params,
      page,
      page_size: pageSize ?? 10,
    })
  }

  const onChangeSearch = (value: string) => {
    setParams({
      ...params,
      search: value,
      name: value,
    })
  }

  const onChangeLocation = (value: string) => {
    setParams({
      ...params,
      location_id: value,
    })
  }

  return (
    <S.RootWrapper direction="vertical" size={5}>
      <S.FilterWrapper gutter={[20, 10]} align={'middle'}>
        <BaseCol>
          <SearchComponent onChange={onChangeSearch} initValue={params?.name} />
        </BaseCol>
        <BaseCol>
          <SelectionRestaurant onChange={onChangeLocation} />
        </BaseCol>
      </S.FilterWrapper>
      <BaseRow justify={'end'}>
        <S.TotalItemLayout gutter={[10, 10]} align={'middle'}>
          <BaseCol>
            <BoxPlotFilled rev={undefined} />
          </BaseCol>
          <BaseCol>
            <BaseCol className="text">{`${totalItem} ${t(
              R.strings.product_management_items,
            ).toLowerCase()}`}</BaseCol>
          </BaseCol>
        </S.TotalItemLayout>
      </BaseRow>
      <BaseCol>
        <TableComp
          data={dataTable}
          onChangePage={onChangePage}
          total={totalItem}
          loadingList={loading}
        />
      </BaseCol>
    </S.RootWrapper>
  )
}
