/* eslint-disable @typescript-eslint/no-explicit-any */
import { IRestaurant } from 'app/api/restaurant/model'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { Loading } from 'app/components/common/Loading'
import { NotFound } from 'app/components/common/NotFound'
import * as SR from 'app/containers/admin/Settings/components/RightSide/styles'
import { URL_SETTING } from 'app/containers/admin/Settings/constant'
import { usePagination } from 'app/hook'
import { useGetRestaurantList } from 'app/react-query/hook/restaurant'
import { useAppDispatch } from 'app/redux/hooks'
import {
  setPathSetting,
  setRestaurantSetting,
} from 'app/redux/slices/pathSettingSlice'
import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

const RestaurantLayout = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { data, isLoading: loading } = useGetRestaurantList()
  const { flattenData } = usePagination()

  const restaurants = useMemo(() => {
    const dataRs = flattenData(data)
    return dataRs
  }, [data])

  const navigateToRestaurantDetail = (data: IRestaurant) => {
    dispatch(setPathSetting(URL_SETTING.RestaurantDetail))
    dispatch(setRestaurantSetting(data))
  }

  return (
    <S.RootWrapper>
      <SR.TitleSide>{t(R.strings.restaurant)}</SR.TitleSide>
      <S.ListWrapper gutter={[20, 20]}>
        {restaurants.map((item, index) => {
          return (
            <BaseCol key={`${item} ${index}`}>
              <S.ItemRestaurant
                onClick={() => navigateToRestaurantDetail(item)}
              >
                <S.HeadItemRestaurant>
                  <S.ImageHeadItem
                    src={
                      item?.image ??
                      'https://storage.googleapis.com/kiosify-01/images/ic_logo_kiosify.png'
                    }
                    width={44}
                    height={44}
                    preview={false}
                  />
                </S.HeadItemRestaurant>
                <S.NameRestaurant align={'middle'} justify={'center'}>
                  {`${t(R.strings.restaurant)} ${item.name}`}
                </S.NameRestaurant>
              </S.ItemRestaurant>
            </BaseCol>
          )
        })}
        {restaurants.length === 0 && <NotFound />}
      </S.ListWrapper>
      {loading && <Loading />}
    </S.RootWrapper>
  )
}

export default memo(RestaurantLayout)
