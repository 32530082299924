import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { SearchInput } from 'app/components/common/inputs/SearchInput'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseRow)`
  padding: ${PADDING.xxxxs};
  margin: unset !important;
`

export const LeftSide = styled(BaseCol)`
  border-radius: ${BORDER_RADIUS};
  background-color: var(--admin-collapse-background-color);
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: fit-content;
`

export const TitleWrapper = styled(BaseRow)`
  padding: ${PADDING.md};
  padding-bottom: 0px;
`

export const Title = styled(BaseCol)`
  color: var(--background-color);
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: ${FONT_SIZE.xxxl};
`

export const SearchLayout = styled(SearchInput)`
  .ant-input-group-addon {
    display: none;
  }
  .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: ${BORDER_RADIUS};
    border: 0;
    background: transparent !important;
    color: var(--background-color);
    border: 0.7px solid var(--background-color) !important;
  }
  input {
    background: transparent !important;
    color: var(--background-color);
  }
`
