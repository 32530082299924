import { BoxPlotFilled } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface BoxTotalItemProps {
  total: number
}

const BoxTotalItem = ({ total }: BoxTotalItemProps) => {
  const { t } = useTranslation()

  return (
    <BaseRow justify={'end'}>
      <TotalItemLayout gutter={[10, 10]} align={'middle'}>
        <BaseCol>
          <BoxPlotFilled rev={undefined} />
        </BaseCol>
        <BaseCol>
          <BaseCol className="text">{`${total} ${t(R.strings.item).toLowerCase()}`}</BaseCol>
        </BaseCol>
      </TotalItemLayout>
    </BaseRow>
  )
}

export default BoxTotalItem

const TotalItemLayout = styled(BaseRow)`
  padding: ${PADDING.xxxs};
  border-radius: ${BORDER_RADIUS};
  background-color: rgba(45, 48, 62, 1);
  svg {
    fill: var(--background-color);
    width: 24px;
    height: 24px;
  }
  .text {
    color: var(--background-color);
    font-weight: ${FONT_WEIGHT.medium};
    font-size: ${FONT_SIZE.lg};
  }
`
