/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseButtonsForm } from 'app/components/common/forms/BaseButtonsForm'
import {
  BaseSelect,
  BaseSelectProps,
} from 'app/components/common/selects/BaseSelect'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

interface IProps extends BaseSelectProps {
  title: string
}

function BaseSelectInUpSell({ title, ...props }: IProps) {
  return (
    <BaseButtonsForm.Item name="select-multiple">
      <BaseSpaceInUpSell direction="vertical" size={10}>
        <Title>{`${title}`}</Title>
        <SelectWrapper {...props}>{props.children}</SelectWrapper>
      </BaseSpaceInUpSell>
    </BaseButtonsForm.Item>
  )
}

export default BaseSelectInUpSell

const SelectWrapper = styled(BaseSelect)`
  min-width: 200px !important;
  height: 39px;
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #2d303e;
    color: var(--background-color);
    border: 0.7px solid rgba(45, 48, 62, 1);
  }

  svg {
    fill: var(--background-color) !important;
  }
`
export const Title = styled.div`
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.medium};
  color: var(--background-color);
`

export const BaseSpaceInUpSell = styled(BaseSpace)`
  width: 100%;
`
