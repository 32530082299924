import { Empty } from 'antd'
import {
  IDataMostOrdered,
  IDataReportTotal,
  IDataReportTypeOrder,
  IParamsListGetReportOrder,
} from 'app/api/dashboard/type'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { Loading } from 'app/components/common/Loading'
import { BaseButtonsForm } from 'app/components/common/forms/BaseButtonsForm'
import { Option } from 'app/components/common/selects/BaseSelect'
import { ChartOption } from 'app/constant'
import { formatMoneyDollar, usePagination } from 'app/hook'
import {
  useGetMostOrdered,
  useGetReportTotal,
  useGetReportTypeOrder,
} from 'app/react-query/hook/dashboard'
import _ from 'lodash'
import { moment } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import ApexCharts from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import TableOrderDashboard from './table-order'

const ItemRevenue = ({
  name,
  value,
  icon,
}: {
  name: string
  value?: string
  icon: string
}) => {
  const { t } = useTranslation()
  return (
    <S.RevenueDataItemWrapper direction="vertical" size={3}>
      <BaseRow align={'middle'} gutter={[20, 20]}>
        <BaseCol>
          <S.RevenueDataItemIcon>
            <BaseImage src={icon} width={18} preview={false} />
          </S.RevenueDataItemIcon>
        </BaseCol>
      </BaseRow>
      <S.SpaceVertical />
      <S.RevenueDataItemTextValue>{`${value}`}</S.RevenueDataItemTextValue>
      <S.SpaceVertical />
      <S.RevenueDataItemTextDescription>
        {t(name)}
      </S.RevenueDataItemTextDescription>
    </S.RevenueDataItemWrapper>
  )
}

const ItemMostOrder = ({ item }: { item: IDataMostOrdered }) => {
  const { t } = useTranslation()
  return (
    <S.MostOrderItem gutter={[15, 15]} align={'middle'}>
      <BaseCol>
        <S.MostOrderItemImage
          src={
            item?.image ??
            'https://storage.googleapis.com/kiosify-01/images/ic_logo_kiosify.png'
          }
          width={45}
          height={45}
          preview={false}
        />
      </BaseCol>
      <BaseCol>
        <S.MostOrderItemTitle>{item?.name ?? '-'}</S.MostOrderItemTitle>
        <S.SpaceVertical />
        <S.MostOrderItemDescription>
          {item?.total_quantity ?? 0}{' '}
          {t(R.strings.dashboard_common_dishes_ordered)}
        </S.MostOrderItemDescription>
      </BaseCol>
    </S.MostOrderItem>
  )
}

export enum FilterReportOrderEnum {
  Today = 'today',
  ThisWeek = 'this_week',
  ThisMonth = 'this_month',
}
interface DateRange {
  startDate: string
  endDate: string
}

export const getMonthRange = (): DateRange => {
  const startDate = moment().startOf('month').toISOString()
  const endDate = moment().endOf('month').toISOString()
  return { startDate, endDate }
}

export const getWeekRange = (): DateRange => {
  const startDate = moment().startOf('week').toISOString()
  const endDate = moment().endOf('week').toISOString()
  return { startDate, endDate }
}

export const getTodayRange = (): DateRange => {
  const startDate = moment().startOf('day').toISOString()
  const endDate = moment().endOf('day').toISOString()
  return { startDate, endDate }
}

const DashboardContainer = () => {
  const { t, i18n } = useTranslation()
  const [time, setTime] = useState<FilterReportOrderEnum>(
    FilterReportOrderEnum.Today,
  )

  const [params, setParams] = useState<IParamsListGetReportOrder>({
    page: 1,
    page_size: 10,
    end_date: getTodayRange().endDate,
    start_date: getTodayRange().startDate,
  })

  const onChangeTimeType = (value: FilterReportOrderEnum) => {
    setTime(value)

    switch (value) {
      case FilterReportOrderEnum.Today:
        setParams(prev => ({
          ...prev,
          end_date: getTodayRange().endDate,
          start_date: getTodayRange().startDate,
        }))
        break
      case FilterReportOrderEnum.ThisWeek:
        setParams(prev => ({
          ...prev,
          end_date: getWeekRange().endDate,
          start_date: getWeekRange().startDate,
        }))
        break
      case FilterReportOrderEnum.ThisMonth:
        setParams(prev => ({
          ...prev,
          end_date: getMonthRange().endDate,
          start_date: getMonthRange().startDate,
        }))
        break
    }
  }

  const today = moment().locale(i18n.language)

  const { flattenDataList } = usePagination()

  const { data: dataReportTotal, isLoading: loadingReportTotal } =
    useGetReportTotal()

  const reportTotal = useMemo(() => {
    const res: IDataReportTotal = flattenDataList(dataReportTotal)
    return res
  }, [dataReportTotal])

  const { data: dataMostOrdered, isLoading: loadingMostOrdered } =
    useGetMostOrdered()

  const mostOrdered = useMemo(() => {
    const res: IDataMostOrdered[] = flattenDataList(dataMostOrdered)

    return res ?? []
  }, [dataMostOrdered])

  const { data: dataReportTypeOrder, isLoading: loadingReportTypeOrder } =
    useGetReportTypeOrder()

  const reportTypeOrder = useMemo(() => {
    const res: IDataReportTypeOrder = flattenDataList(dataReportTypeOrder)

    return res ?? {}
  }, [dataReportTypeOrder])

  const dataChart: ChartOption = useMemo(() => {
    const listData: number[] = [
      reportTypeOrder?.total_forhere ?? 0,
      reportTypeOrder?.total_pickup ?? 0,
      reportTypeOrder?.total_togo ?? 0,
    ]
    const total = _.sum(listData)

    const percentList = listData?.map(itm => {
      return _.round((itm / total) * 100, 5)
    })

    return {
      series: percentList,
      options: {
        chart: {
          height: 350,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: '22px',
              },
              value: {
                fontSize: '16px',
              },
              total: {
                show: true,
                label: `Total: ${total}`,
                formatter: function () {
                  return `100%`
                },
              },
            },
          },
        },
        labels: [
          t(R.strings.label_common_for_here),
          t(R.strings.label_common_pick_up),
          t(R.strings.label_common_to_go),
        ],
      },
    }
  }, [reportTypeOrder, t])

  const onChangePage = (page: number, pageSize?: number) => {
    setParams({
      ...params,
      page,
      page_size: pageSize ?? 10,
    })
  }

  return (
    <S.RootWrapper justify={'space-between'} align={'top'}>
      <S.LeftSide direction="vertical" size={20}>
        <BaseCol>
          <S.HomeText>{t(R.strings.dashboard)}</S.HomeText>
          <S.SpaceVertical />
          <S.TextDate>{today.format('dddd, D MMM YYYY')}</S.TextDate>
        </BaseCol>

        <BaseRow gutter={[20, 20]} style={{ position: 'relative' }}>
          <BaseCol xl={8}>
            <ItemRevenue
              name={t(R.strings.dashboard_common_total_revenue)}
              value={formatMoneyDollar(
                reportTotal?.total_revenue?.total_amount ?? 0,
                reportTotal?.total_revenue?.currency ?? 'USD',
              )}
              icon={R.images.ic_total_revenue_restaurant_setting}
            />
          </BaseCol>
          <BaseCol xl={8}>
            <ItemRevenue
              name={t(R.strings.reports_common_total_dish_ordered)}
              value={formatMoneyDollar(
                reportTotal?.total_dish_ordered ?? 0,
                '',
              )}
              icon={R.images.ic_order_dashboard}
            />
          </BaseCol>
          <BaseCol xl={8}>
            <ItemRevenue
              name={t(R.strings.reports_common_total_customer)}
              value={formatMoneyDollar(reportTotal?.total_customer ?? 0, '')}
              icon={R.images.ic_customer_dashboard}
            />
          </BaseCol>
          {loadingReportTotal && <Loading />}
        </BaseRow>

        <S.TableWrapper direction="vertical" size={20}>
          <BaseRow align={'middle'} gutter={[15, 10]} justify={'space-between'}>
            <BaseCol>
              <S.HomeText>
                {t(R.strings.dashboard_common_order_report)}
              </S.HomeText>
            </BaseCol>
            <BaseCol>
              <BaseButtonsForm.Item name="select-multiple">
                <S.ChartHeaderSelectTime
                  defaultValue={FilterReportOrderEnum.Today}
                  onChange={value =>
                    onChangeTimeType(value as FilterReportOrderEnum)
                  }
                  value={time}
                >
                  <Option value={FilterReportOrderEnum.Today}>
                    {t(R.strings.time_today)}
                  </Option>
                  <Option value={FilterReportOrderEnum.ThisWeek}>
                    {t(R.strings.time_this_week)}
                  </Option>
                  <Option value={FilterReportOrderEnum.ThisMonth}>
                    {t(R.strings.time_this_month)}
                  </Option>
                </S.ChartHeaderSelectTime>
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>
          <TableOrderDashboard params={params} onChangePage={onChangePage} />
        </S.TableWrapper>
      </S.LeftSide>

      <S.RightSide direction="vertical" size={20}>
        <S.MostOrderWrapper>
          <S.ChartHeaderWrapper justify={'space-between'} align={'middle'}>
            <S.ChartHeaderTitle>
              {t(R.strings.dashboard_common_most_ordered)}
            </S.ChartHeaderTitle>
          </S.ChartHeaderWrapper>
          <S.SplitDivider />
          <BaseSpace direction="vertical" size={10} style={{ width: '100%' }}>
            {mostOrdered?.length ? (
              mostOrdered?.map((item, index) => {
                return <ItemMostOrder key={index} item={item} />
              })
            ) : (
              <BaseRow justify={'center'}>
                <Empty description={''} />
              </BaseRow>
            )}
          </BaseSpace>
          {loadingMostOrdered && <Loading />}
        </S.MostOrderWrapper>
        <S.ChartWrapper>
          <S.ChartHeaderWrapper justify={'space-between'} align={'middle'}>
            <S.ChartHeaderTitle>
              {t(R.strings.dashboard_common_most_type_order)}
            </S.ChartHeaderTitle>
          </S.ChartHeaderWrapper>
          <S.SplitDivider />
          <S.SpaceVertical />
          <S.SpaceVertical />
          <ApexCharts
            options={dataChart.options}
            series={dataChart.series}
            type="radialBar"
            height={350}
          />
          {loadingReportTypeOrder && <Loading />}
        </S.ChartWrapper>
      </S.RightSide>
    </S.RootWrapper>
  )
}

export default DashboardContainer
