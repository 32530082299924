import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseModal } from 'app/components/common/BaseModal'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const CreateModal = styled(BaseModal)`
  .ant-modal-body {
    padding: 0px;
  }
  .ant-modal-content {
    background: #252836;
  }

  .ant-modal-title {
    color: #fff;
    font-size: ${FONT_SIZE.xxl};
    font-weight: 700;
    background: #252836;
  }
  svg {
    fill: #fff !important;
  }
`
export const ContentWrapper = styled(BaseSpace)`
  width: 70%;
`

export const RightWrapper = styled(BaseCol)`
  width: 30%;
  padding-left: ${PADDING.xxs};
`

export const UpSellButtonAddMore = styled(BaseButton)`
  background-color: rgba(255, 255, 255, 0.1); // need change
  border-color: rgba(255, 255, 255, 0.1); // need change
  margin: 0px;
  height: 40px;
  width: 100%;
  .text {
    color: #29cc6a;
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
  }
  svg {
    fill: #29cc6a;
  }
`
