import { BaseButton } from 'app/components/common/BaseButton'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseDropdown } from 'app/components/common/BaseDropdown'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseTabs } from 'app/components/common/BaseTabs'
import {
  BORDER_RADIUS,
  FONT_WEIGHT,
  PADDING
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  width: 100%;
`

export const HeaderWrapper = styled(BaseRow)`
  padding: ${PADDING.xxs} 0px;
`

export const BackButton = styled(BaseButton)`
  background-color: rgba(255, 255, 255, 0.1); // need change
  border-radius: ${BORDER_RADIUS};
  border-color: transparent;
  padding: ${PADDING.md};
  transition: all 1s ease;

  & svg {
    fill: var(--background-color);
  }

  &:hover {
    border-color: var(--background-color) !important;
    transition: all 1s ease;
  }
`

export const HeaderDropDown = styled(BaseDropdown)`
  background-color: rgba(255, 255, 255, 0.1); // need change
  color: #FAFAFA;
  border-color: transparent;
  &:hover {
    color: #FAFAFA !important;
    border-color: var(--background-color) !important;
    transition: all 1s ease;
  }
  & ul.ant-dropdown-menu{
    background-color: #363441; // need change
    li {
      color: #FAFAFA !important;
      &:hover {
        background-color: #54585b80 !important;
      }
    }
  }
`

export const PrintersDescription = styled.div`
  color: #475569;
  font-weight: ${FONT_WEIGHT.regular};
  margin: 16px 0;
`

export const SplitDivider = styled(BaseDivider)`
  margin: 8px 0;
  background-color: #475569;
`

export const Tabs = styled(BaseTabs)`
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #FFFFFF;
  }
  .ant-tabs-tab .ant-tabs-tab-btn {
    color: #ABBBC2;
  }
  .ant-tabs-nav::before {
    border-bottom: 1px solid #475569;
  }
  .ant-tabs-ink-bar {
    border-bottom: 2px solid #FFFFFF;
  }
  .ant-tabs-content{
    color: #FFFFFF;
  }
`