import R from 'app/assets/R'
import { SpaceVertical } from 'app/components/styles'
import { useAppSelector } from 'app/redux/hooks'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import HeaderLayout from './components/Header'
import HomeLayout from './components/Home'
import TotalRevenueLayout from './components/TotalRevenue'
import TotalDishOrderedLayout from './components/TotalDishOrdered'
import TotalCustomerLayout from './components/TotalCustomer'
import OrderReportLayout from './components/OrderReport'
import { URL_REPORTS } from './constant'
import * as S from './styles'

const ReportsContainer = () => {
  const { t } = useTranslation()
  const currentPath = useAppSelector(state => state?.pathReport)?.path
  const [currentLayout, setCurrentLayout] = useState<JSX.Element | undefined>(
    undefined,
  )
  // eslint-disable-next-line
  const [headerTitle, setHeaderTitle] = useState('')

  useEffect(() => {
    switch (currentPath) {
      case URL_REPORTS.Home:
        setHeaderTitle(R.strings.reports_common_title)
        setCurrentLayout(<HomeLayout />)
        break
      case URL_REPORTS.TotalRevenue:
        setHeaderTitle(R.strings.reports_common_total_revenue)
        setCurrentLayout(<TotalRevenueLayout />)
        break
      case URL_REPORTS.TotalDishOrdered:
        setHeaderTitle(R.strings.reports_common_total_dish_ordered)
        setCurrentLayout(<TotalDishOrderedLayout />)
        break
      case URL_REPORTS.TotalCustomer:
        setHeaderTitle(R.strings.reports_common_total_customer)
        setCurrentLayout(<TotalCustomerLayout />)
        break
      case URL_REPORTS.OrderReport:
        setHeaderTitle(R.strings.reports_common_order_report)
        setCurrentLayout(<OrderReportLayout />)
        break
    }
  }, [currentPath])

  return (
    <S.RootWrapper>
      <HeaderLayout
        title={t(headerTitle)}
        hideBackButton={currentPath === URL_REPORTS.Home}
      />
      <SpaceVertical $gap={16} />
      <S.CurrentLayoutWrapper
        $bgColor={currentPath === URL_REPORTS.Home ? 'transparent' : '#1f1d2b'}
      >
        {currentLayout}
      </S.CurrentLayoutWrapper>
    </S.RootWrapper>
  )
}

export default ReportsContainer
