import { IResponseDefine } from 'app/common/model'

export interface Address {
  country?: string
  locality?: string
  postal_code?: string
  address_line_1?: string
  administrative_district_level_1?: string
}

export interface Coordinates {
  // Add appropriate properties for coordinates if needed
}

export interface BusinessHours {
  periods?: IOpenTime[]
}

export enum DayOfTheWeekEnum {
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN',
}
export interface IOpenTime {
  day_of_week: DayOfTheWeekEnum
  start_local_time: string
  end_local_time: string
  id: string
}

export interface IRestaurant {
  id?: string
  name?: string
  address?: Address
  timezone?: string
  capabilities?: string[] // Note?: Change the type of capabilities to an array of strings
  status?: string
  created_at?: string
  merchant_id?: string
  language_code?: string
  currency?: string
  phone_number?: string
  type?: string
  description?: string
  coordinates?: Coordinates
  business_name?: string
  business_hours?: IOpenTime[]
  business_email?: string
  mcc?: string
  image?: string
  user_fk?: number
}

export type ResponseRestaurantList = IResponseDefine<IRestaurant[]>

export interface IParamsRestaurantList {
  page_size?: number
  page?: number
}

export interface IPayloadUpdateLocationTime {
  day_of_week?: string
  start_local_time?: string
  end_local_time?: string
  location_fk?: string
  id?: string
}
