import { BaseCol } from "app/components/common/BaseCol";
import { BaseRow } from "app/components/common/BaseRow";
import styled from "styled-components";

export const RootWrapper = styled(BaseRow)``

export const CardItemWrapper = styled(BaseCol)``

export const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px 8px;
  width: 252px;
  height: 252px;
  background-color: #1F1D2B;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    border-color: var(--admin-background-focus-setting-menu-color);
    box-shadow: rgba(41, 204, 106, 0.3) 0px 0px 30px 10px, rgba(41, 204, 106, 0.2) 0px 5px 5px -2px;
    transition: all 1s ease;
  }
`