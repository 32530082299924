import React, { ComponentProps } from 'react'
import { Popover as AntPopover, Popover } from 'antd'
import { PopoverProps } from 'antd/lib/popover'

export interface BasePopoverProps extends ComponentProps<typeof AntPopover> {
    className?: string
}

export const BasePopover = React.forwardRef<PopoverProps, BasePopoverProps>(
    ({ className, children, ...props }, ref) => (
        <Popover
            ref={ref}
            getPopupContainer={triggerNode => triggerNode}
            overlayClassName={className}
            {...props}
        >
            {children}
        </Popover>
    ),
)
