import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseTimePicker } from 'app/components/common/pickers/BaseTimePicker'
import { Text } from 'app/components/styles'
import { notificationController } from 'app/controllers/notification-controller'
import dayjs from 'dayjs'
import { isEmpty, isEqual } from 'lodash'
import { FONT_WEIGHT, moment } from 'parkway-web-common'
import { useMemo } from 'react'
import styled from 'styled-components'
import { ITimeOpenRestaurant, SelectOpenTimeItemProps } from './type'

export const SelectOpenTimeItem = ({
  item,
  onAddTimeRangeOfDay,
  onDeleteRangeTime,
  onChangeRangeTime,
}: SelectOpenTimeItemProps) => {
  const handleChangeTimePicker = (
    selectedTime: dayjs.Dayjs | null,
    key: keyof ITimeOpenRestaurant,
    indexRange: number,
    range: ITimeOpenRestaurant,
  ) => {
    const newDate = moment(selectedTime?.toDate()).toDate()
    if (key === 'startTime') {
      const endDate = item?.times?.[indexRange]?.endTime

      if (endDate <= newDate) {
        notificationController.error({
          message:
            'You must choose a start time that is lower than the end time',
        })
        return
      }
    }
    const newItem = {
      ...range,
      [key]: newDate,
    }

    onChangeRangeTime?.({
      newItem,
      key: item?.key,
    })
  }

  const _onAddTimeRangeOfDay = () => {
    onAddTimeRangeOfDay?.(item?.key)
  }

  const _onDeleteRangeTime = (id?: string) => {
    if (!id) return
    onDeleteRangeTime?.(id)
  }

  const isDisableAddButton = useMemo(() => {
    return !item?.times?.find(itm => isEmpty(itm?.id) || isEqual(itm?.id, '-'))
  }, [item])

  return (
    <BaseRow>
      <BaseCol xl={3}>
        {/* <CustomCheckBox
          checked={item.checked}
          onChange={handleChangeCheckbox}
        /> */}
      </BaseCol>
      <BaseCol xl={4} style={{ alignItems: 'flex-start' }}>
        <Text $fontSize="12px" $fontWeight="700">
          {item?.title ?? '-'}
        </Text>
      </BaseCol>
      <BaseCol xl={6}>
        <BaseSpace direction="vertical" size={10} style={{ width: '100%' }}>
          {item?.times?.length ? (
            item?.times?.map((itm, idx) => {
              return (
                <CustomTimePicker
                  size="small"
                  onChange={selectedTime =>
                    handleChangeTimePicker(selectedTime, 'startTime', idx, itm)
                  }
                  defaultValue={dayjs(itm?.startTime)}
                  value={dayjs(itm?.startTime)}
                  key={idx}
                />
              )
            })
          ) : (
            <TextNotFound>{'-'}</TextNotFound>
          )}
        </BaseSpace>
      </BaseCol>

      <BaseCol xl={7}>
        <BaseSpace direction="vertical" size={10} style={{ width: '100%' }}>
          {item?.times?.length ? (
            item?.times?.map((itm, idx) => {
              return (
                <BaseRow key={idx} align={'middle'}>
                  <BaseCol>
                    <CustomTimePicker
                      size="small"
                      onChange={selectedTime =>
                        handleChangeTimePicker(
                          selectedTime,
                          'endTime',
                          idx,
                          itm,
                        )
                      }
                      defaultValue={dayjs(itm.endTime)}
                      value={dayjs(itm?.endTime)}
                    />
                  </BaseCol>
                  <BaseCol>
                    <DeleteOutlinedIcon
                      rev={undefined}
                      onClick={() => _onDeleteRangeTime(itm?.id)}
                    />
                  </BaseCol>
                </BaseRow>
              )
            })
          ) : (
            <TextNotFound>{'-'}</TextNotFound>
          )}
        </BaseSpace>
      </BaseCol>

      <BaseCol>
        <BaseCol>
          <PlusCircleOutlinedIcon
            rev={undefined}
            onClick={_onAddTimeRangeOfDay}
            disabled={isDisableAddButton}
          />
        </BaseCol>
      </BaseCol>
    </BaseRow>
  )
}

// const CustomCheckBox = styled(BaseCheckbox)`
//   align-items: center;
//   color: #ffffff;
//   &:hover {
//     .ant-checkbox-inner {
//       background-color: transparent !important;
//     }
//     .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
//       background-color: #29cc6a !important;
//     }
//   }
//   .ant-checkbox,
//   .ant-checkbox .ant-checkbox-input,
//   .ant-checkbox .ant-checkbox-inner {
//     width: 15px;
//     height: 15px;
//   }
//   span:nth-child(2) {
//     display: flex;
//     align-items: center;
//   }
//   .ant-checkbox .ant-checkbox-inner {
//     outline: 1px solid #ffffff;
//     background-color: transparent;
//     border: unset;
//   }
//   .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
//     background-color: #29cc6a;
//   }
// `

const CustomTimePicker = styled(BaseTimePicker)`
  border-radius: 12px;
  padding: 2px 5px !important;
  .ant-picker-input {
    padding: 0px 5px;
    color: white;
    input {
      color: white;
    }
  }
`

const PlusCircleOutlinedIcon = styled(PlusCircleOutlined)`
  cursor: pointer;
  svg {
    color: white;
    fill: white;
  }
`

const DeleteOutlinedIcon = styled(DeleteOutlined)`
  cursor: pointer;
  svg {
    color: red;
    fill: red;
  }
`

export const TextNotFound = styled.div`
  color: white;
  font-weight: ${FONT_WEIGHT.medium};
`
