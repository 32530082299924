/* eslint-disable @typescript-eslint/no-explicit-any */
import { BoxPlotFilled } from '@ant-design/icons'
import {
  IDiscountProductManagement,
  IResponseQueryList,
  ParamsListDefault,
} from 'app/api/product-management/model'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { usePagination } from 'app/hook'
import { useGetDiscountList } from 'app/react-query/hook/product-management'
import { useMemo, useState } from 'react'
import SearchComponent from '../../../../components/Search'
import SelectionRestaurant from '../../../../components/SelectionLocation'
import * as S from './styles'
import TableComp, { IDataTableDiscount } from './table'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

export const Discount = () => {
  const { t } = useTranslation()
  const [params, setParams] = useState(ParamsListDefault)

  const { data, isLoading: loading } = useGetDiscountList(params)
  const { flattenDataList } = usePagination()

  const discountData = useMemo(() => {
    const discount: IResponseQueryList<IDiscountProductManagement[]> =
      flattenDataList(data)
    return discount
  }, [data, params])

  const dataTable: IDataTableDiscount[] = useMemo(() => {
    return discountData?.data?.map(item => {
      return {
        id: item?.discount_id ?? '-',
        discountName: item?.name ?? '-',
        location: item?.present_at_location_names ?? [],
        status: item.status ? 1 : 0,
        discountType: item?.discount_type ?? '-',
        price: item?.money_amount ?? 0,
        discountApply: item?.apply_to ?? '-',
      } as IDataTableDiscount
    })
  }, [discountData])

  const totalItem = useMemo(() => {
    return discountData?.total ?? 0
  }, [discountData])

  const onChangePage = (page: number, pageSize?: number) => {
    setParams({
      ...params,
      page,
      page_size: pageSize ?? 10,
    })
  }

  const onChangeSearch = (value: string) => {
    setParams({
      ...params,
      search: value,
      name: value,
    })
  }

  const onChangeLocation = (value: string) => {
    setParams({
      ...params,
      location_id: value,
    })
  }

  return (
    <S.RootWrapper direction="vertical" size={5}>
      <S.FilterWrapper gutter={[20, 10]} align={'middle'}>
        <BaseCol>
          <SearchComponent onChange={onChangeSearch} initValue={params?.name} />
        </BaseCol>
        <BaseCol>
          <SelectionRestaurant
            onChange={onChangeLocation}
            value={params?.location_id}
          />
        </BaseCol>
      </S.FilterWrapper>
      <BaseRow justify={'end'}>
        <S.TotalItemLayout gutter={[10, 10]} align={'middle'}>
          <BaseCol>
            <BoxPlotFilled rev={undefined} />
          </BaseCol>
          <BaseCol>
            <BaseCol className="text">{`${totalItem} ${t(R.strings.product_management_items).toLowerCase()}`}</BaseCol>
          </BaseCol>
        </S.TotalItemLayout>
      </BaseRow>
      <BaseCol>
        <TableComp
          data={dataTable}
          onChangePage={onChangePage}
          total={totalItem}
          loadingList={loading}
        />
      </BaseCol>
    </S.RootWrapper>
  )
}
