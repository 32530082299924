import { ArrowLeftOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import UploadImage from 'app/components/common/BaseUpload/components/UploadImage'
import * as SR from 'app/containers/admin/Settings/components/RightSide/styles'
import { URL_SETTING } from 'app/containers/admin/Settings/constant'
import { useUpdateImageLocation } from 'app/react-query/hook/restaurant'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { setPathSetting } from 'app/redux/slices/pathSettingSlice'
import { memo, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { OpenTimeLayout } from './components'
import * as S from './styles'

const RestaurantDetailLayout = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const dataRestaurant = useAppSelector(
    state => state.pathSetting,
  )?.restaurantData

  const { mutate: mutateUpdateImage, isLoading: loadingUpdateImage } =
    useUpdateImageLocation()

  useEffect(() => {
    if (!dataRestaurant) {
      dispatch(setPathSetting(URL_SETTING.Restaurant))
    }
  }, [])

  const goBack = () => {
    return dispatch(setPathSetting(URL_SETTING.Restaurant))
  }

  const addressRestaurant = useMemo(() => {
    let address = ''
    if (dataRestaurant?.address?.address_line_1) {
      address = dataRestaurant?.address?.address_line_1
    }
    if (dataRestaurant?.address?.administrative_district_level_1) {
      address = `${address}, ${dataRestaurant?.address?.administrative_district_level_1}`
    }

    if (dataRestaurant?.address?.locality) {
      address = `${address}, ${dataRestaurant?.address?.locality}`
    }

    if (dataRestaurant?.address?.country) {
      address = `${address}, ${dataRestaurant?.address?.country}`
    }

    return address
  }, [dataRestaurant])

  const handleChangeImage = (imageFile: File) => {
    if (!imageFile) return

    const body = new FormData()
    body.append('id', dataRestaurant?.id ?? '')
    body.append('image', imageFile)

    mutateUpdateImage(body)
  }

  return (
    <S.RootWrapper>
      <S.HeaderWrapper justify={'space-between'} align={'middle'}>
        <S.BackButton onClick={goBack}>
          <ArrowLeftOutlined rev={undefined} />
        </S.BackButton>
        <SR.TitleSide>{dataRestaurant?.name ?? '-'}</SR.TitleSide>
        <BaseCol>
          {/* <S.ButtonSeeYourRestaurant>
            {t(R.strings.see_your_restaurant)}
          </S.ButtonSeeYourRestaurant> */}
        </BaseCol>
      </S.HeaderWrapper>

      <S.InfoWrapper
        gutter={[16, 16]}
        align={'middle'}
        justify={'space-between'}
      >
        <S.InfoImageWrapper>
          <UploadImage
            src={dataRestaurant?.image ?? ''}
            isLoading={loadingUpdateImage}
            onChange={handleChangeImage}
          />
        </S.InfoImageWrapper>
        <S.InfoContentWrapper
          direction="vertical"
          size={15}
          split={<S.SplitDivider />}
        >
          <S.InfoContentItemWrapper>
            <S.InfoContentItemTitle>
              {t(R.strings.information_branch)}
            </S.InfoContentItemTitle>
            <S.SpaceVertical />
            <S.InfoContentItemDescription>
              {dataRestaurant?.business_name}
            </S.InfoContentItemDescription>
          </S.InfoContentItemWrapper>
          <S.InfoContentItemWrapper>
            <S.InfoContentItemTitle>
              {t(R.strings.information_location)}
            </S.InfoContentItemTitle>
            <S.SpaceVertical />
            <S.InfoContentItemDescription>
              {addressRestaurant}
            </S.InfoContentItemDescription>
          </S.InfoContentItemWrapper>

          <S.InfoContentItemWrapper>
            <S.InfoContentItemTitle>
              {t(R.strings.information_phone_no)}
            </S.InfoContentItemTitle>
            <S.SpaceVertical />
            <S.InfoContentItemDescription>
              {dataRestaurant?.phone_number ?? '-'}
            </S.InfoContentItemDescription>
          </S.InfoContentItemWrapper>
          <S.InfoContentItemWrapper>
            <S.InfoContentItemTitle>
              {t(R.strings.information_email)}
            </S.InfoContentItemTitle>
            <S.SpaceVertical />
            <S.InfoContentItemDescription>
              {dataRestaurant?.business_email ?? '-'}
            </S.InfoContentItemDescription>
          </S.InfoContentItemWrapper>
        </S.InfoContentWrapper>
      </S.InfoWrapper>

      <S.OpenTimeWrapper>
        <OpenTimeLayout />
      </S.OpenTimeWrapper>

      {/* <S.RevenueWrapper>
        <S.RevenueDataWrapper direction="vertical" size={15}>
          <S.RevenueDataItemWrapper direction="vertical" size={3}>
            <BaseRow align={'middle'} gutter={[20, 20]}>
              <BaseCol>
                <S.RevenueDataItemIcon>
                  <BaseImage
                    src={R.images.ic_total_revenue_restaurant_setting}
                    width={18}
                    preview={false}
                  />
                </S.RevenueDataItemIcon>
              </BaseCol>
              <BaseRow align={'middle'}>
                <S.RevenueDataItemTextPercent
                  isUp
                >{`+ ${randomPercent()}%`}</S.RevenueDataItemTextPercent>
              </BaseRow>
            </BaseRow>
            <S.SpaceVertical />
            <S.RevenueDataItemTextValue>
              {`$ ${formatMoney(randomMoney(), '')}`}
            </S.RevenueDataItemTextValue>
            <S.SpaceVertical />
            <S.RevenueDataItemTextDescription>
              {t(R.strings.reports_common_total_revenue)}
            </S.RevenueDataItemTextDescription>
          </S.RevenueDataItemWrapper>

          <S.RevenueDataItemWrapper direction="vertical" size={3}>
            <BaseRow align={'middle'} gutter={[20, 20]}>
              <BaseCol>
                <S.RevenueDataItemIcon>
                  <BaseImage
                    src={R.images.ic_total_dish_order}
                    width={18}
                    preview={false}
                  />
                </S.RevenueDataItemIcon>
              </BaseCol>
              <BaseRow align={'middle'}>
                <S.RevenueDataItemTextPercent>{`- ${randomPercent()}%`}</S.RevenueDataItemTextPercent>
              </BaseRow>
            </BaseRow>
            <S.SpaceVertical />
            <S.RevenueDataItemTextValue>
              {`${formatMoney(randomMoney(), '')}`}
            </S.RevenueDataItemTextValue>
            <S.SpaceVertical />
            <S.RevenueDataItemTextDescription>
              {t(R.strings.reports_common_total_dish_ordered)}
            </S.RevenueDataItemTextDescription>
          </S.RevenueDataItemWrapper>

          <S.RevenueDataItemWrapper direction="vertical" size={3}>
            <BaseRow align={'middle'} gutter={[20, 20]}>
              <BaseCol>
                <S.RevenueDataItemIcon>
                  <BaseImage
                    src={R.images.ic_total_customer}
                    width={18}
                    preview={false}
                  />
                </S.RevenueDataItemIcon>
              </BaseCol>
              <BaseRow align={'middle'}>
                <S.RevenueDataItemTextPercent
                  isUp
                >{`+ ${randomPercent()}%`}</S.RevenueDataItemTextPercent>
              </BaseRow>
            </BaseRow>
            <S.SpaceVertical />
            <S.RevenueDataItemTextValue>
              {`${formatMoney(randomMoney(), '')}`}
            </S.RevenueDataItemTextValue>
            <S.SpaceVertical />
            <S.RevenueDataItemTextDescription>
              {t(R.strings.reports_common_total_customer)}
            </S.RevenueDataItemTextDescription>
          </S.RevenueDataItemWrapper>
        </S.RevenueDataWrapper>

        <S.ChartWrapper>
          <S.ChartHeaderWrapper justify={'space-between'} align={'middle'}>
            <S.ChartHeaderTitle>
              {t(R.strings.dashboard_common_most_type_order)}
            </S.ChartHeaderTitle>
            <BaseButtonsForm.Item name="select-multiple">
              <S.ChartHeaderSelectTime defaultValue={'Today'}>
                <Option value="Today">{t(R.strings.time_today)}</Option>
                <Option value="this_week">{t(R.strings.time_this_week)}</Option>
                <Option value="this_month">
                  {t(R.strings.time_this_month)}
                </Option>
              </S.ChartHeaderSelectTime>
            </BaseButtonsForm.Item>
          </S.ChartHeaderWrapper>
          <S.SplitDivider />
          <S.SpaceVertical />
          <S.SpaceVertical />
          <ApexCharts
            options={dataChart.options}
            series={dataChart.series}
            type="radialBar"
            height={350}
          />
        </S.ChartWrapper>
      </S.RevenueWrapper> */}
    </S.RootWrapper>
  )
}

export default memo(RestaurantDetailLayout)
