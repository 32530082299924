import { BaseUpload } from 'app/components/common/BaseUpload'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`

export const UploadWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const CustomUpload = styled(BaseUpload)<{
  $width?: string
  $height?: string
}>`
  width: ${props => props.$width ?? '100%'} !important;
  height: ${props => props.$height ?? '100%'} !important;

  .ant-upload-select {
    padding: 16px;
    border: 1px dashed #ffffff !important;
  }
  .ant-upload-list-item {
    border: 1px solid #ffffff !important;
  }

  .ant-upload-list,
  // default
  &.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
  &.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container,
  // circle
  &.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select,
  &.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-container {
    margin: 0;
    width: 100%;
    height: 100%;
  }

  &.ant-upload-picture-card-wrapper
    .ant-upload-list.ant-upload-list-picture-card
    .ant-upload-list-item-container.ant-upload-animate-inline-leave,
  &.ant-upload-picture-circle-wrapper
    .ant-upload-list.ant-upload-list-picture-circle
    .ant-upload-list-item-container.ant-upload-animate-inline-leave {
    display: none;
  }

  // default
  &.ant-upload-picture-card-wrapper
    .ant-upload-list.ant-upload-list-picture-card
    .ant-upload-list-item-container
    .ant-upload-list-item-thumbnail
    img {
    object-fit: contain;
  }
  // circle
  &.ant-upload-picture-circle-wrapper
    .ant-upload-list.ant-upload-list-picture-circle
    .ant-upload-list-item-container
    .ant-upload-list-item-thumbnail
    img {
    object-fit: cover;
  }

  // disable animation
  .ant-upload-select {
    pointer-events: auto !important;
  }
  & * {
    animation: unset !important;
    transition: unset !important;
  }
`

export const UploadButton = styled.button`
  border: 0;
  background: none;
  cursor: pointer;
`
