/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react'
import * as S from './styles'
import TableOrderHistory from './table-order'
import {
  FilterReportOrderEnum,
  getMonthRange,
  getTodayRange,
  getWeekRange,
} from 'app/containers/admin/Dashboard'
import { IParamsListGetReportOrder } from 'app/api/dashboard/type'

const OrderReportLayout = () => {
  const [time, setTime] = useState<FilterReportOrderEnum>(
    FilterReportOrderEnum.Today,
  )
  const [params, setParams] = useState<IParamsListGetReportOrder>({
    page: 1,
    page_size: 10,
    end_date: getMonthRange().endDate,
    start_date: getMonthRange().startDate,
  })

  const onChangePage = (page: number, pageSize?: number) => {
    setParams({
      ...params,
      page,
      page_size: pageSize ?? 10,
    })
  }

  const onChangeTimeType = (value: FilterReportOrderEnum) => {
    setTime(value)

    switch (value) {
      case FilterReportOrderEnum.Today:
        setParams(prev => ({
          ...prev,
          end_date: getTodayRange().endDate,
          start_date: getTodayRange().startDate,
        }))
        break
      case FilterReportOrderEnum.ThisWeek:
        setParams(prev => ({
          ...prev,
          end_date: getWeekRange().endDate,
          start_date: getWeekRange().startDate,
        }))
        break
      case FilterReportOrderEnum.ThisMonth:
        setParams(prev => ({
          ...prev,
          end_date: getMonthRange().endDate,
          start_date: getMonthRange().startDate,
        }))
        break
    }
  }
  return (
    <S.RootWrapper>
      <TableOrderHistory params={params} onChangePage={onChangePage} />
    </S.RootWrapper>
  )
}

export default OrderReportLayout
