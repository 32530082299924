import {
  IFilterReport,
  IReportCustomer,
  TypeDateEnum,
} from 'app/api/report/type'
import { BaseChart } from 'app/components/common/charts/BaseChart'
import { usePagination } from 'app/hook'
import { useGetReportCustomer } from 'app/react-query/hook/report'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BAR_CHART_SERIES_COLORS } from '../../constant'
import ChartHeaderLayout from '../ChartHeader'
import * as S from './styles'

const TotalCustomerLayout = () => {
  const { i18n } = useTranslation()

  const [params, setParams] = useState<IFilterReport>({
    type_date: TypeDateEnum.daily,
  })

  const { data: dataReport } = useGetReportCustomer(params)

  const { flattenDataList } = usePagination()

  const chartData = useMemo(() => {
    const data: IReportCustomer[] = flattenDataList(dataReport)

    const customers = data?.map(item => item?.total_customer ?? 0)
    const labels = data?.map(item => item?.date ?? '-')

    return {
      data: customers,
      labels,
    }
  }, [dataReport])

  const xAxisLabel = useMemo(() => {
    switch (params?.type_date) {
      case TypeDateEnum.daily:
        return chartData?.labels?.map(item => {
          const hour = +item
          return hour > 9
            ? `${hour}:00 - ${hour}:59`
            : `0${hour}:00 - 0${hour}:59`
        })

      case TypeDateEnum.weekly:
        return chartData?.labels?.map(item => `${item}`)
      case TypeDateEnum.monthly:
        return chartData?.labels?.map(item => `${item}`)
      case TypeDateEnum.yearly:
        return chartData?.labels?.map(item => `Month ${item}`)
    }
  }, [chartData, params])

  const dataZoom = useMemo(() => {
    switch (params?.type_date) {
      case TypeDateEnum.daily:
        return [
          {
            type: 'inside',
            start: 0,
            end: 35,
            show: false,
            zoomLock: true,
          },
        ]

      case TypeDateEnum.weekly:
        return [
          {
            type: 'inside',
            start: 0,
            end: 100,
            show: false,
            zoomLock: true,
          },
        ]
      case TypeDateEnum.monthly:
        return [
          {
            type: 'inside',
            start: 0,
            end: 35,
            show: false,
            zoomLock: true,
          },
        ]
      case TypeDateEnum.yearly:
        return [
          {
            type: 'inside',
            start: 0,
            end: 60,
            show: false,
            zoomLock: true,
          },
        ]
    }
  }, [params])

  const option = useMemo(() => {
    return {
      backgroundColor: 'transparent',
      tooltip: {},
      grid: {
        top: 20,
        left: 0,
        right: 0,
        bottom: 0,
        containLabel: true,
      },
      xAxis: {
        data: xAxisLabel,
        type: 'category',
        axisLine: {
          lineStyle: {
            color: '#FFFFFF',
            type: 'dashed',
          },
        },
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        axisLabel: {
          formatter: params => `${params / 1000}k`,
        },
        splitLine: {
          lineStyle: {
            color: '#FFFFFF',
            type: 'dashed',
          },
        },
      },
      series: [
        {
          data: chartData?.data ?? [],
          type: 'bar',
          label: {
            show: true,
            position: [0, -14],
            formatter: params => `${params.value.toLocaleString('en-US')}`,
          },
          barGap: '0',
          barWidth: '27px',
          itemStyle: {
            color: BAR_CHART_SERIES_COLORS[1],
            borderRadius: [5, 5, 0, 0],
          },
        },
      ],
      dataZoom,
    }
  }, [chartData, i18n.language, dataZoom, xAxisLabel])

  const onChange = (type_date: TypeDateEnum) => {
    setParams({
      type_date,
    })
  }

  return (
    <S.RootWrapper>
      <ChartHeaderLayout
        hideFilter
        onChange={onChange}
        params={params.type_date}
      />
      <BaseChart
        option={{ ...option }}
        theme={'dark'}
        width={'100%'}
        height={500}
      />
    </S.RootWrapper>
  )
}

export default TotalCustomerLayout
