import { BaseRow } from "app/components/common/BaseRow";
import { RestaurantLocationSelect } from "app/containers/admin/Settings/components/RightSide/layout/Appearance/styles";
import { FONT_WEIGHT } from "parkway-web-common";
import styled from "styled-components";

export const RootWrapper = styled(BaseRow)`
  width: 100%;
  margin-bottom: 16px;
`

export const LeftLayout = styled.div``

export const ActionItem = styled.span`
  color: #A5A5AA;
  font-size: 10px;
  font-weight: ${FONT_WEIGHT.regular};
  border-radius: 7px;
  margin: 4px;
  padding: 4px 8px;
  transition: all 0.5s ease;
  cursor: pointer;
  user-select: none;
  &:first-child {
    margin-left: unset;
  }
  &:last-child {
    margin-right: unset;
  }
  &.active {
    color: #FFFFFF;
    background-color: #29CC6A;
  }
`

export const RightLayout = styled.div`
  width: 140px;
`

export const Select = styled(RestaurantLocationSelect)`
  margin-bottom: 8px;
  min-width: 100px!important;
  & .ant-select-selection-item{
    color: #48BB78 !important;
  }
`