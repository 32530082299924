import {
  IItemProductManagement,
  IUpSellProductManagement,
  IVariationProductManagement,
} from 'app/api/product-management/model'
import { ButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { useMemo, useState } from 'react'
import InputPriceComponent from '../InputPrice'
import InputQuantityComponent from '../InputQuantity'
import SelectionItem from '../SelectionItem'
import SelectionLocation from '../SelectionLocation'
import SelectionPrice, { PriceTypeUpSellEnum } from '../SelectionPrice'
import SelectionLevel, { UpSellAtTypeUpSellEnum } from '../SelectionUpSellAt'
import SelectionVariation from '../SelectionVariation'
import * as S from './styles'
import { BORDER_RADIUS } from 'parkway-web-common'
import { useCreateUpSell } from 'app/react-query/hook/product-management'
import { notificationController } from 'app/controllers/notification-controller'
import { BaseCol } from 'app/components/common/BaseCol'
import { PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import SelectionItemApply from '../SelectionItemApply'
import { ENV_CONFIG } from 'app/common/config'

export interface IFormCreateUpSell {
  item: string
  upsell_at: string
  item_id: string[]
  selection_type: string
  varation: string
  price: number
  max_qty: number
  status: boolean
  present_at_all_locations: boolean
  present_at_location_ids: string[]
  present_at_location_names: string[]
  absent_at_location_ids: string[]
  item_by_upsell?: string[]
  image?: string
  varation_id?: string
}

export enum FormLocationTypeEnum {
  category = 'category',
  upSell = 'upSell',
}

const AddYourUpSellsModal = ({
  type = FormLocationTypeEnum.upSell,
  itemInit,
  listUpSellExist,
}: {
  type?: FormLocationTypeEnum
  itemInit?: IItemProductManagement
  listUpSellExist?: IUpSellProductManagement[]
}) => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [upSellAt, setUpSellAt] = useState<UpSellAtTypeUpSellEnum>()

  const [priceType, setPriceType] = useState<PriceTypeUpSellEnum>()
  const [price, setPrice] = useState<string>('0')

  const [quantity, setQuantity] = useState<string>('0')

  const [item, setItem] = useState<IItemProductManagement>()
  const [itemApply, setItemApply] = useState<IItemProductManagement[]>([])
  const [variation, setVariation] = useState<IVariationProductManagement>()

  const { mutateAsync, isLoading } = useCreateUpSell()

  const onCreate = async () => {
    if (!item || !variation || !priceType) return

    if (!itemInit && !itemApply) {
      notificationController.error({
        message: 'Item apply must be different from item',
      })
      return
    }

    if (!itemInit && !upSellAt) {
      notificationController.error({
        message: 'UpSell at must be different from item',
      })
      return
    }

    // let item_by_upsell: string[] = [];

    const payload: IFormCreateUpSell = {
      item: item?.name ?? '',
      upsell_at: itemInit ? UpSellAtTypeUpSellEnum.ITEM : upSellAt ?? '',
      item_id: [item?.item_id ?? ''],
      selection_type: '',
      varation: variation.name ?? '',
      varation_id: variation.id,
      price: Number(price),
      max_qty: Number(quantity),
      status: true,
      present_at_all_locations: upSellAt === UpSellAtTypeUpSellEnum.ORDER,
      present_at_location_ids: item?.present_at_location_ids ?? [],
      present_at_location_names: item?.present_at_location_names ?? [],
      absent_at_location_ids: [],
      item_by_upsell: itemInit
        ? [itemInit?.item_id ?? '']
        : itemApply?.map(item => item?.item_id ?? '') ?? [],
      image: item?.image?.replace(`${ENV_CONFIG.BASE_URL_IMAGE}`, ''),
    }
    mutateAsync(payload)

    notificationController.success({
      message: 'Create UpSell success',
    })

    setVariation(undefined)
    setItem(undefined)
    setItemApply([])
    setPriceType(undefined)
    setQuantity('0')
    setUpSellAt(undefined)
  }

  const handleModalOk = () => {
    onCreate()
    setIsModalOpen(false)
  }

  const handleModalCancel = () => {
    setIsModalOpen(false)
  }

  const openModal = () => {
    setIsModalOpen(true)
  }
  // handle action select

  // item
  const handleSelectItem = (item: IItemProductManagement) => {
    setItem(item)
    setVariation(undefined)
    setPrice('0')
  }

  const handleSelectItemApply = (items: IItemProductManagement[]) => {
    setItemApply(items)
  }

  const handleSelectVariation = (value: IVariationProductManagement) => {
    setVariation(value)
    setPrice(value?.price_money ?? '0')
  }

  const handleSelectUpSellAt = (value: UpSellAtTypeUpSellEnum) => {
    setUpSellAt(value)
    setQuantity('0')
  }

  const handleSelectPriceType = (value: PriceTypeUpSellEnum) => {
    setPriceType(value)
    if (value === PriceTypeUpSellEnum.ITEM) {
      setPrice(variation?.price_money ?? '0')
    }
  }

  const isCanSubmit = useMemo(() => {
    if (!itemInit && !itemApply) {
      return false
    }

    if (!itemInit && !upSellAt) {
      return false
    }

    return !!item && !!variation && !!priceType
  }, [item, variation, priceType, upSellAt, itemApply, itemInit])

  const renderButtonAdd = () => {
    switch (type) {
      case FormLocationTypeEnum.upSell:
        return (
          <ButtonVariant $borderRadius={BORDER_RADIUS} onClick={openModal}>
            {t(R.strings.add_new)}
          </ButtonVariant>
        )
      case FormLocationTypeEnum.category:
        return (
          <S.UpSellButtonAddMore onClick={openModal}>
            <BaseRow gutter={[10, 10]}>
              <BaseCol>
                <PlusOutlined rev={undefined} />
              </BaseCol>
              <BaseCol className="text">{t(R.strings.add_more_food)}</BaseCol>
            </BaseRow>
          </S.UpSellButtonAddMore>
        )
      default:
        return (
          <ButtonVariant onClick={openModal}>
            {t(R.strings.add_new)}
          </ButtonVariant>
        )
    }
  }

  return (
    <BaseSpace direction="vertical" size={10} style={{ width: '100%' }}>
      {renderButtonAdd()}
      <S.CreateModal
        title={t(R.strings.product_management_create_up_sell)}
        width={800}
        footer={
          <BaseRow justify={'end'} align={'middle'}>
            <ButtonVariant
              $borderRadius={BORDER_RADIUS}
              onClick={handleModalOk}
              disabled={!isCanSubmit}
              loading={isLoading}
            >
              {t(R.strings.save)}
            </ButtonVariant>
          </BaseRow>
        }
        open={isModalOpen}
        onCancel={handleModalCancel}
      >
        <BaseRow
          justify={'space-between'}
          align={'top'}
          gutter={20}
          style={{ marginLeft: 0 }}
        >
          <S.ContentWrapper direction="vertical" size={15}>
            {!itemInit && (
              <SelectionLevel
                onChange={handleSelectUpSellAt}
                value={upSellAt}
              />
            )}

            <SelectionItem
              onChange={handleSelectItem}
              value={item}
              itemParent={itemInit}
              listUpSellExist={listUpSellExist}
            />
            {item && (
              <SelectionVariation
                id={item?.item_id}
                onChange={handleSelectVariation}
                value={variation}
              />
            )}
            {upSellAt && upSellAt === UpSellAtTypeUpSellEnum.ORDER && (
              <InputQuantityComponent value={quantity} onChange={setQuantity} />
            )}
            <SelectionPrice
              onChange={handleSelectPriceType}
              value={priceType}
            />
            <InputPriceComponent
              isDisabled={!variation || priceType === PriceTypeUpSellEnum.ITEM}
              value={price}
              onChange={setPrice}
              currency={variation?.currency}
            />
            {!itemInit && (
              <SelectionItemApply
                onChange={handleSelectItemApply}
                value={itemApply}
                title={t(R.strings.form_item_select_item_apply_t)}
                placeholder={t(R.strings.form_item_select_item_apply_p)}
              />
            )}
          </S.ContentWrapper>
          <S.RightWrapper>
            {upSellAt && item && upSellAt === UpSellAtTypeUpSellEnum.ITEM && (
              <SelectionLocation
                locationsName={item?.present_at_location_names ?? []}
              />
            )}
          </S.RightWrapper>
        </BaseRow>
      </S.CreateModal>
    </BaseSpace>
  )
}

export default AddYourUpSellsModal
