import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ItemMenuCpn } from './components/ItemMenu'
import ModalSyncSquare from './components/ModalSyncSquare'
import { RightSide } from './components/RightSide'
import SearchComponent from './components/RightSide/components/Search'
import { dataMenu } from './constant'
import * as S from './styles'

export const SettingContainer = () => {
  const [search, setSearch] = useState('')
  const { t } = useTranslation()

  return (
    <div>
      <S.TitleWrapper justify={'space-between'}>
        <S.Title>{t(R.strings.settings)}</S.Title>
        <BaseCol>
          <SearchComponent
            initValue={search}
            onChange={setSearch}
            placeholder={t(R.strings.input_placeholder_search_tab)}
          />
        </BaseCol>
      </S.TitleWrapper>
      <S.RootWrapper gutter={[15, 15]}>
        <S.LeftSide xl={6}>
          {dataMenu?.map((item, index) => {
            return <ItemMenuCpn item={item} key={index} />
          })}
          <ModalSyncSquare />
        </S.LeftSide>
        <BaseCol xl={18}>
          <RightSide />
        </BaseCol>
      </S.RootWrapper>
    </div>
  )
}
