import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { Text } from 'app/components/styles'
import { useTranslation } from 'react-i18next'
import { SelectOpenTimeItem } from './SelectOpenTimeItem'
import { useHook } from './hook'
import * as S from './styles'
import { Loading } from 'app/components/common/Loading'

export const OpenTimeLayout = () => {
  const { t } = useTranslation()
  const {
    days,
    onAddTimeRange,
    onDeleteRangeTime,
    onChangeRangeTime,
    isLoading,
  } = useHook()

  return (
    <S.RootWrapper>
      <Text $fontSize="14px" $fontWeight="700">
        {t(R.strings.kiosk_open_time)}
      </Text>
      <S.OpenTimeSelectWrapper direction="vertical" size={25}>
        <BaseRow>
          <BaseCol xl={3} />
          <BaseCol xl={4}>
            <Text $fontSize="12px" $fontWeight="700">
              {t(R.strings.time_days)}
            </Text>
          </BaseCol>
          <BaseCol xl={6}>
            <Text $fontSize="12px" $fontWeight="700">
              {t(R.strings.time_start_time_kiosk)}
            </Text>
          </BaseCol>
          <BaseCol xl={6}>
            <Text $fontSize="12px" $fontWeight="700">
              {t(R.strings.time_end_time_kiosk)}
            </Text>
          </BaseCol>
          <BaseCol />
        </BaseRow>
        {days.map((item, index) => {
          return (
            <SelectOpenTimeItem
              key={index}
              item={item}
              index={index}
              onAddTimeRangeOfDay={onAddTimeRange}
              onDeleteRangeTime={onDeleteRangeTime}
              onChangeRangeTime={onChangeRangeTime}
            />
          )
        })}
      </S.OpenTimeSelectWrapper>
      {isLoading && <Loading />}
    </S.RootWrapper>
  )
}
