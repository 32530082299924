import R from 'app/assets/R'
import React from 'react'
import { BaseImage } from '../BaseImage'
import * as S from './styles'
import { useTranslation } from 'react-i18next'

export const NotFound: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.NotFoundWrapper>
      <S.ImgWrapper>
        <BaseImage
          src={'https://storage.googleapis.com/kiosify-01/images/ic_square.png'}
          alt={t(R.strings.not_found)}
          preview={false}
        />
      </S.ImgWrapper>
      <S.Text>{t(R.strings.not_found)}</S.Text>
    </S.NotFoundWrapper>
  )
}
