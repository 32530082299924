import { BaseSpace } from 'app/components/common/BaseSpace'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  width: 100%;
`

export const OpenTimeSelectWrapper = styled(BaseSpace)`
  width: 100%;
  border: 1px solid #ffffff;
  border-radius: 12px;
  margin-top: 8px;
  padding: 16px 8px 20px 8px;
  .ant-row {
    .ant-col {
      padding: 0px 8px;
    }
    .btn-add {
      cursor: pointer;
      float: right;
      &:hover {
        fill: #80808045;
      }
    }
  }
`
